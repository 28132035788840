import React from "react";

import { Button, styled } from "@mui/material";

const Root = styled("div")(({ theme }) => ({
  display: "flex",
  "& .MuiButton-root": {
    fontSize: "16px",
  },
  [theme.breakpoints.up("sm")]: {
    justifyContent: "flex-end",
  },
}));

const RemoveButton = styled(Button)(({ theme }) => ({
  marginLeft: "24px",

  [theme.breakpoints.down("sm")]: {
    marginLeft: "8px",
  },
}));

export const Buttons = props => {
  const {
    debtEdit,
    debtSaved,
    onEditButton,
    onRemoveButton,
    onSaveButton,
    disabled,
  } = props;
  return (
    <Root>
      {debtSaved && !debtEdit && (
        <Button variant='text' type='button' onClick={onEditButton}>
          Redigera
        </Button>
      )}

      {debtEdit && (
        <Button
          disabled={disabled}
          variant='text'
          type='button'
          onClick={onSaveButton}
        >
          Spara
        </Button>
      )}

      <RemoveButton variant='text' onClick={onRemoveButton} disabled={disabled}>
        Ta bort
      </RemoveButton>
    </Root>
  );
};
