import { gql } from "@apollo/client";

export const GET_SETTINGS = gql`
  query getSettings {
    settings {
      data {
        inactiveTimeUntilLogout
      }
      errors
    }
  }
`;
