import parse from "html-react-parser";
import DOMPurify from "dompurify";
import React from "react";

export const SanitizedHtml = ({ html, tags }) => {
  const cleanedText = parse(
    DOMPurify.sanitize(html, { ALLOWED_TAGS: tags ? tags : ["span"] }),
    {
      replace: true,
    }
  );
  return <>{cleanedText}</>;
};
