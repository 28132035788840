import { useQuery } from "@apollo/client";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  styled,
} from "@mui/material";

import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import React, { useState, useEffect } from "react";

import { Alert } from "../misc/Alert/Alert";

import { GET_FAQ_COMPLETION } from "../../graphql/GetFaqCompletion.query";
import { SanitizedHtml } from "../helpers/SanitizedHtml";

const FaqAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  padding: 0,
  flexDirection: "row-reverse",
  alignItems: "baseline",
  "& svg": {
    top: "3px",
    position: "relative",
    color: theme.palette.red,
    fontSize: "22px",
  },
  "& svg[data-icon~=minus]": {
    top: "-5px",
  },
}));

const FaqAccordion = styled(Accordion)(({ theme }) => ({
  border: 0,
  boxShadow: "none",
  color: theme.palette.neutral.main,
  "&:before": {
    height: 0,
  },
}));

const FaqQuestion = styled("div")(({ theme, expanded }) => ({
  fontWeight: 700,
  fontSize: theme.spacing(2.25),
  lineHeight: "1.75rem",
  margin: 0,
  marginLeft: theme.spacing(1.5),
  padding: "16px 0",
  decoration: expanded && "underline",
}));

const FaqAnswer = styled(AccordionDetails)(({ theme }) => ({
  paddingLeft: theme.spacing(5),
  border: 0,
  [theme.breakpoints.up("xl")]: {
    paddingLeft: theme.spacing(8),
  },
}));

const FaqAnswerText = styled("div")(({ theme }) => ({
  "& p": {
    fontSize: theme.spacing(2.25),
    lineHeight: "1.75rem",
    margin: 0,
  },
}));

export const QuickHelp = props => {
  const [expanded, setExpanded] = useState(false);
  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const {
    data: faqData,
    loading,
    error,
  } = useQuery(GET_FAQ_COMPLETION, {
    variables: { input: "COMPLETION" },
  });

  const faqItems = faqData?.faq?.data ?? [];

  useEffect(() => {
    const collapseMe = collapse => {
      if (collapse) {
        setExpanded(false);
      }
    };

    collapseMe(props.closeMe);
  }, [props.closeMe]);

  if (loading) {
    return null;
  }

  if (error || faqItems?.faq?.errors.length) {
    return <Alert message='Det verkar så att vi inte kunde ladda ner faq.' />;
  }

  return (
    <React.Fragment>
      {faqItems.map((item, index) => {
        return (
          <FaqAccordion
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
            key={index}
          >
            <FaqAccordionSummary
              aria-controls={`panel${index}bh-content`}
              id={`panel${index}bh-content`}
              expandIcon={
                expanded === `panel${index}` ? (
                  <FontAwesomeIcon icon={faMinus} />
                ) : (
                  <FontAwesomeIcon icon={faPlus} />
                )
              }
            >
              <FaqQuestion
                expanded={expanded === `panel${index}` && "expanded"}
              >
                <SanitizedHtml html={item.question} tags='p' />
              </FaqQuestion>
            </FaqAccordionSummary>
            <FaqAnswer>
              <FaqAnswerText>
                <SanitizedHtml html={item.answer} tags='p' />
              </FaqAnswerText>
            </FaqAnswer>
          </FaqAccordion>
        );
      })}
    </React.Fragment>
  );
};
