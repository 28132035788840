import InlineSVG from "svg-inline-react";
import React from "react";
import { SvgWrapper } from "../SvgWrapper";

const source = `<svg width="150px" height="150px" viewBox="0 0 80 80" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<title>New-icon/Exclamation@1x</title>
<g id="Bullet-icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
    <g id="Delprojekt-8---kompletterings-ikoner" transform="translate(-247.000000, -302.000000)">
        <g id="Icons8/Confimation-bullets/Exclamation-mark" transform="translate(247.000000, 302.000000)">
            <rect id="Rectangle-Copy" fill="#FEEBB9" x="0" y="0" width="80" height="80" rx="40"></rect>
            <g id="Icon/16px/search" transform="translate(10.000000, 10.000000)">
                <path d="M31.875,40.3125 L31.875,46.875 L29.0625,46.875 L29.0625,40.3125 L31.875,40.3125 Z M31.875,12.1875 L31.875,34.6875 L29.0625,34.6875 L29.0625,12.1875 L31.875,12.1875 Z" id="Combined-Shape" fill="#363A38"></path>
            </g>
        </g>
    </g>
</g>
</svg>`;

export function ExclamationIcon(props) {
  return (
    <SvgWrapper>
      <InlineSVG src={source} {...props} />
    </SvgWrapper>
  );
}
