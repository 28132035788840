import React from "react";
import { styled } from "@mui/material";

const AlertRoot = styled("div")(({ theme }) => ({
  width: "100%",
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
}));

const AlertContent = styled("div")(({ theme, type }) => ({
  borderRadius: "6px",
  padding: theme.spacing(3),
  color: theme.palette.neutral.main,
  backgroundColor: type === "warning" && theme.palette.backgrounds.warning,
}));

const AlertTitle = styled("span")(({ theme }) => ({
  display: "block",
  fontSize: "1.125rem",
  fontWeight: 700,
}));

const AlertMessage = styled("p")(({ theme }) => ({
  margin: 0,
  fontSize: "1rem",
  lineHeight: "29px",
}));

export const Alert = ({ title, message = "", type = "warning" }) => {
  return (
    <AlertRoot>
      <AlertContent type={type}>
        {title && <AlertTitle>{title}</AlertTitle>}

        <AlertMessage>{message}</AlertMessage>
      </AlertContent>
    </AlertRoot>
  );
};
