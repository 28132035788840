import { Tooltip, styled, Button } from "@mui/material";
import React from "react";
import glassIcon from "../../assets/images/icon-lookingglass.png";
import { SanitizedHtml } from "../helpers/SanitizedHtml";

const Root = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "25px 0",

  [theme.breakpoints.down("sm")]: {
    padding: "25px 20px",
    alignItems: "flex-start",
  },
}));
const Link = styled(Button)(({ theme }) => ({
  cursor: "pointer",
  display: "inline-block",
  fontWeight: 700,
  padding: [0, theme.spacing(1)].join(" "),
  fontSize: theme.spacing(2),

  "&.MuiButton-text": {
    color: theme.palette.primaryColor[100],
  },

  "&:hover": {
    background: "none",
  },

  "&:focus-visible": {
    outline: `2px solid ${theme.palette.focus}`,
  },

  [theme.breakpoints.down("sm")]: {
    lineHeight: "29px",
  },
}));

const Title = styled("h3")(({ theme }) => ({
  display: "inline-block",
  fontSize: "24px",
  fontWeight: 900,
  lineHeight: "40px",
  background: `url(${glassIcon}) center left no-repeat`,
  backgroundSize: "22px",
  paddingLeft: "38px",
  margin: 0,

  [theme.breakpoints.down("sm")]: {
    fontSize: "18px",
    lineHeight: "28px",
  },
}));

export const ReviewBlockHeader = props => {
  const { title, step, goToStep } = props;

  return (
    <Root>
      <Title>
        <SanitizedHtml html={title} />
      </Title>

      <Tooltip title={`Tillbaks till steg ${step + 1}`} placement='top'>
        <Link onClick={goToStep(step)} disableFocusRipple>
          Redigera
        </Link>
      </Tooltip>
    </Root>
  );
};
