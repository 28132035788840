import { styled } from "@mui/material";
import React from "react";
import QRCode from "react-qr-code";

const Root = styled("div")(({ theme }) => ({
  height: 180,
  width: 180,
  margin: "0 auto",
  maxWidth: 180,
}));

const Wrap = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  border: "2px solid #0D5D4B",
  padding: "0.5rem",
  borderRadius: "0.5rem",
}));

const Inner = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  padding: "0.5rem",

  backgroundColor: "#ffffff",
}));

export const QRCodeRender = ({ value }) => {
  return (
    <Root>
      <Wrap>
        <Inner>
          <QRCode
            size={180}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={value}
            viewBox={`0 0 180 180`}
          />
        </Inner>
      </Wrap>
    </Root>
  );
};
