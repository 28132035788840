import { styled } from "@mui/material";
import React from "react";
import { NumericFormat } from "react-number-format";

const Root = styled("div")(({ theme }) => ({
  backgroundColor: "#ECF6EA",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: `${theme.spacing(4)} 0`,
  boxSizing: "border-box",
  marginBottom: theme.spacing(4),
  textAlign: "center",

  [theme.breakpoints.down("sm")]: {
    padding: `${theme.spacing(2)} 0 ${theme.spacing(1.5)} 0`,
    marginBottom: theme.spacing(2.5),
  },
}));

const Title = styled("h3")(({ theme }) => ({
  fontSize: "0.875rem",
  fontWeight: 700,
  letterSpacing: "1.27px",
  textTransform: "uppercase",
  color: theme.palette.primary.main,
  margin: "0 0 0.5rem 0",

  [theme.breakpoints.down("sm")]: {
    fontSize: "0.75rem",
    lineHeight: "1.375rem",
    marginBottom: "6px",
    letterSpacing: "1.09px",
  },
}));

const TotalCost = styled(NumericFormat)(({ theme }) => ({
  fontSize: "3rem",
  lineHeight: "3.5rem",
  fontWeight: 900,

  [theme.breakpoints.down("sm")]: {
    fontSize: "2.5rem",
    letterSpacing: "0.67px",
  },
}));

export const TotalAmount = ({ totalAmount, title, monthly }) => {
  return (
    <Root>
      <Title>{title}</Title>

      <TotalCost
        value={totalAmount}
        displayType='text'
        suffix={monthly ? " kr/mån" : " kr"}
        thousandSeparator=' '
      />
    </Root>
  );
};
