import { MenuItem } from "@mui/material";
import { styled } from "@mui/system";

const SelectMenuItem = styled(MenuItem)(({ theme }) => ({
  paddingTop: 0,
  paddingBottom: 0,
  "& img": {
    width: "52px",
    height: "auto",
  },
}));

export const createCountriesSelectOptions = array => {
  if (!array || !array.length) {
    return (
      <SelectMenuItem value='' disabled key='n00'>
        Välj
      </SelectMenuItem>
    );
  }

  let options = array.map(item => {
    return (
      <SelectMenuItem value={item.countrycode} key={item.countrycode}>
        <img src={item.icon} alt='img' />
        {item.name}
      </SelectMenuItem>
    );
  });
  options.unshift(
    <SelectMenuItem value='' disabled key='n00'>
      Välj
    </SelectMenuItem>
  );
  return options;
};
