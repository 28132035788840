import { gql } from "@apollo/client";

export const GET_FAQ_COMPLETION = gql`
  query getFaq($input: FAQCategory) {
    faq(input: $input) {
      errors
      data {
        category
        question
        answer
      }
    }
  }
`;
