const BANKID_BASE_URL = process.env.REACT_APP_BANKID_API_BASE_URL;
const BASE_API_URL = process.env.REACT_APP_API_BASE_URL;
const BASE_SITE_URL = process.env.REACT_APP_BASE_URL;

const loginWithBankid = async data => {
  const response = await fetch(BANKID_BASE_URL + "/login", {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  return response;
};

const getQRCodeToken = async orderRef => {
  const response = await fetch(BANKID_BASE_URL + "/qr", {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ order_ref: orderRef }),
  });

  return response;
};

const collectBankidToken = async orderRef => {
  const response = await fetch(BANKID_BASE_URL + "/collect", {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ order_ref: orderRef }),
  });

  return response;
};

// handle scenario when user clicks on the button "Avbryt" while login with bankid is proccessing
const cancelBankidLogin = async orderRef => {
  const response = await fetch(BANKID_BASE_URL + "/cancel", {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ order_ref: orderRef }),
  });

  return response;
};

const checkToken = async token => {
  const response = await fetch(
    `${BASE_API_URL}/user/login_status?_format=json`,
    {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response;
};

const refreshAccessToken = async token => {
  const response = await fetch(`${BASE_API_URL}/refresh-token`, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },

    body: JSON.stringify({ refresh_token: token }),
  });

  return response.json();
};

const logout = () => {
  localStorage.removeItem("completion_token");
  localStorage.removeItem("refresh_token");

  window.location.href = BASE_SITE_URL;
};

export {
  loginWithBankid,
  collectBankidToken,
  checkToken,
  logout,
  cancelBankidLogin,
  refreshAccessToken,
  getQRCodeToken,
};
