import React, { useEffect } from "react";
import { useState } from "react";
import { loanStatus } from "../../config/loanStatus";
import { styled } from "@mui/material";

const ProgressListRoot = styled("dl")(({ theme }) => ({
  margin: 0,
  paddingLeft: 0,
}));

const ProgressListIndicator = styled("span")(
  ({ theme, disabled, current }) => ({
    display: "block",
    minWidth: "26px",
    height: "26px",
    borderRadius: "26px",
    backgroundColor: disabled
      ? theme.palette.primary.disabled
      : current
      ? theme.palette.primaryColor[100]
      : theme.palette.primaryColor[40],
    color: "#fff",
    textAlign: "center",
    fontSize: "14.4px",
    marginRight: "13px",
  })
);

const ProgressListItemLabel = styled("span")(({ theme, disabled }) => ({
  color: disabled ? theme.palette.text.secondary : "#002427",
}));

const ProgressListItem = styled("dd")(({ theme }) => ({
  fontSize: "1rem",
  fontWeight: 700,
  margin: "1.5rem 0",
  display: "flex",
  alignItems: "baseline",
  lineHeight: "26px",
}));

export const ProgressList = ({ status }) => {
  const items = [
    "Ansökan om lån skickas in",
    "Kreditprövning görs (en kopia på kreditupplysning skickas till dig)",
    "Du får information om din ansökan blivit beviljad eller ej",
    "Du lämnar kompletterande uppgifter",
    "Du, och eventuell medlåntagare, signerar avtal med BankID",
    "Lånet betalas ut till ditt/ert Servicekonto i Lån & Spar Bank (normalt inom några bankdagar)",
  ];
  const [completed, setCompleted] = useState({
    0: true,
    1: true,
    2: true,
    3: false,
    4: false,
    5: false,
  });

  const [current, setCurrent] = useState(3);

  useEffect(() => {
    if (status === loanStatus.complete) {
      setCompleted(prev => ({ ...prev, 3: true }));
      setCurrent(4);
    }

    if (status === loanStatus.documentsSigned) {
      setCompleted(prev => ({ ...prev, 3: true, 4: true }));
      setCurrent(5);
    }

    if (status === loanStatus.paidOut) {
      setCompleted(prev => ({ ...prev, 3: true, 4: true, 5: true }));
      setCurrent(undefined);
    }
  }, [status]);

  return (
    <React.Fragment>
      <ProgressListRoot>
        {items.map((item, index) => {
          return (
            <ProgressListItem key={index}>
              <ProgressListIndicator
                disabled={completed[index] && "disabled"}
                current={current === index && "current"}
              >
                {index + 1}
              </ProgressListIndicator>
              <ProgressListItemLabel disabled={completed[index] && "completed"}>
                {item}
              </ProgressListItemLabel>
            </ProgressListItem>
          );
        })}
      </ProgressListRoot>
    </React.Fragment>
  );
};
