import { MenuItem, Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { faSave } from "@fortawesome/free-regular-svg-icons";

import React, { useContext, useEffect, useState } from "react";

import { LsbSelect } from "../../form-components/LsbSelect";
import { LsbFormControl } from "../../form-components/LsbFormControl";
import { Buttons } from "./Buttons";

import { sanitizedString } from "../../helpers/SanitizedString";

import {
  WarningBlock,
  WarningBlockText,
  WarningBlockTitle,
  DebtForm,
  DebtFormHeading,
  DebtFormTitle,
  DebtFormBody,
  DebtFormFooter,
} from "../../../styles/LoanFormStyles";

import {
  CompletionContext,
  debtDataSchema,
} from "../../contexts/CompletionContext";

const bankFields = {
  amount: "AMOUNT",
  cardNumber: "CREDIT_NUMBER",
  bankPlusGiro: "BANK_PLUS_GIRO",
  ocrNumber: "OCR_NUMBER",
  otherBankName: "OTHER_BANK_NAME",
};

const createBankOptions = array => {
  if (!array || !array.length) {
    return;
  }

  let options = array.map(item => {
    return (
      <MenuItem value={item.id} key={item.id}>
        <img src={item.icon} alt='img' />
        {item.name}
      </MenuItem>
    );
  });
  options.unshift(
    <MenuItem value='' disabled key='n00'>
      Välj
    </MenuItem>
  );
  return options;
};

export const LoanFormControls = props => {
  const {
    debtSaved,
    index,
    item,
    inReview,
    selectedBankId,
    debtEdit,
    buttonsProps,
    disabled,
    hasInternalLoan,
  } = props;

  const { interfaceText, banks } = useContext(CompletionContext);

  const [bankType, setBankType] = useState({ loanRepaymentFields: [] });

  const bankSelectOptions = banks?.data.length
    ? createBankOptions(banks?.data)
    : null;

  function handleBankSelect(bankId) {
    setBankType(
      banks?.data.find(bank => {
        return bank.id === bankId;
      })
    );
  }

  useEffect(() => {
    if (!inReview && selectedBankId !== "") {
      setBankType(
        banks?.data.find(bank => {
          return bank.id === selectedBankId;
        })
      );
    }
  }, [banks, inReview, selectedBankId]);

  useEffect(() => {
    if (inReview) {
      setBankType(
        banks?.data.find(bank => {
          return bank.id === item.bank;
        })
      );
    }
  }, [banks, inReview, item.bank]);

  if (!banks.data.length) {
    return null;
  }

  const loanNumber = hasInternalLoan ? index + 2 : index + 1;

  return (
    <DebtForm saved={debtSaved && "saved"} edit={debtEdit && "edit"}>
      <DebtFormHeading saved={debtSaved && "saved"}>
        <DebtFormTitle saved={debtSaved && "saved"}>
          Lån {loanNumber}
        </DebtFormTitle>

        {((!inReview && debtSaved) || debtEdit) && (
          <Buttons {...buttonsProps} />
        )}
      </DebtFormHeading>
      <DebtFormBody>
        <LsbSelect
          disabled={debtSaved}
          name={
            inReview
              ? `loanRepayments[${[index]}].${debtDataSchema.bank}`
              : debtDataSchema.bank
          }
          label={sanitizedString(interfaceText?.loanRepaymentBankNameLabel)}
          options={bankSelectOptions}
          defaultValue={item.bank}
          index={index}
          handleChange={handleBankSelect}
        />

        {bankType?.loanRepaymentFields.includes(bankFields.otherBankName) && (
          <LsbFormControl
            label={sanitizedString(
              interfaceText?.loanRepaymentOtherBankNameLabel
            )}
            name={
              inReview
                ? `loanRepayments[${[index]}].${
                    debtDataSchema?.data?.otherBankName
                  }`
                : debtDataSchema.data.otherBankName
            }
            placeholder={sanitizedString(
              interfaceText?.loanRepaymentOtherBankNamePlaceholder
            )}
            helpText={
              !inReview &&
              sanitizedString(interfaceText?.loanRepaymentOtherBankNameHelp)
            }
            defaultValue={item?.data?.otherBankName}
            disabled={debtSaved}
            index={index}
          />
        )}

        {bankType.loanRepaymentDisplayExtraInfo && (
          <WarningBlock>
            <WarningBlockTitle>
              <FontAwesomeIcon icon={faExclamationTriangle} />
              Viktig information angående &nbsp;
              {bankType.name}
            </WarningBlockTitle>
            <WarningBlockText>
              När lån i {bankType.name} ska lösas behöver vi kontakta banken åt
              dig.
            </WarningBlockText>
          </WarningBlock>
        )}

        {bankType.loanRepaymentFields.includes(bankFields.amount) && (
          <LsbFormControl
            formatType='money'
            label={sanitizedString(
              interfaceText?.loanRepaymentAmountToRepayLabel
            )}
            placeholder={sanitizedString(
              interfaceText?.loanRepaymentAmountToRepayPlaceholder
            )}
            name={
              inReview
                ? `loanRepayments[${[index]}].${
                    debtDataSchema?.data?.amountToRepay
                  }`
                : debtDataSchema?.data?.amountToRepay
            }
            helpText={
              !inReview &&
              sanitizedString(interfaceText?.loanRepaymentAmountToRepayHelp)
            }
            defaultValue={
              item?.data?.amountToRepay
                ? parseFloat(item?.data?.amountToRepay)
                : null
            }
            disabled={debtSaved}
            index={index}
          />
        )}

        {bankType.loanRepaymentFields.includes(bankFields.cardNumber) && (
          <LsbFormControl
            label={sanitizedString(interfaceText?.loanRepaymentCardNumberLabel)}
            name={
              inReview
                ? `loanRepayments[${[index]}].${debtDataSchema.data.cardNumber}`
                : debtDataSchema.data.cardNumber
            }
            placeholder={sanitizedString(
              interfaceText?.loanRepaymentCardNumberPlaceholder
            )}
            helpText={
              !inReview &&
              sanitizedString(interfaceText?.loanRepaymentCardNumberHelp)
            }
            defaultValue={item?.data?.cardNumber}
            disabled={debtSaved}
            index={index}
            formatType={inReview ? "common" : "textNumbers"}
          />
        )}

        {bankType.loanRepaymentFields.includes(bankFields.bankPlusGiro) && (
          <LsbFormControl
            formatType={inReview ? "common" : "textNumbers"}
            label={sanitizedString(
              interfaceText?.loanRepaymentBankPlusGiroLabel
            )}
            placeholder={sanitizedString(
              interfaceText?.loanRepaymentBankPlusGiroPlaceholder
            )}
            helpText={
              !inReview &&
              sanitizedString(interfaceText?.loanRepaymentBankPlusGiroHelp)
            }
            name={
              inReview
                ? `loanRepayments[${[index]}].${
                    debtDataSchema.data?.bankPlusGiro
                  }`
                : debtDataSchema.data?.bankPlusGiro
            }
            defaultValue={item?.data?.bankPlusGiro}
            disabled={debtSaved}
            index={index}
          />
        )}

        {bankType.loanRepaymentFields.includes(bankFields.ocrNumber) && (
          <LsbFormControl
            formatType={inReview ? "common" : "textNumbers"}
            label={sanitizedString(interfaceText?.loanRepaymentOCRNumberLabel)}
            placeholder={sanitizedString(
              interfaceText?.loanRepaymentOCRNumberPlaceholder
            )}
            helpText={
              !inReview &&
              sanitizedString(interfaceText?.loanRepaymentOCRNumberHelp)
            }
            name={
              inReview
                ? `loanRepayments[${[index]}].${
                    debtDataSchema?.data?.ocrNumber
                  }`
                : debtDataSchema?.data?.ocrNumber
            }
            defaultValue={item?.data?.ocrNumber}
            disabled={debtSaved}
            index={index}
          />
        )}
      </DebtFormBody>

      {!debtSaved && !debtEdit && (
        <DebtFormFooter>
          <Button
            variant='contained'
            type='button'
            onClick={() => {
              buttonsProps.onSaveButton();
            }}
            color='primary'
            endIcon={<FontAwesomeIcon icon={faSave} />}
            disabled={disabled}
          >
            Spara
          </Button>
        </DebtFormFooter>
      )}
    </DebtForm>
  );
};
