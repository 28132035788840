import { Box, Grid, styled } from "@mui/material";
import React from "react";

import systemErrorImage from "../../assets/images/system-error.png";
import { RedButton } from "../CTAButtons/CTAButtons";
import { PaperLayout } from "../Layout/PaperLayout";
import { OfferMissingScreen } from "./OfferMissingScreen";
import { Heading } from "../misc/Typography/Heading";
import { Paragraph } from "../misc/Typography/Paragraph";
import { ContextLink } from "../misc/ContextLink/ContextLink";
import { StatusContentLayout } from "../StatusPage/fragments/StatusContentLayout";

import { logout } from "../../auth-service/auth-service";
import { StatusFragmentIcon } from "../StatusPage/components/StatusFragmentIcon";
import { UnauthorizedErrorMessage } from "./UnauthorizedErrorMessage";
import { Header } from "../MainPage/Header";

const Root = styled("div")(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  zIndex: 5000,
  backgroundColor: theme.palette.basicBackgroundColor,
}));

const Content = styled("div")(({ theme }) => ({
  paddingTop: theme.spacing(10),
  minHeight: "100vh",
}));

export const ErrorText = styled(Paragraph)(({ theme }) => ({
  padding: "12px",
  margin: 0,
}));

export const ErrorScreen = ({ errorCode }) => {
  const defaultContent = (
    <React.Fragment>
      <StatusFragmentIcon icon={systemErrorImage} />
      <Box py={{ xs: 2, sm: 3 }}>
        <Heading>Oj, något blev fel!</Heading>
        <ErrorText>
          Ett okänt fel har inträffat. Vänligen försök igen senare.
        </ErrorText>

        <ErrorText>
          Om felet kvarstår är du välkommen att höra av dig till oss på
          telefon&nbsp;&nbsp;
          <ContextLink
            title='Ring oss'
            link='tel:+4640205600'
            text='040-205 600'
          />
          . Du når oss helgfria vardagar&nbsp;
          <span style={{ whiteSpace: "nowrap" }}>kl. 09.00-16.00</span>
        </ErrorText>
      </Box>
      <RedButton variant='contained' onClick={logout}>
        Logga ut
      </RedButton>
    </React.Fragment>
  );

  const renderContent = errorCode => {
    switch (errorCode) {
      case "offerMissing":
        return (
          <>
            <OfferMissingScreen />
            <RedButton variant='contained' onClick={logout}>
              Logga ut
            </RedButton>
          </>
        );

      case "Unauthorized":
        return <UnauthorizedErrorMessage />;

      default:
        return defaultContent;
    }
  };

  return (
    <Root>
      <Header userLoggedIn={false} />
      <Content>
        <PaperLayout>
          <Grid container justifyContent='center'>
            <StatusContentLayout fadeIn>
              {renderContent(errorCode)}
            </StatusContentLayout>
          </Grid>
        </PaperLayout>
      </Content>
    </Root>
  );
};
