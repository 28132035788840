import React from "react";
import { SvgIcon, styled } from "@mui/material";

const SvgRoot = styled(SvgIcon)(({ theme }) => ({
  width: theme.spacing(4.55),
  height: theme.spacing(4.55),
  marginBottom: theme.spacing(1.25),
}));

export function BankIDIcon(props) {
  return (
    <SvgRoot {...props} width='32' height='32' viewBox='0 0 32 32'>
      <path
        d='M10.0958 16.696L11.4324 7.78571C10.9059 7.78571 10.0046 7.78571 10.0046 7.78571C9.3363 7.78571 8.47556 7.38946 8.22241 6.66122C8.1414 6.4149 7.949 5.56885 9.05276 4.74421C9.44769 4.45506 9.70085 4.13377 9.75148 3.88745C9.80211 3.63043 9.74135 3.40553 9.56921 3.23417C9.32617 2.98786 8.85024 2.84863 8.24266 2.84863C7.2199 2.84863 6.50094 3.46978 6.43005 3.91958C6.37942 4.25158 6.62245 4.51932 6.8351 4.69067C7.47306 5.19401 7.62496 5.92226 7.23003 6.60767C6.82498 7.3145 5.94399 7.775 5.00224 7.78571C5.00224 7.78571 4.07062 7.78571 3.54405 7.78571C3.42254 8.65318 1.43778 21.9544 1.28589 23.0039H9.16415C9.23504 22.5434 9.59958 20.0267 10.0958 16.696Z'
        fill='#193E4F'
      />
      <path
        d='M0.86069 26.0884H4.09098C5.46816 26.0884 5.80233 26.8273 5.70106 27.502C5.62005 28.0482 5.26563 28.4552 4.65805 28.7229C5.42765 29.0335 5.73144 29.5154 5.62005 30.2758C5.47828 31.2289 4.69856 31.9358 3.6758 31.9358H-4.57764e-05L0.86069 26.0884ZM2.99734 28.5087C3.62517 28.5087 3.91883 28.1553 3.97959 27.7376C4.04035 27.2878 3.84795 26.9773 3.22012 26.9773H2.66317L2.44039 28.5087H2.99734ZM2.65305 31.0255C3.30113 31.0255 3.6758 30.747 3.76694 30.1794C3.83782 29.6868 3.57454 29.3976 2.94671 29.3976H2.31888L2.07585 31.0362H2.65305V31.0255Z'
        fill='#193E4F'
      />
      <path
        d='M10.1465 31.9785C9.30602 32.0427 8.90097 31.9464 8.69844 31.5608C8.25289 31.85 7.7567 31.9999 7.23013 31.9999C6.27826 31.9999 5.94409 31.4751 6.03522 30.8968C6.07573 30.6184 6.22762 30.3506 6.47066 30.1257C6.99722 29.6438 8.29339 29.5796 8.79971 29.2154C8.84021 28.8085 8.68832 28.6585 8.21238 28.6585C7.65543 28.6585 7.18962 28.8513 6.38964 29.4296L6.58205 28.1016C7.27063 27.5769 7.93897 27.3306 8.70857 27.3306C9.69082 27.3306 10.5617 27.7589 10.3997 28.8942L10.2073 30.1793C10.1364 30.6291 10.1566 30.7683 10.6326 30.779L10.1465 31.9785ZM8.68832 29.9544C8.24276 30.2543 7.4124 30.2007 7.32126 30.8219C7.28076 31.111 7.45291 31.3252 7.72632 31.3252C7.9896 31.3252 8.31364 31.2074 8.57693 31.0146C8.55667 30.9075 8.5668 30.8004 8.59718 30.597L8.68832 29.9544Z'
        fill='#193E4F'
      />
      <path
        d='M11.7159 27.4055H13.3969L13.3058 27.9946C13.8425 27.5126 14.2475 27.3306 14.7741 27.3306C15.7158 27.3306 16.1512 27.941 15.9994 28.937L15.5639 31.9249H13.883L14.2475 29.451C14.3184 29.0012 14.1867 28.7871 13.8627 28.7871C13.5994 28.7871 13.3564 28.937 13.1235 29.269L12.7387 31.9142H11.0577L11.7159 27.4055Z'
        fill='#193E4F'
      />
      <path
        d='M17.3159 26.0884H18.9969L18.5716 28.9585L20.1817 27.4056H22.2575L20.1918 29.3334L21.8525 31.9251H19.7361L18.4602 29.826H18.4399L18.1361 31.9143H16.4552L17.3159 26.0884Z'
        fill='#193E4F'
      />
      <path
        d='M23.2599 26.0884H25.1941L24.3434 31.9251H22.4093L23.2599 26.0884Z'
        fill='#193E4F'
      />
      <path
        d='M26.1359 26.0884H28.9004C31.037 26.0884 31.6547 27.7269 31.4522 29.087C31.2598 30.415 30.2674 31.9251 28.394 31.9251H25.2751L26.1359 26.0884ZM27.9282 30.5328C28.87 30.5328 29.3864 30.0402 29.5383 29.0014C29.6497 28.2303 29.4269 27.4699 28.3839 27.4699H27.8675L27.4219 30.5328H27.9282Z'
        fill='#193E4F'
      />
      <path
        d='M20.688 0H12.6376L11.5642 7.2075H12.9313C13.6806 7.2075 14.3895 6.84337 14.6932 6.31861C14.7945 6.14726 14.835 5.99732 14.835 5.8581C14.835 5.55823 14.6426 5.33333 14.4502 5.1834C13.9236 4.76573 13.8122 4.32664 13.8122 4.01606C13.8122 3.95181 13.8123 3.89826 13.8224 3.84471C13.9338 3.08434 14.9059 2.25971 16.1919 2.25971C16.9615 2.25971 17.5489 2.45248 17.9033 2.80589C18.2172 3.11647 18.3387 3.55556 18.2476 4.01606C18.1362 4.56225 17.6197 5.01205 17.3261 5.23695C16.5464 5.81526 16.6476 6.31861 16.6982 6.46854C16.8603 6.91834 17.478 7.2075 17.9539 7.2075H20.0399C20.0399 7.2075 20.0399 7.2075 20.0399 7.21821C22.8753 7.23962 24.3942 8.62115 23.9183 11.834C23.4727 14.822 21.3057 16.1071 18.7235 16.1285L17.7007 23.0254H19.2096C25.579 23.0254 30.7839 18.6988 31.8067 11.8661C33.0725 3.3842 27.9789 0 20.688 0Z'
        fill='#193E4F'
      />
    </SvgRoot>
  );
}
