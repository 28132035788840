import { styled } from "@mui/system";
import Stepper, { stepperClasses } from "@mui/material/Stepper";

export const LsbStepper = styled(Stepper)(({ theme }) => ({
  [`&.${stepperClasses.root}`]: {
    padding: 0,
  },

  [`&.${stepperClasses.horizontal}`]: {
    justifyContent: "space-between",
    marginLeft: "-28px",
    marginRight: "-28px",

    [theme.breakpoints.between(320, 375)]: {
      marginLeft: "-14px",
      marginRight: "-14px",
    },

    [theme.breakpoints.up("sm")]: {
      marginLeft: "calc(-13.5% - 5px)",
      marginRight: "calc(-13.5% - 5px)",
    },

    [theme.breakpoints.up("lg")]: {
      marginLeft: "calc(-13.5% - 15px)",
      marginRight: "calc(-13.5% - 15px)",
    },
  },

  "&.five-steps": {
    [theme.breakpoints.down(375)]: {
      marginLeft: "-10px",
      marginRight: "-10px",
    },
    [theme.breakpoints.between(375, 412)]: {
      marginLeft: "-16px",
      marginRight: "-16px",
    },
    [theme.breakpoints.between(412, 499)]: {
      marginLeft: "-20px",
      marginRight: "-20px",
    },
    [theme.breakpoints.between(500, 600)]: {
      marginLeft: "-40px",
      marginRight: "-40px",
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: "-10.3%",
      marginRight: "-10.3%",
    },

    [theme.breakpoints.up("md")]: {
      marginLeft: "-10.5%",
      marginRight: "-10.5%",
    },

    [theme.breakpoints.up("lg")]: {
      marginLeft: "-11%",
      marginRight: "-11%",
    },
  },

  [`&.three-steps`]: {
    [theme.breakpoints.down(375)]: {
      marginLeft: "-42px",
      marginRight: "-42px",
    },
    [theme.breakpoints.between(375, 412)]: {
      marginLeft: "-55px",
      marginRight: "-55px",
    },
    [theme.breakpoints.between(412, 500)]: {
      marginLeft: "-65px",
      marginRight: "-65px",
    },
    [theme.breakpoints.between(500, 600)]: {
      marginLeft: "-93px",
      marginRight: "-93px",
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: "calc(-22.5% - 15px)",
      marginRight: "calc(-22.5% - 15px)",
    },
  },
}));
