import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import React, { useState } from "react";
import { faSignOut, faChevronLeft } from "@fortawesome/pro-regular-svg-icons";
import { ModalLayout } from "./ModalLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PrimaryButton, RedButton, TextButton } from "../CTAButtons/CTAButtons";
import { ModalDialog, ModalGridContent } from "../../styles/ModalComponents";
import { logout } from "../../auth-service/auth-service";
import { Heading } from "../misc/Typography/Heading";
import { Paragraph } from "../misc/Typography/Paragraph";
import { VictoryIcon } from "../SvgIcons/VictoryIcon/VictoryIcon";
import { SupportIcon } from "../SvgIcons/SupportIcon/SupportIcon";

export const ChangeRequestModal = props => {
  const theme = useTheme();
  const [showThanksFragment, setShowThanksFragment] = useState(false);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isSmallTablet = useMediaQuery(theme.breakpoints.between(599, 961));

  return (
    <ModalDialog
      open={props.openModal}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      closeAfterTransition
      disableAutoFocus={true}
      PaperComponent={ModalLayout}
      hideBackdrop={isSmallScreen}
      fullScreen={isSmallScreen}
      scroll={isSmallTablet ? "body" : "paper"}
    >
      <Grid container justifyContent='center'>
        <ModalGridContent item xs={12} sm={8} md={7} lg={6}>
          {!showThanksFragment && (
            <>
              <SupportIcon />
              <Heading>Ändra din ansökan</Heading>

              <Paragraph textLight>
                Om du vill ändra ditt belopp, eller har några frågor gällande
                din ansökan, så ringer vi upp dig snarast!
              </Paragraph>

              <Box mt={5} maxWidth='100%'>
                <RedButton
                  variant='contained'
                  onClick={() => setShowThanksFragment(true)}
                >
                  Jag önskar att bli uppringd
                </RedButton>

                <TextButton
                  variant='text'
                  disableRipple
                  startIcon={<FontAwesomeIcon icon={faChevronLeft} />}
                  onClick={() => {
                    props.closeModal("changeRequest");
                  }}
                >
                  Nej, gå tillbaks till kompletteringen
                </TextButton>
              </Box>
            </>
          )}

          {showThanksFragment && (
            <>
              <VictoryIcon />

              <Heading>Tack för att du väljer oss!</Heading>

              <Paragraph textLight>
                Du kommer få hjälp av vår kundtjänst som ringer upp dig snarast
                möjligt.
              </Paragraph>

              <Box mt={5}>
                <PrimaryButton
                  variant='contained'
                  endIcon={<FontAwesomeIcon icon={faSignOut} />}
                  onClick={logout}
                >
                  Logga ut
                </PrimaryButton>
              </Box>
            </>
          )}
        </ModalGridContent>
      </Grid>
    </ModalDialog>
  );
};
