import React from "react";
import { Container, Grid, styled } from "@mui/material";

const InfoSectionRoot = styled(Container)(
  ({ theme, neutral, aligncenter, nopaddingsy }) => ({
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    backgroundColor: neutral
      ? theme.palette.neutralColor[4]
      : theme.palette.primaryColor[10],
    padding: nopaddingsy
      ? `${theme.spacing(4)} 0`
      : `${theme.spacing(8)} ${theme.spacing(2.5)}`,
    color: neutral
      ? theme.palette.primaryColor[150]
      : theme.palette.neutralColor[90],
    fontSize: neutral && "1rem",
    textAlign: aligncenter ? "center" : "left",
  })
);

const InfoSectionTitle = styled("h2")(({ theme, neutral }) => ({
  color: neutral ? theme.palette.neutral.main : theme.palette.primaryColor[150],
  fontSize: neutral ? "1.25rem" : "2rem",
  fontWeight: 900,
  lineHeight: neutral ? "1.875rem" : theme.spacing(5),
  letterSpacing: "0.6px",
  margin: 0,
  padding: neutral ? 0 : "0 0 0.5rem 0",

  [theme.breakpoints.down("sm")]: {
    fontSize: "1.625rem",
    lineHeight: "2.125rem",
    letterSpacing: "0.49px",
  },
}));

const InfoSectionContent = styled("div")(({ theme, neutral }) => ({
  padding: neutral ? `${theme.spacing(2.5)} 0` : `${theme.spacing(4)} 0`,
}));

export const InfoSection = ({
  title,
  content,
  neutral,
  alignCenter,
  noPaddings,
}) => {
  return (
    <InfoSectionRoot
      maxWidth={false}
      aligncenter={alignCenter && "align-center"}
      neutral={neutral && "neutral"}
      nopaddingsy={noPaddings && "no-padding-y"}
    >
      <Container justify='center'>
        <Grid container justifyContent='center'>
          <Grid item xs={12} sm={8} lg={6}>
            <InfoSectionTitle neutral={neutral && "neutral"}>
              {title}
            </InfoSectionTitle>

            <InfoSectionContent neutral={neutral && "neutral"}>
              {content}
            </InfoSectionContent>
          </Grid>
        </Grid>
      </Container>
    </InfoSectionRoot>
  );
};
