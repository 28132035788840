import { Fade, Grid, styled } from "@mui/material";
import React from "react";

const Wrapper = styled("div")(({ theme, paddingsm }) => ({
  display: "flex",
  flexDirection: "column",
  paddingLeft: theme.spacing(2.5),
  paddingRight: theme.spacing(2.5),
  paddingTop: paddingsm ? "36px" : theme.spacing(5),

  [theme.breakpoints.down("sm")]: {
    paddingTop: paddingsm ? "20px" : theme.spacing(5),
  },
}));

const Content = styled("div")(({ theme, alignleft, fullheight }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: alignleft ? "left" : "center",
  minHeight: fullheight ? "unset" : "380px",
  justifyContent: fullheight ? "flex-start" : "center",
  paddingBottom: fullheight ? theme.spacing(6) : theme.spacing(8),
}));

export const StatusContentLayout = props => {
  return (
    <Fade in={props.fadeIn} timeout={800}>
      <Grid
        item
        lg={props?.narrow ? 6 : 9}
        md={props?.narrow ? 6 : 9}
        sm={props?.narrow ? 8 : 9}
        xs={12}
      >
        <Wrapper paddingsm={props?.paddingSm && "padding-sm"}>
          <Content
            fullheight={props?.fullHeight && "full-height"}
            alignleft={props.textAlign && "align-left"}
          >
            {props.children}
          </Content>
        </Wrapper>
      </Grid>
    </Fade>
  );
};
