import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  forwardRef,
} from "react";

import {
  Container,
  Step,
  StepLabel,
  Button,
  Grid,
  StepButton,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronLeft,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";

import * as Scroll from "react-scroll";
import { LoansToResolveStep } from "../step_loansToResolve/LoansToResolveStep";
import { OtherCountriesTaxesStep } from "../step_otherCountriesTaxes/OtherCountriesTaxesStep";

import { ReviewStep } from "../step_review/ReviewStep";

import { sanitizedString } from "../helpers/SanitizedString";
import { CompletionContext } from "../contexts/CompletionContext";
import { MoreAboutYouStep } from "../step_MoreAboutYou/MoreAboutYouStep";
import { CitizenshipStep } from "../step_citizenship/CitizenshipStep";
import { loanStatus } from "../../config/loanStatus";
import { StatusPageScreen } from "../StatusPage/StatusPageScreen";
import { Spinner } from "../misc/Spinner/Spinner";
import { InfoSection } from "../InfoSection/InfoSection";
import { ProgressList } from "../QuickHelp/ProgressList";
import { ButtonGroup } from "../ButtonGroup/ButtonGroup";
import { LsbStepper } from "../Stepper/LsbStepper";
import { LsbStepConnector } from "../Stepper/LsbStepConnector";
import { LsbStepIcon } from "../Stepper/LsbStepIcon";
import { BackToReview } from "../BackToReview/BackToReview";
import { NextButton } from "../CTAButtons/CTAButtons";
import { LineShadow, classes } from "../../styles/ApplicationFormComponents";

export const CompletionForm = forwardRef((props, ref) => {
  const {
    stepsCompleted,
    stepsStateChange,
    handleStepsCompleted,
    resetContext,
    status,
    interfaceText,
    hasLoansToResolve,
    validating,
    requestStatus,
    completionData,
    refetchSettings,
    reviewInit,
  } = useContext(CompletionContext);

  const [activeStep, setActiveStep] = useState(0);

  const [completed, setCompleted] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [checkStep, setCheckStep] = useState(false);
  const [formSent, setformSent] = useState(false);
  const [fadeIn, setfadeIn] = useState(true);
  const [agreedTerms, setAgreedTerms] = useState(false);
  const [backToReview, setBackToReview] = useState(false);
  const [disableMove, setDisableMove] = useState(false);

  const scroll = Scroll.animateScroll;

  const otherTaxDomicileTitle = sanitizedString(
    interfaceText?.otherTaxDomicileTitle || "Annan skatterättslig hemvist"
  );
  const citizenshipTitle = sanitizedString(
    interfaceText?.citizenshipTitle || "Medborgarskap"
  );
  const reviewInfoTitle = sanitizedString(
    interfaceText?.reviewInfoTitle || "Granska"
  );

  const loanRepaymentTitle = sanitizedString(
    interfaceText?.loanRepaymentTitle || "Lån som ska lösas in"
  );

  const moreAboutYouTitle = sanitizedString(
    interfaceText?.moreAboutYouTitle || "Mer kunskap om dig"
  );

  const getSteps = loans => {
    var steps = [
      citizenshipTitle,
      otherTaxDomicileTitle,
      moreAboutYouTitle,
      reviewInfoTitle,
    ];
    if (loans) {
      steps.unshift(loanRepaymentTitle);
    }
    return steps;
  };

  // states to handle indicator's animation:
  // stepActiveAnimated - collection of steps which were animated while being active but not completed
  // this state should be set 'true' for every step once step became active
  const [stepActiveAnimated, setStepActiveAnimated] = useState({});

  // stepCompletedAnimated - collection of steps which were animated while being completed
  // this state should be set 'true' for every step once step became completed
  const [stepCompletedAnimated, setStepCompletedAnimated] = useState({
    0: status === loanStatus.acceptedByCustomer ? true : false,
  });

  const steps = getSteps(hasLoansToResolve);

  const reviewStepIndex = steps.length - 1;

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return hasLoansToResolve ? (
          <LoansToResolveStep
            stepIndex={stepIndex}
            fadeIn={fadeIn}
            handleMove={handleNext}
            disableCheckStep={disableCheckStep}
            checkCompleted={checkStep}
            setDisableMove={setDisableMove}
          />
        ) : (
          <CitizenshipStep
            stepIndex={stepIndex}
            fadeIn={fadeIn}
            handleMove={handleNext}
            disableCheckStep={disableCheckStep}
            checkCompleted={checkStep}
            setDisableMove={setDisableMove}
          />
        );
      case 1:
        return hasLoansToResolve ? (
          <CitizenshipStep
            stepIndex={stepIndex}
            fadeIn={fadeIn}
            handleMove={handleNext}
            disableCheckStep={disableCheckStep}
            checkCompleted={checkStep}
            setDisableMove={setDisableMove}
          />
        ) : (
          <OtherCountriesTaxesStep
            stepIndex={stepIndex}
            fadeIn={fadeIn}
            handleMove={handleNext}
            disableCheckStep={disableCheckStep}
            checkCompleted={checkStep}
            setDisableMove={setDisableMove}
          />
        );
      case 2:
        return hasLoansToResolve ? (
          <OtherCountriesTaxesStep
            stepIndex={stepIndex}
            fadeIn={fadeIn}
            handleMove={handleNext}
            disableCheckStep={disableCheckStep}
            checkCompleted={checkStep}
            setDisableMove={setDisableMove}
          />
        ) : (
          <MoreAboutYouStep
            stepIndex={stepIndex}
            fadeIn={fadeIn}
            handleMove={handleNext}
            disableCheckStep={disableCheckStep}
            checkCompleted={checkStep}
            setDisableMove={setDisableMove}
          />
        );
      case 3:
        return hasLoansToResolve ? (
          <MoreAboutYouStep
            stepIndex={stepIndex}
            fadeIn={fadeIn}
            handleMove={handleNext}
            disableCheckStep={disableCheckStep}
            checkCompleted={checkStep}
            setDisableMove={setDisableMove}
          />
        ) : (
          <ReviewStep
            stepIndex={stepIndex}
            fadeIn={fadeIn}
            submitForm={formSubmitted}
            handleFinish={handleLastStep}
            editStep={handleStep}
            toggleFormSubmitted={setFormSubmitted}
            setAgreedTerms={setAgreedTerms}
            setDisableMove={setDisableMove}
          />
        );
      case 4:
        return hasLoansToResolve ? (
          <ReviewStep
            stepIndex={stepIndex}
            fadeIn={fadeIn}
            submitForm={formSubmitted}
            handleFinish={handleLastStep}
            editStep={handleStep}
            toggleFormSubmitted={setFormSubmitted}
            setAgreedTerms={setAgreedTerms}
            setDisableMove={setDisableMove}
          />
        ) : (
          ""
        );
      default:
        return null;
    }
  }

  const handleLastStep = isSubmitted => {
    if (isSubmitted) {
      setformSent(true);
      setfadeIn(true);
      setActiveStep(steps.length);
      handleStepsCompleted(activeStep, true);
      setTimeout(() => {
        removeAnimationCompleted();
      }, 2000);
    }
  };

  const disableCheckStep = () => {
    setCheckStep(false);
  };

  const handleCheckStep = () => {
    removeAnimationActiveStep(activeStep);
    setCheckStep(true);
  };

  const [isLastStep, setIsLastStep] = useState(false);

  const checkIfLastStep = () => {
    let check = activeStep === getSteps(hasLoansToResolve).length - 1;
    setIsLastStep(check);
  };

  useEffect(() => {
    checkIfLastStep();
  });

  // functions to handle indicator's animation

  const removeAnimationActiveStep = useCallback(
    step => {
      let newAnimated = stepActiveAnimated;
      newAnimated[step] = true;
      setStepActiveAnimated(newAnimated);
    },
    [stepActiveAnimated]
  );

  const addAnimationActiveStep = useCallback(
    step => {
      let newAnimated = stepActiveAnimated;
      newAnimated[step] = false;
      setStepActiveAnimated(newAnimated);
    },
    [stepActiveAnimated]
  );

  const removeAnimationCompletedStep = useCallback(
    step => {
      let newAnimated = stepCompletedAnimated;
      newAnimated[step] = true;
      setStepCompletedAnimated(newAnimated);
    },
    [stepCompletedAnimated]
  );

  const addAnimationCompletedStep = useCallback(
    step => {
      let stepsToAnimate = stepCompletedAnimated;
      stepsToAnimate[step] = false;
      setStepCompletedAnimated(stepsToAnimate);
    },
    [stepCompletedAnimated]
  );

  const removeAnimationCompleted = () => {
    removeAnimationCompletedStep(activeStep);
  };

  // const resetAllAnimation = step => {
  //   addAnimationActiveStep(step);
  //   addAnimationCompletedStep(step);
  // };

  const handleNext = _.debounce(
    () => {
      if (backToReview) {
        handleBackToReviewStep();
      } else {
        handleNextStep();
      }
    },
    2000,
    { leading: true, trailing: false, maxWait: 2000 }
  );

  const handleNextStep = () => {
    const Events = Scroll.Events;
    setfadeIn(true);

    Events.scrollEvent.register("end", () => {
      setActiveStep(prevActiveStep => prevActiveStep + 1);

      Events.scrollEvent.remove("end");

      setDisableMove(false);
    });
    scroll.scrollToTop({
      smooth: "easeOutQuad",
      duration: 300,
    });
  };

  const handleBack = () => {
    const Events = Scroll.Events;

    setfadeIn(true);
    Events.scrollEvent.register("end", () => {
      setActiveStep(prevActiveStep => prevActiveStep - 1);
      Events.scrollEvent.remove("end");
    });

    scroll.scrollToTop({
      smooth: "easeOutQuad",
      duration: 300,
    });
  };

  const checkCompletedSteps = clickStep => {
    if (clickStep === 0) {
      return false;
    }

    const previousSteps = Object.keys(stepsCompleted).filter(
      step => parseInt(step) < clickStep
    );

    return previousSteps.some(step => stepsCompleted[parseInt(step)] === false);
  };

  const handleStep = step => () => {
    const checkPreviousSteps = checkCompletedSteps(step);
    if (step === 0 && stepsCompleted[step]) {
      const Events = Scroll.Events;

      setfadeIn(true);
      Events.scrollEvent.register("end", () => {
        setActiveStep(step);
        Events.scrollEvent.remove("end");
      });

      scroll.scrollToTop({
        smooth: "easeOutQuad",
        duration: 300,
      });
    } else {
      //to prevent move to the next step in case if user completed more than 2 steps, returned to previous step,
      //removed value from required field (incomplete step) and try to go to the next step via step-indicator buttons
      if (stepsCompleted[step] && !checkPreviousSteps) {
        const Events = Scroll.Events;

        setfadeIn(true);
        Events.scrollEvent.register("end", () => {
          setActiveStep(step);
          Events.scrollEvent.remove("end");
        });

        scroll.scrollToTop({
          smooth: "easeOutQuad",
          duration: 300,
        });
      }
    }

    if (stepsCompleted[activeStep]) {
      removeAnimationCompleted();
    } else {
      addAnimationActiveStep(activeStep);
    }
  };

  const onClickBackToReview = () => {
    setBackToReview(true);
    handleCheckStep();
  };

  const handleBackToReviewStep = () => {
    const checkPreviousSteps = checkCompletedSteps(reviewStepIndex);

    if (!checkPreviousSteps) {
      const Events = Scroll.Events;

      setfadeIn(true);
      Events.scrollEvent.register("end", () => {
        setActiveStep(reviewStepIndex);
        Events.scrollEvent.remove("end");

        setDisableMove(false);
      });

      scroll.scrollToTop({
        smooth: "easeOutQuad",
        duration: 300,
      });
    }

    setBackToReview(false);
  };

  const handleSubmit = () => {
    setFormSubmitted(true);

    scroll.scrollToTop({
      smooth: "easeOutQuad",
      duration: 300,
    });
  };

  const handleReset = () => {
    resetContext();
    setCompleted({});
    setFormSubmitted(false);
    setformSent(false);
  };

  useEffect(() => {
    let diff = true;

    const compare = () => {
      diff = _.isEqual(stepsCompleted, completed);
    };

    if (stepsCompleted[activeStep]) {
      removeAnimationActiveStep(activeStep);
      setTimeout(() => {
        removeAnimationCompletedStep(activeStep);
      }, 500);
    }

    if (!stepsCompleted[activeStep]) {
      addAnimationCompletedStep(activeStep);
    }

    compare();

    if (!diff) {
      setCompleted(stepsCompleted);
    }
  }, [
    stepsStateChange,
    stepsCompleted,
    completed,
    formSubmitted,
    activeStep,
    removeAnimationCompletedStep,
    removeAnimationActiveStep,
    addAnimationCompletedStep,
  ]);

  useEffect(() => {
    setTimeout(() => {
      removeAnimationActiveStep(activeStep);
    }, 500);
  }, [activeStep, removeAnimationActiveStep]);

  useEffect(() => {
    if (validating) {
      return;
    }
    const step = parseInt(
      (Object.entries(stepsCompleted).find(([key, value]) => !value) ??
        Object.entries(stepsCompleted).slice(-1)[0])[0]
    );
    setActiveStep(step);
  }, [stepsCompleted, validating]);

  useEffect(() => {
    if (status === loanStatus.acceptedByCustomer && interfaceText === null) {
      refetchSettings.refetchInterfaceText();
      refetchSettings.refetchConfig();
      refetchSettings.refetchBanks();
    }
  }, [interfaceText, refetchSettings, status]);

  if (validating) {
    return null;
  }

  if (
    (status === loanStatus.acceptedByCustomer ||
      status === loanStatus.accepted) &&
    interfaceText === null
  ) {
    return <Spinner fullscreen />;
  }

  return (
    <React.Fragment>
      <Container>
        <Grid container justifyContent='center' sx={classes.overflowHidden}>
          <Grid item xs={12} sm={10}>
            <div style={classes.root}>
              <LineShadow />

              <LsbStepper
                nonLinear
                activeStep={activeStep}
                connector={<LsbStepConnector />}
                alternativeLabel
                classes={{ horizontal: hasLoansToResolve ? "five-steps" : "" }}
              >
                {steps.map((label, index) => {
                  return (
                    <Step
                      key={label}
                      completed={completed[index]}
                      disabled={formSent || !completed[index]}
                    >
                      <StepButton
                        onClick={handleStep(index)}
                        disableRipple={true}
                        id={`stepButton-${index}`}
                      >
                        <StepLabel
                          StepIconComponent={LsbStepIcon}
                          StepIconProps={{
                            index: index,
                            stepCompleted: stepsCompleted[index],
                            isActiveAnimated: stepActiveAnimated[index]
                              ? true
                              : false,
                            isCompletedAnimated: stepCompletedAnimated[index]
                              ? true
                              : false,
                          }}
                        >
                          {label}
                        </StepLabel>
                      </StepButton>
                    </Step>
                  );
                })}
              </LsbStepper>
            </div>
          </Grid>
        </Grid>
      </Container>

      {activeStep === steps.length ? (
        handleReset && (
          <StatusPageScreen
            status={status}
            formSubmitted
            updateStatus={requestStatus}
            statusInterfaceText={completionData.statusInterfaceText}
          />
        )
      ) : (
        <React.Fragment>
          {/* Steps instructions */}
          {getStepContent(activeStep)}
          {/* Buttons */}
          <Container justify='center' className={classes.paddingBottomSm}>
            <Grid container justifyContent='center'>
              <Grid item xs={12} sm={10}>
                <ButtonGroup
                  justifyEnd={activeStep === 0}
                  layoutXS={isLastStep}
                >
                  {activeStep !== 0 && (
                    <Button
                      variant='outlined'
                      disabled={activeStep === 0 || checkStep}
                      startIcon={<FontAwesomeIcon icon={faChevronLeft} />}
                      onClick={handleBack}
                      disableRipple={true}
                      id='moveBackButton'
                    >
                      Föregående steg
                    </Button>
                  )}

                  <NextButton
                    disabled={
                      (isLastStep && !agreedTerms) ||
                      (!isLastStep && !stepsCompleted[activeStep])
                    }
                    processing={disableMove ? "processing" : "none"}
                    variant='contained'
                    color='primary'
                    id='moveForwardButton'
                    startIcon={
                      isLastStep && <FontAwesomeIcon icon={faPaperPlane} />
                    }
                    endIcon={
                      !isLastStep && <FontAwesomeIcon icon={faChevronRight} />
                    }
                    onClick={isLastStep ? handleSubmit : handleCheckStep}
                  >
                    {isLastStep ? "Skicka in uppgifter" : "Nästa steg"}
                  </NextButton>
                </ButtonGroup>
              </Grid>
            </Grid>
          </Container>
        </React.Fragment>
      )}

      <InfoSection
        noPaddings
        neutral
        title='Var i låneprocessen befinner jag mig?'
        content={<ProgressList status={status} />}
      />

      {reviewInit && (
        <BackToReview
          visibility={
            isLastStep || formSubmitted || activeStep === steps.length
              ? "hidden"
              : "visible"
          }
          disabled={!completed[activeStep]}
          handleOnClick={onClickBackToReview}
        />
      )}
    </React.Fragment>
  );
});
