import React from "react";
import { Container, Grid, useMediaQuery, useTheme } from "@mui/material";

export const Section = props => {
  const { noguttersXS } = props;

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Container disableGutters={noguttersXS && isSmallScreen}>
      <Grid container justifyContent='center'>
        <Grid item sm={10} container>
          {props.children}
        </Grid>
      </Grid>
    </Container>
  );
};
