import { gql } from "@apollo/client";

export const USER_ACCEPT_LOAN_OFFER = gql`
  mutation userAcceptLoanOffer {
    acceptLoanOffer {
      accepted
      statusAcceptInterfaceText {
        label
        title
        description
      }
      errors
    }
  }
`;
