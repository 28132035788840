import { Container, Grid, styled } from "@mui/material";
import React from "react";
import { Name } from "../misc/Typography/Name";

const CoAppSectionRoot = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.greyColor.light,
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(4),
  marginTop: theme.spacing(5),
}));

const CoAppSectionHeading = styled("h2")(({ theme, inreview }) => ({
  fontSize: inreview ? "32px" : "20px",
  lineHeight: "40px",
  fontWeight: 900,
  margin: 0,
  paddingBottom: "18px",
  color: theme.palette.neutral.main,

  [theme.breakpoints.down("sm")]: {
    fontSize: inreview && "24px",
    lineHeight: inreview && "32px",
    paddingLeft: inreview && "20px",
    paddingRight: inreview && "20px",
  },
}));

export const CoApplicantSection = ({
  title,
  personalData,
  children,
  disableGutters,
  inReview = false,
}) => {
  return (
    <CoAppSectionRoot>
      <Container disableGutters={disableGutters}>
        <Grid container justifyContent='center'>
          <Grid item sm={10} xs={12}>
            {!inReview && personalData && <Name personalData={personalData} />}
            {!personalData && title && (
              <CoAppSectionHeading inreview={inReview}>
                {title}
              </CoAppSectionHeading>
            )}

            {children}
          </Grid>
        </Grid>
      </Container>
    </CoAppSectionRoot>
  );
};
