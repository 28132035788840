import React from "react";
import { styled } from "@mui/material";

const Root = styled("p")(({ theme, bold, left, textlight }) => ({
  fontSize: "1rem",
  lineHeight: "1.5rem",
  margin: "0 0 18px 0",
  color: textlight ? theme.palette.text.lighter : theme.palette.neutral.main,
  fontWeight: bold && 700,
  textAlign: left && "left",

  "&:last-of-type": {
    marginBottom: 0,
  },
}));

export const Paragraph = ({ bold, textAlignLeft, textLight, ...props }) => {
  return (
    <Root
      bold={bold && "bold"}
      left={textAlignLeft && "left"}
      textlight={textLight && "light"}
    >
      {props.children}
    </Root>
  );
};
