import { styled } from "@mui/material";
import React from "react";

const Image = styled("img")(({ theme }) => ({
  display: "block",
  margin: "auto",
  width: "150px",
  height: "150px",
}));

export const StatusFragmentIcon = ({ icon }) => {
  return (
    <div style={{ paddingBottom: "32px" }}>
      <Image src={icon} alt='Icon' />
    </div>
  );
};
