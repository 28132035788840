import React, { useState } from "react";

import { Slide, styled } from "@mui/material";
import { PrimaryButton, TextButton } from "../CTAButtons/CTAButtons";

import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { advices } from "./advices";

const Root = styled("div")(({ theme }) => ({
  overflow: "hidden",
  "& h2": {
    fontSize: "1.25rem",
    lineHeight: "1.5rem",
    fontWeght: 900,
  },
}));

const ButtonGroup = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  position: "relative",
  bottom: 0,
  left: 0,
  width: "100%",
  paddingTop: "50px",
}));

export const EconomicAdviceSlider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [direction, setDirection] = useState("next");

  const handleNext = () => {
    if (currentSlide === advices.length - 1) {
      return;
    }
    setDirection("next");
    setCurrentSlide(prev => prev + 1);
  };

  const handleBack = () => {
    setDirection("back");

    setCurrentSlide(prev => prev - 1);
  };

  if (currentSlide === undefined) {
    return null;
  }

  return (
    <Root>
      {advices.map((item, index) => {
        return (
          <Slide
            in={currentSlide === index}
            key={index}
            direction={direction === "next" ? "left" : "right"}
            timeout={{
              appear: 0,
              enter: currentSlide === 0 && direction !== "back" ? 0 : 500,
            }}
          >
            <div style={{ display: currentSlide === index ? "block" : "none" }}>
              <h2>
                Tips {index + 1} -{item.title}
              </h2>
              <div>
                {item.paragraphs.map((p, index) => {
                  return <p key={index}>{p}</p>;
                })}
              </div>
            </div>
          </Slide>
        );
      })}
      <ButtonGroup>
        <TextButton
          startIcon={<FontAwesomeIcon icon={faChevronLeft} />}
          onClick={handleBack}
          className='cta-little'
          disableRipple
          style={{
            visibility: currentSlide > 0 ? "visible" : "hidden",
            margin: "0 8px 0 0",
            whiteSpace: "nowrap",
          }}
        >
          Föregående tips
        </TextButton>

        <PrimaryButton
          variant='outlined'
          endIcon={<FontAwesomeIcon icon={faChevronRight} />}
          onClick={handleNext}
          disabled={currentSlide === advices.length - 1}
          className='cta-little'
          style={{
            visibility:
              currentSlide === advices.length - 1 ? "hidden" : "visible",
            margin: "0 0 0 8px",
            whiteSpace: "nowrap",
          }}
        >
          Nästa tips
        </PrimaryButton>
      </ButtonGroup>
    </Root>
  );
};
