import { Container, Grid, Paper, styled } from "@mui/material";
import React from "react";

const Root = styled(Container)(({ theme, insideform, hasfooter }) => ({
  paddingBottom: insideform
    ? theme.spacing(2)
    : hasfooter
    ? theme.spacing(6)
    : 0,

  [theme.breakpoints.up("sm")]: {
    paddingBottom: theme.spacing(8),
  },
}));

const StyledPaper = styled(Paper)(
  ({ theme, insideform, hasfooter, centered }) => ({
    position: "relative",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    boxShadow: insideform && "none",
    paddingBottom: hasfooter && 0,
    justifyContent: hasfooter
      ? "space-between"
      : centered
      ? "center"
      : "flex-start",

    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(8),
    },

    [theme.breakpoints.down("sm")]: {
      height: centered && "100vh",
      justifyContent: centered && "flex-start",
    },

    "&.MuiPaper-elevation24": {
      [theme.breakpoints.down("sm")]: {
        boxShadow: "none",
      },
    },
  })
);

export const PaperLayout = props => {
  return (
    <Root
      insideform={props?.displayInsideForm ? "insideform" : null}
      hasfooter={props?.footer ? "hasfooter" : null}
      disableGutters
    >
      <Grid container justifyContent='center'>
        <Grid item xs={12} sm={9} md={8} lg={8} xl={8}>
          <StyledPaper
            elevation={24}
            square
            insideform={props?.displayInsideForm ? "insideform" : null}
            hasfooter={
              props?.footer || props?.shownEconomicAdvices ? "hasfooter" : null
            }
            centered={props?.center ? "centered" : null}
          >
            {props.children}
            {props?.footer}
          </StyledPaper>
        </Grid>
      </Grid>
    </Root>
  );
};
