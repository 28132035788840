import { Box } from "@mui/material";
import React from "react";

import { PrimaryButton } from "../../CTAButtons/CTAButtons";
import { StatusContentLayout } from "./StatusContentLayout";
import { Heading } from "../../misc/Typography/Heading";
import { Paragraph } from "../../misc/Typography/Paragraph";
import { ContextLink } from "../../misc/ContextLink/ContextLink";
import { logout } from "../../../auth-service/auth-service";
import { FailIcon } from "../../SvgIcons/FailIcon/FailIcon";

export const RejectedStatusFragment = props => {
  const { fadeIn } = props;

  const BASE_SITE_URL = process.env.REACT_APP_BASE_URL;

  return (
    <StatusContentLayout fadeIn={fadeIn}>
      <FailIcon />

      <Heading>Din ansökan blev tyvärr inte beviljad</Heading>
      <Paragraph textAlignLeft>
        Utifrån de uppgifter du angivit i din låneansökan och den
        kreditupplysning som inhämtats har vi tyvärr inte möjlighet att bevilja
        önskat lån. I vår bedömning har vi även tagit hänsyn till om ett lägre
        lånebelopp, eller en längre återbetalningstid, kan beviljas.
      </Paragraph>

      <Paragraph textAlignLeft>
        Om du har frågor angående vårt beslut är du välkommen att kontakta oss
        på telefon{" "}
        <ContextLink
          title='Ring oss'
          link='tel:+4640205600'
          text='040-205 600'
        />
        , eller skicka ett mejl till{" "}
        <ContextLink link='mailto:info@lsb.se' text='info@lsb.se' />.
      </Paragraph>

      <Paragraph textAlignLeft>
        Vi vill också passa på att informera om vår{" "}
        <ContextLink
          title='Läs om ekonomiskola'
          link={`${BASE_SITE_URL}/ekonomiskola`}
          text='Ekonomiskola'
        />
        . I Ekonomiskolan kan du läsa intressanta inlägg om olika ämnen inom
        privatekonomi. Där delar vi även med oss av smarta tips och råd som kan
        vara bra för ekonomin.
      </Paragraph>

      <Box pt={4}>
        <PrimaryButton
          variant='outlined'
          className='cta-little'
          onClick={logout}
        >
          Logga ut och stäng
        </PrimaryButton>
      </Box>
    </StatusContentLayout>
  );
};
