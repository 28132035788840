import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { Fade, styled } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { SanitizedHtml } from "../../helpers/SanitizedHtml";
import { useClickOutside } from "../../helpers/UseClickOutside";

const IngressTextCardRoot = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  backgroundColor: theme.palette.whiteFadedColor,
  padding: "16px",
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  position: "relative",

  "& + h1": {
    marginTop: "16px",
  },
}));

const IngressTextCardContent = styled("div")(({ theme }) => ({
  fontSize: "16px",
  lineHeight: "24px",
  color: theme.palette.neutral.main,
  paddingRight: "50px",

  [theme.breakpoints.down("md")]: {
    paddingRight: 0,
    paddingTop: "8px",
  },

  "& p": {
    fontWeight: 400,
    margin: "0 0 24px 0",
    fontSize: "16px",
    lineHeight: "24px",
    color: theme.palette.neutral.main,
  },

  "& h3": {
    margin: 0,
    fontSize: "16px",
  },

  "& p:last-of-type": {
    marginBottom: 0,
  },

  "& p:first-of-type": {
    marginBottom: 24,
  },

  "& ul": {
    paddingLeft: "16px",
    margin: "8px 0",

    "& li": {
      fontWeight: 400,
      paddingLeft: "8px",
      lineHeight: "24px",
    },
  },
}));

const IngressTextCardDismiss = styled("span")(({ theme }) => ({
  display: "block",
  cursor: "pointer",
  order: 2,
  padding: "16px",
  position: "absolute",
  right: 0,
  top: 0,

  [theme.breakpoints.down("md")]: {
    padding: "8px",
  },
}));

export const IngressTextCard = props => {
  const { text, openIngressText, closeIngressText } = props;
  const [onClose, setOnClose] = useState(true);
  const contentRef = useRef(null);

  function closeMe() {
    closeIngressText(false);
    setOnClose(true);
  }

  useEffect(() => {
    if (openIngressText) {
      setOnClose(false);
    }

    return () => {
      setOnClose(true);
    };
  }, [openIngressText]);

  useClickOutside(contentRef, null, () => {
    if (onClose) {
      return;
    }
    closeMe();
  });

  return (
    <Fade in={!onClose} timeout={{ appear: 150, enter: 500, exit: 500 }}>
      <IngressTextCardRoot ref={contentRef}>
        <IngressTextCardDismiss role='button' onClick={() => closeMe()}>
          <FontAwesomeIcon icon={faTimes} />
        </IngressTextCardDismiss>
        <IngressTextCardContent>
          <SanitizedHtml html={text} tags={["p", "h3", "ul", "li"]} />
        </IngressTextCardContent>
      </IngressTextCardRoot>
    </Fade>
  );
};
