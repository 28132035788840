import React from "react";
import { StatusContentLayout } from "./StatusContentLayout";
import { DeclineLoanOfferResult } from "../../DeclineLoanOfferForm/DeclineLoanOfferResult";
import { BlurbHeartIcon } from "../../SvgIcons/BlurbHeartIcon/BlurbHeartIcon";
import { DeleteFileIcon } from "../../SvgIcons/DeleteFileIcon/DeleteFileIcon";

export const UserDeclinedOfferResultFragment = ({
  fadeIn,
  userCancelApplication = false,
}) => {
  //userCancelApplication === true in case if customer accepted offer but cancels application after.

  const heading = userCancelApplication
    ? "Ditt låneärende är makulerat"
    : "Din ansökan om lån är makulerad";

  const message = userCancelApplication
    ? "Din låneansökan är nu makulerad enligt önskemål. Om det blir aktuellt med ett lån igen är du välkommen att göra en ny ansökan via vår hemsida, www.lsb.se, eller höra av dig till oss på telefon 040-205 600."
    : "Du har valt att inte gå vidare med det låneerbjudandet du fick av oss, och ditt ärende är nu makulerat. Om det blir aktuellt med ett lån igen är du välkommen att göra en ny ansökan via vår hemsida, www.lsb.se, eller höra av dig till oss på telefon 040-205 600.";

  return (
    <StatusContentLayout fadeIn={fadeIn}>
      <DeclineLoanOfferResult
        heading={heading}
        message={message}
        buttonText='Logga ut och stäng'
        icon={userCancelApplication ? <DeleteFileIcon /> : <BlurbHeartIcon />}
      />
    </StatusContentLayout>
  );
};
