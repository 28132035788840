import { Box, Container } from "@mui/material";

import React from "react";

import { PrimaryButton } from "../CTAButtons/CTAButtons";

import { logout } from "../../auth-service/auth-service";
import { Heading } from "../misc/Typography/Heading";
import { Paragraph } from "../misc/Typography/Paragraph";

export const DeclineLoanOfferResult = ({
  heading,
  message,
  icon,
  buttonText,
}) => {
  return (
    <Container disableGutters>
      {icon}
      <Heading>{heading}</Heading>

      <Box pb={6}>
        <Paragraph textAlignLeft>{message}</Paragraph>
      </Box>

      <PrimaryButton variant='contained' onClick={() => logout()}>
        {buttonText}
      </PrimaryButton>
    </Container>
  );
};
