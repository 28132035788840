import { Container, Grid, styled } from "@mui/material";

const StatusPageRoot = styled("div")(({ theme }) => ({
  width: "100%",
  zIndex: 1000,
  backgroundColor: "#fff",

  "& .MuiBox-root": {
    maxWidth: "100%",
  },
}));

const StatusPageStatus = styled("p")(({ theme, approved, rejected }) => ({
  width: "100%",
  backgroundColor: "#fff",
  textAlign: "center",
  textTransform: "uppercase",
  lineHeight: "58px",
  margin: 0,
  fontSize: "0.875rem",
  borderBottom: (approved || rejected) && "4px solid",
  borderBottomColor: approved
    ? theme.palette.secondary.main
    : rejected
    ? theme.palette.dangerColor
    : null,

  [theme.breakpoints.up("sm")]: {
    lineHeight: "69px",
    backgroundColor: "#fff",
  },
}));

const StatusPageResult = styled("span")(({ theme, approved, rejected }) => ({
  color: approved
    ? theme.palette.primary.main
    : rejected
    ? theme.palette.dangerColor
    : theme.palette.neutral.main,
  fontFamily: [
    "Lato Heavy",
    "Lato Black",
    "Lato",
    "Segoe UI",
    "Arial",
    "sans-serif",
  ].join(","),
  fontWeight: 600,
}));

const StatusPageStickyMobile = styled(Container)(({ theme }) => ({
  display: "none",

  [theme.breakpoints.down("sm")]: {
    display: "flex",
    position: "sticky",
    top: "80px",
    backgroundColor: "#fff",
    zIndex: 1250,
  },
}));

const StatusPageStickyDesktop = styled("div")(({ theme }) => ({
  display: "none",

  [theme.breakpoints.up("sm")]: {
    display: "flex",
    flexDirection: "column",
    top: "95px",
    backgroundColor: "#fff",
    zIndex: 1150,
  },
}));

const AnimatedGrid = styled(Grid)(({ theme, exit }) => ({
  animation: exit && `$onExit 2s ${theme.transitions.easing.easeInOut}`,
  opacity: exit && 0,
  height: exit && 0,
  transform: exit && "translateY(-200%)",

  "@keyframes onExit": {
    "0%": {
      opacity: 1,
      transform: "translateY(0)",
    },
    "100%": {
      opacity: 0,
      height: 0,
      transform: "translateY(-200%)",
    },
  },
}));

export {
  StatusPageRoot,
  StatusPageStatus,
  StatusPageResult,
  StatusPageStickyMobile,
  StatusPageStickyDesktop,
  AnimatedGrid,
};
