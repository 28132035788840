import React from "react";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { styled } from "@mui/system";

const Button = styled("button")(({ theme }) => ({
  appearance: "none",
  border: 0,
  background: "transparent",
  position: "absolute",
  top: 0,
  right: 0,
  padding: theme.spacing(2),

  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(1),
  },
}));

export const RemoveTaxButton = ({ onRemove, label }) => {
  return (
    <Button onClick={onRemove} aria-label='Ta bort'>
      {label ? label : <FontAwesomeIcon icon={faTimes} />}
    </Button>
  );
};
