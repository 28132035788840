import { styled } from "@mui/material";
import React from "react";

const Root = styled("a")(({ theme }) => ({
  color: theme.palette.primary.main,
  whiteSpace: "nowrap",
}));

export const ContextLink = ({ link, title, text }) => {
  return (
    <Root href={link} title={title}>
      {text}
    </Root>
  );
};
