import * as Scroll from "react-scroll";
import * as _ from "lodash";

import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useRef,
} from "react";
import { Fade, Grid, Container } from "@mui/material";

import { usePreviousValue } from "../helpers/UsePreviousValueHook";

import { Section } from "../Layout/Section";
import { LoanFormComponent } from "./components/LoanFormComponent";
import { SummationComponent } from "./components/SummationComponent";
import { SanitizedHtml } from "../helpers/SanitizedHtml";
import { IngressTextCard } from "../HelpText/IngressText/IngressTextCard";
import { IngressTextLink } from "../HelpText/IngressText/IngressTextLink";
import { Alert } from "../misc/Alert/Alert";

import { sanitizedString } from "../helpers/SanitizedString";
import { CompletionContext } from "../contexts/CompletionContext";
import { useUpdateCompletionData } from "../../graphql/useUpdateCompletionData";
import { StepHeading } from "../misc/Typography/StepHeading";
import { StepPreamble } from "../misc/Typography/StepPreamble";
import { StepContent } from "../Layout/StepContent";
import { HelpTooltip } from "../HelpTooltip/HelpTooltip";
import { InternalLoan } from "./components/InternalLoan";
import {
  AddMoreButton,
  StepSectionPaddingTop,
} from "../../styles/ApplicationFormComponents";

export const LoansToResolveStep = props => {
  const {
    checkCompleted,
    stepIndex,
    handleMove,
    disableCheckStep,
    setDisableMove,
  } = props;

  const [fadeIn, setfadeIn] = useState(false);
  const [loansSaved, setLoansSaved] = useState(false);

  const [tempItem, setTempItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const [checkSum, setCheckSum] = useState(0);
  const [minimumAmountToRepayAchieved, setMinimumAmountToRepayAchieved] =
    useState(false);
  const [amountOverflow, setAmountOverflow] = useState(false);

  const [openIngressText, setOpenIngressText] = useState(false);

  const {
    interfaceText,
    handleStepsCompleted,
    stepsCompleted,
    completionData,
    completionInput,
    banks,
  } = useContext(CompletionContext);

  const initialDebtCollection = completionData.loanRepayments || [];

  const hasInternalLoan = completionData?.hasInternalLoanRepayment ?? false;

  const internalLoanAmount =
    completionData?.requiredResolvedInternalAmount ?? null;

  const [debtsCollection, setDebtsCollection] = useState(initialDebtCollection);

  const updateCompletionData = useUpdateCompletionData();

  const stepCompleted = stepsCompleted[stepIndex];

  const minimumAmountToRepay = completionData.minimumAmountToRepay;

  const totalAmountToRepay = completionData.totalAmountToRepay;

  const approvedAmount = completionData.approvedAmount;

  const feesAmount = completionData.feesAmount;

  const scroll = Scroll.animateScroll;

  const prevCollection = usePreviousValue(debtsCollection);

  const banksError = banks?.errors.length ? true : false;

  const stepRef = useRef(false); // true if step is ready and mounted

  const addDebtToCollection = useCallback(
    debt => {
      setDebtsCollection(currentDebtsCollection => {
        return [...currentDebtsCollection, debt];
      });
      setTempItem(null);

      scroll.scrollToTop({
        smooth: true,
        duration: 300,
      });
    },
    [scroll]
  );

  const createNewDebt = useCallback(() => {
    if (tempItem === null) {
      let debt = { bank: "", id: "n0" };
      setTempItem(debt);
    }
  }, [tempItem]);

  const updateDebtCollection = debt => {
    setDebtsCollection(
      debtsCollection.map(item => {
        return item.id === debt.id ? debt : item;
      })
    );

    scroll.scrollToTop({
      smooth: true,
      duration: 300,
    });
  };

  const removeDebtFromCollection = debt => {
    setDebtsCollection(
      debtsCollection.filter(item => {
        return item.id !== debt.id;
      })
    );

    setLoansSaved(true);

    scroll.scrollToTop({
      smooth: true,
      duration: 300,
    });
  };

  const onAddDebtButton = () => {
    createNewDebt();
  };

  function handleOpenIngressText() {
    setOpenIngressText(true);
  }

  useEffect(() => {
    setMinimumAmountToRepayAchieved(
      checkSum >= minimumAmountToRepay ? true : false
    );
  }, [checkSum, minimumAmountToRepay]);

  useEffect(() => {
    setAmountOverflow(checkSum > approvedAmount - feesAmount);
  }, [approvedAmount, checkSum, feesAmount]);

  useEffect(() => {
    if (stepRef.current === false) return;
    handleStepsCompleted(
      stepIndex,
      minimumAmountToRepayAchieved && !amountOverflow
    );
  }, [
    handleStepsCompleted,
    stepIndex,
    minimumAmountToRepayAchieved,
    amountOverflow,
  ]);

  useEffect(() => {
    if (stepRef.current) return;

    if (!debtsCollection.length) {
      createNewDebt();
    }

    stepRef.current = true;

    return () => {
      stepRef.current = false;
    };
  }, [debtsCollection, completionData, createNewDebt, interfaceText]);

  useEffect(() => {
    if (!stepRef.current) return;

    let sum = debtsCollection.reduce((total, item) => {
      return total + parseFloat(item?.data?.amountToRepay);
    }, 0);

    if (hasInternalLoan && internalLoanAmount) {
      sum = sum + parseFloat(internalLoanAmount);
    }

    setCheckSum(sum);
  }, [debtsCollection, internalLoanAmount, hasInternalLoan]);

  useEffect(() => {
    if (stepCompleted && !loading) {
      setLoansSaved(true);
    }
  }, [stepCompleted, loading, stepIndex]);

  useEffect(() => {
    if (!stepRef.current) return;

    if (interfaceText) {
      setfadeIn(true);
      setLoading(false);
      disableCheckStep();
    }
  }, [disableCheckStep, interfaceText]);

  useEffect(() => {
    if (loansSaved && debtsCollection.length === 0) {
      createNewDebt();
      setLoansSaved(false);
    }
  }, [createNewDebt, debtsCollection, loansSaved]);

  useEffect(() => {
    if (loading && stepRef.current) {
      setLoading(false);
      setfadeIn(true);

      if (debtsCollection.length !== 0) {
        setLoansSaved(true);
      }
    }
  }, [loading, debtsCollection]);

  const sendCompletionData = useCallback(
    async data => {
      const input = {
        ...completionInput,
        loanRepayments: data,
      };

      try {
        const response = await updateCompletionData({
          variables: {
            input: input,
          },
        });

        if (
          !response?.data?.updateCompletion?.errors.length &&
          response?.data?.updateCompletion?.data?.loanRepayments.length
        ) {
          const updatedLoansToResolve =
            response?.data?.updateCompletion?.data?.loanRepayments;

          setDebtsCollection(updatedLoansToResolve);
        }
      } catch (error) {}
    },
    [updateCompletionData, completionInput]
  );

  useEffect(() => {
    if (prevCollection === undefined) {
      return;
    }

    if (!_.isEqual(prevCollection, debtsCollection)) {
      sendCompletionData(debtsCollection);
    }
  }, [debtsCollection, sendCompletionData, prevCollection]);

  useEffect(() => {
    if (checkCompleted && stepsCompleted[stepIndex]) {
      setDisableMove(true);
      handleMove();
    }
  }, [
    checkCompleted,
    handleMove,
    stepIndex,
    stepsCompleted,
    sendCompletionData,
    debtsCollection,
    setDisableMove,
  ]);

  return (
    <Fade in={fadeIn} timeout={{ enter: 500, exit: 500 }}>
      <StepContent>
        <Section>
          <Grid item xs={12}>
            <StepHeading
              title={<SanitizedHtml html={interfaceText?.loanRepaymentTitle} />}
              helpText={
                interfaceText?.loanRepaymentHelp && (
                  <HelpTooltip
                    text={sanitizedString(interfaceText?.loanRepaymentHelp)}
                  />
                )
              }
            />
          </Grid>
          <Grid item sm={8}>
            <StepPreamble>
              <SanitizedHtml html={interfaceText?.loanRepaymentDescription} />

              <IngressTextLink handleOpenIngressText={handleOpenIngressText} />
            </StepPreamble>
          </Grid>

          {openIngressText && (
            <Grid item sm={8}>
              <IngressTextCard
                text={interfaceText?.loanRepaymentIngress}
                openIngressText={openIngressText}
                closeIngressText={setOpenIngressText}
              />
            </Grid>
          )}
        </Section>

        <Section>
          <SummationComponent
            totalDebts={checkSum}
            completed={minimumAmountToRepayAchieved}
            totalAmountToRepay={totalAmountToRepay}
            approvedAmount={approvedAmount}
            amountOverflow={amountOverflow}
            feesAmount={feesAmount}
            internalLoanAmount={internalLoanAmount}
          />
        </Section>

        {/* Debts to resolve */}

        <StepSectionPaddingTop>
          <Section noguttersXS>
            {banksError && (
              <Alert
                type='warning'
                title='Ojdå!'
                message='Det verkar att vi inte kunde hämta banker.'
              />
            )}

            {hasInternalLoan && (
              <Container disableGutters justify='center'>
                <Grid item sm={8} md={6}>
                  <InternalLoan
                    loanAmount={internalLoanAmount}
                    labelBankField={interfaceText?.loanRepaymentBankNameLabel}
                    labelAmountField={
                      interfaceText?.loanRepaymentAmountToRepayLabel
                    }
                  />
                </Grid>
              </Container>
            )}

            {!banksError &&
              debtsCollection.length > 0 &&
              !loading &&
              debtsCollection.map((item, index) => {
                return (
                  <LoanFormComponent
                    item={item}
                    key={item.id}
                    hasInternalLoan={hasInternalLoan}
                    index={index}
                    updateDebt={updateDebtCollection}
                    removeDebt={removeDebtFromCollection}
                    setLoansSaved={setLoansSaved}
                    inReview={false}
                    loading={loading}
                  />
                );
              })}
            {!banksError && tempItem && (
              <LoanFormComponent
                item={tempItem}
                hasInternalLoan={hasInternalLoan}
                index={debtsCollection.length > 0 ? debtsCollection.length : 0}
                updateDebt={addDebtToCollection}
                removeDebt={removeDebtFromCollection}
                setLoansSaved={setLoansSaved}
                loading={loading}
              />
            )}
          </Section>

          <Section>
            {loansSaved && tempItem === null && (
              <AddMoreButton
                color='primary'
                variant={
                  checkSum >= minimumAmountToRepay ? "outlined" : "contained"
                }
                onClick={onAddDebtButton}
              >
                Lägg till fler lån
              </AddMoreButton>
            )}
          </Section>
        </StepSectionPaddingTop>
      </StepContent>
    </Fade>
  );
};
