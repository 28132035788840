import React from "react";
import { Box, styled } from "@mui/material";

import { Heading } from "../../misc/Typography/Heading";
import { Paragraph } from "../../misc/Typography/Paragraph";
import { QuestionBlurbIcon } from "../../SvgIcons/QuestionBlurbIcon/QuestionBlurbIcon";
import { RedButton } from "../../CTAButtons/CTAButtons";

import { StatusContentLayout } from "./StatusContentLayout";
import { ContextLink } from "../../misc/ContextLink/ContextLink";

import { logout } from "../../../auth-service/auth-service";

const ParagraphSmallFont = styled(Paragraph)(({ theme }) => ({
  padding: "12px",
  margin: 0,
}));

export const ProcessingIntermediaryFragment = ({ fadeIn }) => {
  const BASE_SITE_URL = process.env.REACT_APP_BASE_URL;

  return (
    <StatusContentLayout fadeIn={fadeIn}>
      <Box py={2}>
        <QuestionBlurbIcon />

        <Heading>Det finns inget pågående låneärende</Heading>
        <ParagraphSmallFont textAlignLeft>
          Det verkar inte som att det finns någon aktiv låneansökan, eller något
          pågående låneärende, i ditt namn.
        </ParagraphSmallFont>
        <ParagraphSmallFont textAlignLeft>
          Om du vill logga in i Internetbanken gör du det via&nbsp;
          <ContextLink
            link={BASE_SITE_URL}
            text='www.lsb.se'
            title='LSB Lån & Spår Bank'
          />
          . Klicka på&nbsp;
          <span style={{ fontWeight: 700 }}>”Logga in”</span>
          &nbsp;och välj&nbsp;
          <span style={{ fontWeight: 700 }}>”Internetbanken”</span>.
        </ParagraphSmallFont>
        <ParagraphSmallFont textAlignLeft>
          Vid frågor är du välkommen att höra av dig till oss på
          telefon&nbsp;&nbsp;
          <ContextLink
            link='tel:+4640205600'
            text='040-205 600'
            title='Ring oss'
          />
          . Du når oss helgfria vardagar&nbsp;
          <span style={{ whiteSpace: "nowrap" }}>kl. 09.00-16.00</span>
        </ParagraphSmallFont>

        <RedButton variant='contained' onClick={logout}>
          Logga ut
        </RedButton>
      </Box>
    </StatusContentLayout>
  );
};
