import { Box, Dialog, styled } from "@mui/material";

import React, { useContext, useEffect, useRef, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useIntervalHook } from "../helpers/UseIntervalHook";

import { PrimaryButton, RedButton } from "../CTAButtons/CTAButtons";
import { SessionExpiredModalLayout } from "./SessionExpiredModalLayout";
import { logout } from "../../auth-service/auth-service";
import { CompletionContext } from "../contexts/CompletionContext";
import { ExclamationIcon } from "../SvgIcons/ExclamationIcon/ExclamationIcon";

const ExpiredModalDialog = styled(Dialog)(({ theme }) => ({
  outline: "none",
  margin: `0 ${theme.spacing(1.5)}`,

  "& .MuiDialog-container": {
    [theme.breakpoints.up("lg")]: { overflowY: "hidden" },
  },
}));

const ExpiredModalDialogContent = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  padding: "0 1.25rem",

  "& h1": {
    fontFamily: ["Lato Heavy", "Lato", "Segoe UI", "Arial", "sans-serif"].join(
      ","
    ),
    textAlign: "center",
    fontSize: "1.5rem",
    lineHeight: "2rem",
    marginTop: 0,
    fontWeight: 500,

    "& span": {
      fontWeight: 700,
    },
  },

  "& p": {
    fontFamily: ["Lato", "Segoe UI", "Arial", "sans-serif"].join(","),
    fontSize: "1rem",
    lineHeight: "1.5rem",
    margin: 0,
  },
}));

const ExpiredModalDialogMessage = styled("div")(({ theme }) => ({
  maxWidth: "75%",
  margin: "auto",
  paddingTop: "1rem",

  [theme.breakpoints.up("md")]: { maxWidth: "60%" },

  [theme.breakpoints.down("sm")]: { maxWidth: "unset" },
}));

const Buttons = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(5),

  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const getTimeout = sec => {
  if (sec) {
    return sec * 1000;
  }

  return 1000 * 60 * 10;
};

export const SessionExpiredModal = () => {
  const { appSettings } = useContext(CompletionContext);
  const [open, setOpen] = useState(false);
  const [countDown, setCountDown] = useState(60);
  const countDownRef = useRef(countDown);
  countDownRef.current = countDown;

  const handleOnIdle = event => {
    setOpen(true);
  };

  const handleOnActive = event => {
    setOpen(false);
    setCountDown(60);
  };

  const timeout = getTimeout(appSettings?.inactiveTimeUntilLogout);

  useIdleTimer({
    timeout: timeout,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  const updateCountdown = () => {
    if (!open) {
      return;
    }
    setCountDown(countDownRef.current - 1);
  };

  useIntervalHook(updateCountdown, open ? 1000 : null);

  useEffect(() => {
    if (countDown === 0) {
      logout();
    }
  }, [countDown]);

  return (
    <ExpiredModalDialog
      open={open}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      closeAfterTransition
      disableAutoFocus={true}
      disableEscapeKeyDown={true}
      PaperComponent={SessionExpiredModalLayout}
      maxWidth='sm'
      scroll='body'
    >
      <ExpiredModalDialogContent>
        <Box mt={10}>
          <ExclamationIcon />
        </Box>
        <ExpiredModalDialogMessage>
          <h1>
            Du loggas ut om <span>{countDown}</span> sekunder
          </h1>
          <p>
            På grund av inaktivitet kommer du snart att loggas ut automatiskt.
            Vill du fortsätta att vara inloggad?
          </p>
        </ExpiredModalDialogMessage>

        <Buttons>
          <PrimaryButton variant='contained' onClick={handleOnActive}>
            Ja, fortsätta vara inloggad
          </PrimaryButton>
          <RedButton variant='contained' onClick={logout}>
            Nej, logga ut nu
          </RedButton>
        </Buttons>
      </ExpiredModalDialogContent>
    </ExpiredModalDialog>
  );
};
