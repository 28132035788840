import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  throwServerError,
  from,
} from "@apollo/client";

import { RetryLink } from "@apollo/client/link/retry";

import { setContext } from "apollo-link-context";
import { refreshAccessToken } from "../auth-service/auth-service";

const customFetch = (uri, options) => {
  return fetch(uri, options).then(async response => {
    if (
      response.status >= 400 &&
      response.status !== 503 &&
      response.status !== 401
    ) {
      throwServerError(response, {}, response.statusText);
    }

    if (response.status === 503) {
      const result = await response.text();
      throwServerError(response, {}, result);
    }

    return response;
  });
};

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URI,
  fetch: customFetch,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("completion_token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      accept: "application/json",
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

var refreshing = false;
const recoveryLink = new RetryLink({
  delay: {
    initial: 1000,
  },
  attempts: {
    max: Infinity,
    retryIf: async (error, operation) => {
      if (error.statusCode === 401) {
        const refreshToken = localStorage.getItem("refresh_token");

        if (!refreshToken) {
          return false;
        }

        if (refreshing) {
          return true;
        }

        refreshing = true;

        try {
          const refreshResponse = await refreshAccessToken(refreshToken);

          localStorage.removeItem("completion_token");
          localStorage.removeItem("refresh_token");

          localStorage.setItem("completion_token", refreshResponse.accessToken);
          localStorage.setItem("refresh_token", refreshResponse.refreshToken);
        } catch (error) {
          return false;
        } finally {
          refreshing = false;
        }

        return true;
      }
    },
  },
});

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache({ addTypename: false }),
  link: from([recoveryLink, authLink, httpLink]),
});
