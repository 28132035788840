export const loanStatus = {
  waiting: "WAITING",
  acceptedByCustomer: "ACCEPTED_BY_CUSTOMER",
  accepted: "ACCEPTED",
  rejected: "REJECTED",
  declinedByCustomer: "DECLINED_BY_CUSTOMER",
  complete: "COMPLETE",
  error: "ERROR",
  loading: "LOADING",
  pendingManualHandling: "PENDING_MANUAL_HANDLING",
  documentsSigned: "DOCUMENTS_SIGNED",
  paidOut: "PAID_OUT",
  processingIntermediary: "PROCESSING_INTERMEDIARY",
};
