import React from "react";

import { Heading } from "../../misc/Typography/Heading";
import { Paragraph } from "../../misc/Typography/Paragraph";
import { SuccessIcon } from "../../SvgIcons/SuccessIcon/SuccessIcon";
import { StatusContentLayout } from "./StatusContentLayout";

export const DocumentsSignedStatusFragment = ({ fadeIn }) => {
  return (
    <StatusContentLayout fadeIn={fadeIn}>
      <SuccessIcon />

      <Heading>Avtal signerade</Heading>
      <Paragraph textAlignLeft>
        Dina låneavtal är signerade, och nu kontrolleras underskrivna
        handlingar. Om något saknas eller behöver kompletteras kommer vi att
        kontakta dig för mer information, i annat fall betalas lånet ut inom
        kort.
      </Paragraph>

      <Paragraph textAlignLeft>
        Utbetalning görs till ditt servicekonto här i Lån & Spar Bank, vanligen
        inom 24 timmar (bankdagar) efter att du skrivit under dina
        avtalshandlingar.
      </Paragraph>
    </StatusContentLayout>
  );
};
