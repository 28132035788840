import React from "react";
import { StatusContentLayout } from "./StatusContentLayout";
import { DeclineLoanOfferForm } from "../../DeclineLoanOfferForm/DeclineLoanOfferForm";

export const UserRejectedFragment = props => {
  const { fadeIn, setUserDeclined, onDeclineLoanOffer} = props;


  return (
    <StatusContentLayout fadeIn={fadeIn}>

        <DeclineLoanOfferForm
          heading='Är du säker på att du inte vill gå vidare med ditt låneerbjudande?'
          message='När du väljer att inte gå vidare med ditt låneerbjudande kommer ditt
        ärende att makuleras. Har du ångrat dig klickar du ”Nej, jag har
        ångrat mig” nedan.'
          submitButtonText='Ja, jag vill avböja'
          cancelButtonText='Nej, jag har ångrat mig'
          onCancel={() => setUserDeclined(false)}
          icon={false}
          onDeclineLoanOffer={onDeclineLoanOffer}
      />
      
    </StatusContentLayout>
  );
};
