import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, styled } from "@mui/material";
import React from "react";

const Root = styled("div")(({ theme, hidden, disabled }) => ({
  position: "fixed",
  left: 0,
  bottom: 0,
  width: "100%",
  display: hidden === "hidden" ? "none" : "flex",
  justifyContent: "center",
  padding: theme.spacing(2),
  zIndex: 1350,
  alignItems: "center",
  height: theme.spacing(12),
  backgroundColor: disabled
    ? theme.palette.neutralColor[10]
    : theme.palette.primaryColor[10],
  boxShadow: "0 0 20px 0 rgb(97 120 136 / 15%);",
}));

export const BackToReview = ({ visibility, disabled, handleOnClick }) => {
  return (
    <Root hidden={visibility === "hidden"} disabled={disabled}>
      <Button
        disabled={disabled}
        variant='contained'
        color='primary'
        id='backToReviewButton'
        endIcon={<FontAwesomeIcon icon={faChevronRight} />}
        onClick={handleOnClick}
      >
        Tillbaka till Granska uppgifter
      </Button>
    </Root>
  );
};
