export const advices = [
  {
    title: "Gör en budget, få koll på läget",
    paragraphs: [
      "En budget är a och o när du vill få kontroll över din ekonomi. I din budget ska du specificera alla inkomster och utgifter, rörliga såväl som fasta. Det hjälper dig att, svart på vitt, se var dina pengar tar vägen varje månad.",
      "En gyllene budgetregel: var både realistisk och ärlig mot dig själv när du gör dina beräkningar.",
    ],
  },
  {
    title: "Samla dina lån",
    paragraphs: [
      "Har du lån och krediter hos flera olika kreditgivare? Ett sätt att minska dina månatliga och löpande kostnader/utgifter, och därigenom förbättra din ekonomi, är att samla hela ditt kreditengagemang hos en bank istället. När du samlar dina lån på ett ställe har du ofta möjlighet att sänka dina lånekostnader, förbättra din kreditvärdighet och få bättre överblick över din ekonomi.",
    ],
  },

  {
    title: " Börja buffertspara",
    paragraphs: [
      "Att ha en buffert för oförutsedda och oplanerade utgifter är klokt. Det skapar en ekonomisk trygghet och vetskap om att du har möjlighet att hantera en plötslig utgift av den större arten. Ett aktivt buffertsparande är av den anledningen en viktig pusselbit i en hälsosam privatekonomi.",
      "Ta för vana att, varje månad, sätta in en fast summa på ett sparkonto (eller annat lämpligt konto för sparande). Kom ihåg att det inte är beloppets storlek som är det viktigaste. Även små medel kan göra stor skillnad på sikt. Allt sparande är bättre än inget.",
    ],
  },

  {
    title: "Se över din kreditvärdighet",
    paragraphs: [
      "När du exempelvis ska ta lån bedömer banken din betalningsförmåga genom att titta på din kreditvärdighet. Din kreditvärdighet är ett slags betyg på hur din privatekonomiska situation ser ut.",
      "För att ta kontroll över din kreditvärdighet är det en bra idé att göra en kreditupplysning på sig själv. Se upplysningen som en privatekonomisk hälsoundersökning som ger dig information om vad du bör prioritera för en så bra kreditvärdighet som möjligt. En förbättrad kreditvärdighet går hand i hand med en bättre privatekonomi.",
    ],
  },

  {
    title: " Anslut e-faktura och autogiro",
    paragraphs: [
      "Se till att alltid betala dina fakturor och räkningar i tid. Skapa en fast rutin för när du betalar dina räkningar, och anslut dig till e-faktura eller autogiro om det är möjligt.",
      "Fördelen med e-faktura är att fakturan skickas direkt till din internetbank. Allt samlas då på ett ställe, och du behöver inte heller oroa dig för att fakturan kommer bort i posten.",
      "Fördelen med autogiro är att du alltid kan vara säker på att fakturan betalas i tid, förutsatt att du har tillräckligt med pengar på kontot som autogirot är kopplat till.",
    ],
  },
];
