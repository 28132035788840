import { Container, Grid, styled } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  loginWithBankid,
  collectBankidToken,
  cancelBankidLogin,
  getQRCodeToken,
} from "../../auth-service/auth-service";
import { Header } from "../MainPage/Header";
import { ProcessingLoginScreen } from "./screens/ProcessingLoginScreen";
import { ContactUs } from "../ContactUs/ContactUs";
import { LoginErrorScreen } from "../ErrorScreen/LoginErrorScreen";
import { MaintenanceModeScreen } from "../MaintenanceModeScreen/MaintenanceModeScreen";
import { InitialLoginScreen } from "./screens/InitialLoginScreen";
import isMobileDevice from "../helpers/isMobileDevice";
import { useIntervalAsyncHook } from "../helpers/UseIntervalAsyncHook";

const Wrapper = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(20),
  marginBottom: theme.spacing(10),

  [theme.breakpoints.down("md")]: {
    paddingTop: theme.spacing(17),
  },
}));

const ContactUsBox = styled("div")(({ theme }) => ({
  margin: `${[theme.spacing(5), 0, 0, 0].join(" ")}`,

  [theme.breakpoints.up("md")]: {
    margin: `${[theme.spacing(5), theme.spacing(8), 0, theme.spacing(8)].join(
      " "
    )}`,
  },
}));

export const LoginPage = () => {
  const navigate = useNavigate();

  const initialBankidMessage =
    "Öppna BankID-appen i din mobil/surfplatta. Använder du BankID på fil ska du öppna säkerhetsprogrammet på din dator.";

  const [pending, setPending] = useState(false);

  const [orderRef, setOrderRef] = useState(null);

  const [bankidMessage, setBankidMessage] = useState(initialBankidMessage);

  const [error, setError] = useState(false);

  const [maintenanceMode, setMaintenanceMode] = useState({
    mode: false,
    msg: null,
  });

  const [sourceQRCode, setSourceQRCode] = useState(null);

  const [showBankIDMessage, setShowBankIDMessage] = useState(false);

  const BASE_SITE_URL = process.env.REACT_APP_BASE_URL;

  const mobileDevice = isMobileDevice();

  useEffect(() => {
    if (mobileDevice) {
      setBankidMessage("Starta BankID-appen");
    }
  }, [mobileDevice]);

  const handleBankidMessage = useCallback(message => {
    setBankidMessage(message);
  }, []);

  const pollBankid = async orderRef => {
    var response;

    if (!orderRef) {
      return;
    }

    try {
      response = await collectBankidToken(orderRef);
      if (response.status >= 400) {
        setPending(false);
        setError(true);

        var text = await response.text();

        if (response.status === 503) {
          return setMaintenanceMode({ mode: true, msg: text });
        }
        return;
      } else {
        const result = await response.json();
        handleBankidMessage(result?.message);

        if (
          result.status === "complete" &&
          result?.tokenData &&
          result?.tokenData.length !== 0
        ) {
          localStorage.setItem(
            "completion_token",
            result.tokenData.accessToken
          );
          localStorage.setItem("refresh_token", result.tokenData.refreshToken);
          setPending(false);
          navigate("/");
        } else if (
          result.status === "complete" &&
          result?.tokenData &&
          result.tokenData.length === 0
        ) {
          setError(true);
          setPending(false);
          console.error("[Error on server]: tokenData is empty.");

          return;
        }

        if (result.status === "failed") {
          setPending(false);
          if (result.hintCode === "startFailed") {
            startLogin();
          } else {
            setError(result?.message ?? true);
            setShowBankIDMessage(false);
          }
        }
      }
    } catch (error) {}
  };

  const pollQRCodeSource = async orderRef => {
    var tokens;

    if (!orderRef) {
      return;
    }

    try {
      const response = await getQRCodeToken(orderRef);

      if (!response.ok) {
        onLoginError(true);

        if (response.status === 503) {
          var text = await response.text();

          return setMaintenanceMode({ mode: true, msg: text });
        }

        return console.error(
          `Error: ${response.status} ${response.statusText}`
        );
      } else {
        tokens = await response.json();

        const urlQRCode = tokens?.autostartData?.autoStartQr ?? null;

        if (!urlQRCode && tokens?.status !== "failed") {
          onLoginError(true);
        }

        if (tokens?.status === "pending") {
          setSourceQRCode(urlQRCode);
        }

        if (tokens?.status === "failed") {
          onLoginError(tokens?.message);
          setPending(false);
          setShowBankIDMessage(false);
        }
      }
    } catch (error) {}
  };

  useIntervalAsyncHook(
    async () => {
      if (orderRef === null) {
        return;
      }
      await pollBankid(orderRef);
    },
    pending ? 1000 : null
  );

  useIntervalAsyncHook(
    async () => {
      if (orderRef === null) {
        return;
      }
      await pollQRCodeSource(orderRef);
    },
    pending ? 1000 : null
  );

  const startLogin = useCallback(async (loginMethod = "qrCode") => {
    var tokens = {};
    const data = {
      ssn: null,
    };
    setPending(true);

    try {
      const response = await loginWithBankid(data);

      if (!response.ok) {
        onLoginError(true);

        if (response.status === 503) {
          var text = await response.text();

          return setMaintenanceMode({ mode: true, msg: text });
        }

        return console.error(
          `Error: ${response.status} ${response.statusText}`
        );
      } else {
        tokens = await response.json();

        const redirectUrlDesktop =
          tokens?.autostartData?.autoStartDesktop ?? null;

        const urlQRCode = tokens?.autostartData?.autoStartQr ?? null;

        const redirectUrlMobile =
          tokens?.autostartData?.autoStartMobile ?? null;

        if (
          !redirectUrlDesktop &&
          !urlQRCode &&
          !redirectUrlMobile &&
          tokens?.status !== "failed" &&
          tokens.orderRef === null
        ) {
          onLoginError(true);
        }

        if (tokens?.status === "pending") {
          if (loginMethod === "desktop") {
            window.location.href = redirectUrlDesktop;
            setShowBankIDMessage(true);
          }
          if (loginMethod === "mobile") {
            window.location.href = redirectUrlMobile;
            setShowBankIDMessage(true);
          }
          if (loginMethod === "qrCode") {
            setSourceQRCode(urlQRCode);
            setShowBankIDMessage(false);
          }

          setOrderRef(tokens.orderRef);
        }

        if (tokens?.status === "failed") {
          onLoginError(tokens?.message);
          setShowBankIDMessage(false);
          setPending(false);
        }
      }
    } catch (error) {}
  }, []);

  const handleCancelLogin = async orderRef => {
    try {
      const response = await cancelBankidLogin(orderRef);

      if (!response.ok) {
        setError(true);
        return console.error(
          `Error: ${response.status} ${response.statusText}`
        );
      } else {
        setPending(false);
        setShowBankIDMessage(false);
        window.location.href = BASE_SITE_URL;
      }
    } catch (error) {}
  };

  const resetLogin = () => {
    setPending(false);
    setError(false);
    handleBankidMessage(initialBankidMessage);
  };

  const onLoginError = error => {
    setPending(false);
    setError(error);
  };

  if (maintenanceMode.mode) {
    return <MaintenanceModeScreen message={maintenanceMode.msg} />;
  }

  return (
    <React.Fragment>
      <Header userLoggedIn={false} />
      <Wrapper>
        {error && (
          <LoginErrorScreen error={error} action={() => resetLogin()} />
        )}

        {!error && (
          <>
            <Grid container justifyContent='center'>
              <Grid item lg={6} md={6} sm={7} xs={12}>
                {!showBankIDMessage && (
                  <InitialLoginScreen
                    startLogin={startLogin}
                    sourceQRCode={sourceQRCode}
                    pending={pending}
                    mobileDevice={mobileDevice}
                  />
                )}

                {pending && showBankIDMessage && (
                  <ProcessingLoginScreen
                    title='Inloggning startar'
                    message={bankidMessage}
                    handleCancelLogin={() => handleCancelLogin(orderRef)}
                  />
                )}

                <ContactUsBox>
                  <ContactUs />
                </ContactUsBox>
              </Grid>
            </Grid>
          </>
        )}
      </Wrapper>
    </React.Fragment>
  );
};
