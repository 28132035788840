import React, { forwardRef } from "react";
import { NumericFormat } from "react-number-format";
import { useState } from "react";
import {
  FormControl,
  Input,
  FormHelperText,
  FormLabel,
  styled,
} from "@mui/material";

import { HelpTooltip } from "../HelpTooltip/HelpTooltip";

const LabelDescription = styled(FormHelperText)(({ theme }) => ({
  position: "relative",
  top: 0,
  marginTop: "4px",
  marginBottom: "16px",
  fontStyle: "italic",
}));

const LabelIcon = styled("img")(({ theme }) => ({
  verticalAlign: "middle",
  marginRight: "0.5rem",
}));

const setFormat = name => {
  switch (name) {
    case "ssn":
      return "########-####";
    case "data.ocrNumber":
      return "##################";
    case "data.bankPlusGiro":
      return "########";
    case "data.cardNumber":
      return "####################";
    default:
      return "";
  }
};

const TextFormatCustom = forwardRef(function TextFormatCustom(props, ref) {
  const { onChange, name, format, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={values => {
        onChange({
          target: {
            name: name,
            value: values.value,
          },
        });
      }}
      format={setFormat(name)}
      valueIsNumericString={true}
      inputMode='numeric'
    />
  );
});

export const LsbFormatInput = props => {
  // state to hide and show placeholder on focus and blur input
  const [hidePlaceholder, setHidePlaceholder] = useState(false);

  const togglePlaceholder = () => {
    hidePlaceholder ? setHidePlaceholder(false) : setHidePlaceholder(true);
  };

  const handleInputChange = event => {
    if (props.onChange && typeof props.onChange === "function") {
      if (event.target.value) {
        props.onChange(event.target.value);
      }
    }
    return event.target.value;
  };

  return (
    <FormControl error={props.errors ? true : false} component={"div"}>
      {props.label && (
        <FormLabel
          htmlFor={props.id}
          color='secondary'
          className={props.helpText ? "MuiFormLabel-withHelp" : ""}
        >
          {props.icon && <LabelIcon src={props.icon} alt='img' />}

          {props.label}
          {props.helpText && <HelpTooltip text={props.helpText} />}
        </FormLabel>
      )}

      {props?.labelDescription && (
        <LabelDescription>{props.labelDescription}</LabelDescription>
      )}

      <Input
        id={props.inputId}
        name={props.name}
        placeholder={!hidePlaceholder ? props.placeholder : ""}
        onFocus={togglePlaceholder}
        onBlur={togglePlaceholder}
        onChange={handleInputChange}
        defaultValue={props.defaultValue}
        ref={props.inputRef}
        required={props.required}
        color='secondary'
        inputComponent={TextFormatCustom}
        disabled={props.disabled}
        fullWidth={props.fullWidth}
      />
      {props.errors && (
        <FormHelperText>
          {props.errors.message ? props.errors.message : "Vänligen fyll fältet"}
        </FormHelperText>
      )}
    </FormControl>
  );
};
