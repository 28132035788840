import { gql } from "@apollo/client";

export const GET_STATUS = gql`
  query completion {
    completion {
      data {
        status
        statusInterfaceText {
          label
          title
          description
        }
        hasLsbLoanApplication
        requiresLoanRepayment
      }
      errors
    }
  }
`;
