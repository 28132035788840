import { darken, Fade, styled } from "@mui/material";

import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOut } from "@fortawesome/pro-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";
import { logout } from "../../auth-service/auth-service";

const MenuList = styled("ul")(({ theme }) => ({
  position: "absolute",
  boxSizing: "border-box",
  padding: "0 20px",
  top: "67px",
  backgroundColor: "#F2F2F2",
  boxShadow: "4px 13px 20px 0 rgba(95,97,97,0.2)",
  width: "302px",
  margin: 0,
  right: "-5px",
  listStyle: "none",

  [theme.breakpoints.down("sm")]: {
    width: "calc(100% + 40px)",
    left: "-20px",
    top: "65px",
  },

  "@media screen (min-width: 1280px and max-width: 1366px)": {
    right: "-60px",
  },

  [theme.breakpoints.up(1490)]: {
    right: "calc(-151px + 24px)",
  },
}));

const MenuListItem = styled("li")(({ theme }) => ({
  display: "inline-block",
  width: "100%",
  borderBottom: "1px solid #AEAEAE",

  "&:last-child": { borderBottom: 0 },

  "& a": {
    textDecoration: "none",
  },
}));

const MenuListItemButton = styled("button")(({ theme }) => ({
  appearance: "none",
  border: 0,
  textAlign: "left",
  display: "inline-block",
  width: "100%",
  padding: "24px 20px",
  cursor: "pointer",
  backgroundColor: "transparent",

  "&:disabled": {
    cursor: "default",
    opacity: ".7",
  },

  "& a": {
    textDecoration: "none",
  },
}));

const MenuListItemIcon = styled("span")(({ theme, red }) => ({
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  width: "32px",
  height: "32px",
  borderRadius: "32px",
  backgroundColor: red ? theme.palette.red : theme.palette.neutral.main,
  color: "#fff",

  "&:hover": {
    backgroundColor: red
      ? darken(theme.palette.red, 0.1)
      : darken(theme.palette.neutral.main, 0.1),
  },
}));

const MenuListItemLabel = styled("span")(({ theme }) => ({
  whiteSpace: "nowrap",
  fontSize: "1.125rem",
  fontWeight: 700,
  color: "#002427",
  marginLeft: "1rem",
}));

export const Menu = props => {
  return (
    <Fade in={props.isOpen}>
      <MenuList>
        <MenuListItem>
          <MenuListItemButton type='button' onClick={logout}>
            <MenuListItemIcon>
              <FontAwesomeIcon icon={faSignOut} />
            </MenuListItemIcon>
            <MenuListItemLabel>Logga ut</MenuListItemLabel>
          </MenuListItemButton>
        </MenuListItem>

        <MenuListItem>
          <MenuListItemButton
            type='button'
            onClick={() => {
              props.closeMenu();
              props.openModal("changeRequest");
            }}
          >
            <MenuListItemIcon>
              <FontAwesomeIcon icon={faCog} />
            </MenuListItemIcon>
            <MenuListItemLabel>Ändra uppgifter</MenuListItemLabel>
          </MenuListItemButton>
        </MenuListItem>

        <MenuListItem>
          <MenuListItemButton
            type='button'
            onClick={() => {
              props.closeMenu();
              props.openModal("help");
            }}
          >
            <MenuListItemIcon>
              <FontAwesomeIcon icon={faQuestion} />
            </MenuListItemIcon>
            <MenuListItemLabel>Hjälp</MenuListItemLabel>
          </MenuListItemButton>
        </MenuListItem>

        {!props.disableRequest && (
          <MenuListItem>
            <MenuListItemButton
              type='button'
              onClick={() => {
                props.closeMenu();
                props.openModal("declineOffer");
              }}
            >
              <MenuListItemIcon red='red'>
                <FontAwesomeIcon icon={faTimes} />
              </MenuListItemIcon>
              <MenuListItemLabel>Makulera ansökan</MenuListItemLabel>
            </MenuListItemButton>
          </MenuListItem>
        )}
      </MenuList>
    </Fade>
  );
};
