import { formatISO } from "date-fns";
import React from "react";

import { formatDate } from "../../../utils/formatDate";

export const FormattedDate = ({ date, format }) => {
  const formattedDate = formatDate(date, format);
  const isoDate = formatISO(date);

  return <time dateTime={isoDate}>{formattedDate}</time>;
};

FormattedDate.defaultProps = {
  format: "yyyy-MM-dd",
};
