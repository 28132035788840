import React from "react";
import { SvgIcon, styled } from "@mui/material";

const SvgRoot = styled(SvgIcon)(({ theme, mb }) => ({
  width: theme.spacing(9),
  height: theme.spacing(9),
  marginBottom: mb && theme.spacing(mb),

  [theme.breakpoints.down("sm")]: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    marginBottom: 0,
  },
}));

export function PhoneIcon(props) {
  return (
    <SvgRoot {...props} width='80' height='81' viewBox='0 0 80 81'>
      <circle cx='40' cy='40.4386' r='40' fill='#DE4D48' />
      <path
        d='M45.6034 44.2801L42.7313 47.8649C38.2841 45.2518 34.5773 41.5471 31.9617 37.1014L35.552 34.2292C36.417 33.5364 36.7065 32.3476 36.2568 31.3347L32.9843 23.9651C32.4996 22.8735 31.2962 22.293 30.1401 22.5932L23.9099 24.2094C22.707 24.5239 21.9292 25.6882 22.0992 26.9199C24.3454 42.9147 36.9167 55.486 52.9116 57.7322C54.1433 57.9025 55.3078 57.1247 55.6221 55.9216L57.2383 49.6919C57.5378 48.536 56.9578 47.3329 55.8669 46.8472L48.4986 43.5747C47.4854 43.1244 46.2959 43.4142 45.6034 44.2801Z'
        fill='white'
      />
    </SvgRoot>
  );
}
