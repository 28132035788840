import React, { forwardRef } from "react";
import { Container, Grid } from "@mui/material";

import {
  FooterContactUsCard,
  FooterContactUsContainer,
  FooterContactUsContent,
  FooterContactUsTitle,
  FooterContactUsWrapper,
  FooterText,
} from "../../styles/FooterComponents";
import { PhoneIcon } from "../SvgIcons/PhoneIcon/PhoneIcon";
import { EnvelopeIcon } from "../SvgIcons/EnvelopeIcon/EnvelopeIcon";
import { UnderlinedLink } from "../misc/UnderlinedLink/UnderlinedLink";

export const Footer = forwardRef((props, ref) => {
  return (
    <FooterContactUsWrapper ref={ref}>
      <Container>
        <FooterContactUsContainer container justifyContent='center'>
          <Grid item md={3} sm={4} xs={12}>
            <FooterContactUsTitle>Kontakta oss</FooterContactUsTitle>
            <p>Vi har öppet helgfria vardagar kl. 09.00-16.00.</p>
          </Grid>

          <FooterContactUsCard item md={6} sm={4} xs={12}>
            <PhoneIcon mb={2} />
            <FooterContactUsContent>
              <FooterContactUsTitle small='small'>
                Ring oss
              </FooterContactUsTitle>
              <p>
                <UnderlinedLink href='tel:+4640205600' title='Ring oss'>
                  040-205 600
                </UnderlinedLink>
              </p>
            </FooterContactUsContent>
          </FooterContactUsCard>

          <FooterContactUsCard item md={3} sm={4} xs={12}>
            <EnvelopeIcon mb={2} />
            <FooterContactUsContent>
              <FooterContactUsTitle small='small'>
                Skriv till oss
              </FooterContactUsTitle>

              <p>
                <UnderlinedLink
                  small='small'
                  href='mailto:info@lsb.se'
                  title='Skriv till oss'
                >
                  info@lsb.se
                </UnderlinedLink>
              </p>
            </FooterContactUsContent>
          </FooterContactUsCard>
        </FooterContactUsContainer>

        <FooterText container justifyContent='space-between'>
          <Grid item sm={10}>
            <p>
              Lån & Spar Sverige, bankfilial, org.nr 516406-0971. Lån & Spar
              Sverige, bankfilial, är registrerad i Bolagsverkets
              filialregister. Filial till Lån & Spar Bank A/S, Köpenhamn, CVR-nr
              13 53 85 30. Lån & Spar Bank A/S är registrerad i
              Erhvervsstyrelsen och står under tillsyn av danska Finanstilsynet.
              Lån & Spar Bank Box 4114 203 12 Malmö
            </p>
          </Grid>
        </FooterText>
      </Container>
    </FooterContactUsWrapper>
  );
});
