import React, { useCallback, useEffect, useState } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import "./scss/main.scss";
import { StyledEngineProvider } from "@mui/styled-engine";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material";
import LsbTheme from "./components/LsbTheme";

import { MainPageScreen } from "./components/MainPage/MainPageScreen";
import { LoginPage } from "./components/LoginPage/LoginPage";

import { AuthenticatedRoute } from "./components/route/AuthenticatedRoute";
import { MaintenanceModeScreen } from "./components/MaintenanceModeScreen/MaintenanceModeScreen";

function App() {
  const [maintenanceMode, setMaintenanceMode] = useState({
    mode: false,
    msg: null,
  });
  const [loading, setLoading] = useState(true);

  const pingServer = useCallback(async () => {
    const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const response = await fetch(
      `${REACT_APP_API_BASE_URL}/user/login_status?_format=json`,
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response;
  }, []);

  useEffect(() => {
    async function ping() {
      try {
        const response = await pingServer();

        var text = await response.text();

        if (response.status === 503) {
          return setMaintenanceMode({ mode: true, msg: text });
        }
        setLoading(false);
      } catch (error) {
        setMaintenanceMode({ mode: true, msg: null });
      } finally {
        setLoading(false);
      }
    }

    try {
      ping();
    } catch (error) {}
  }, [pingServer]);

  if (loading) {
    return null;
  }

  return (
    <ThemeProvider theme={LsbTheme}>
      <StyledEngineProvider injectFirst>
        <CssBaseline />

        {maintenanceMode.mode ? (
          <MaintenanceModeScreen message={maintenanceMode.msg} />
        ) : (
          <BrowserRouter>
            <Routes>
              <Route path='login' element={<LoginPage />} />

              <Route
                path='*'
                element={
                  <AuthenticatedRoute maintMode={maintenanceMode.mode}>
                    <MainPageScreen />
                  </AuthenticatedRoute>
                }
              />
            </Routes>
          </BrowserRouter>
        )}
      </StyledEngineProvider>
    </ThemeProvider>
  );
}

export default App;
