import { gql } from "@apollo/client";

export const GET_COUNTRIES = gql`
  query interfaceText {
    interfaceText {
      completion {
        otherTaxDomicileCountryList {
          name
          countrycode
          icon
        }

        citizenshipCountryList {
          countrycode
          icon
          name
        }
      }
    }
  }
`;
