import { Box, Grid } from "@mui/material";
import React from "react";

import systemErrorImage from "../../assets/images/system-error.png";
import { PrimaryButton } from "../CTAButtons/CTAButtons";
import { PaperLayout } from "../Layout/PaperLayout";

import { Heading } from "../misc/Typography/Heading";
import { StatusFragmentIcon } from "../StatusPage/components/StatusFragmentIcon";
import { StatusContentLayout } from "../StatusPage/fragments/StatusContentLayout";
import { ErrorText } from "./ErrorScreen";

export const LoginErrorScreen = ({ error, action }) => {
  return (
    <PaperLayout>
      <Grid container justifyContent='center'>
        <StatusContentLayout fadeIn>
          <StatusFragmentIcon icon={systemErrorImage} />
          <Box py={{ xs: 2, sm: 3 }}>
            <Heading>Oj, något blev fel!</Heading>
            <ErrorText>
              {error === true
                ? "Ett okänt fel har inträffat. Vänligen försök igen senare."
                : error}
            </ErrorText>
          </Box>
          <PrimaryButton
            variant='outlined'
            type='submit'
            className='cta-bold'
            onClick={action}
          >
            Logga in
          </PrimaryButton>
        </StatusContentLayout>
      </Grid>
    </PaperLayout>
  );
};
