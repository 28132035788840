import { styled } from "@mui/system";
import React from "react";

const NameParagraph = styled("p")(({ theme }) => ({
  padding: "0 0 1.25rem 0",
  margin: 0,
  fontSize: "1.25rem",
  fontWeight: 900,
  color: theme.palette.neutral.main,
}));

export const Name = ({ personalData }) => {
  return (
    <NameParagraph>
      {personalData.firstName}&nbsp;
      {personalData.lastName}
    </NameParagraph>
  );
};
