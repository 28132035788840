import InlineSVG from "svg-inline-react";
import React from "react";
import { styled } from "@mui/material";

const SvgStyled = styled(InlineSVG)(({ theme }) => ({
  display: "block",
  margin: "auto",
  width: "150px",
  height: "150px",
}));

const source = `<svg
viewBox='0 0 80 80'
version='1.1'
xmlns='http://www.w3.org/2000/svg'
>
<title>New-icon/check Copy@1x</title>
<g
  id='Bullet-icons'
  stroke='none'
  strokeWidth='1'
  fill='none'
  fillRule='evenodd'
>
  <g
    id='Delprojekt-8---kompletterings-ikoner'
    transform='translate(-247.000000, -562.000000)'
  >
    <g
      id='Icons8/Confimation-bullets/Exclamation-mark'
      transform='translate(247.000000, 562.000000)'
    >
      <rect
        id='Rectangle-Copy'
        fill='#47A660'
        x='0'
        y='0'
        width='80'
        height='80'
        rx='40'
      ></rect>
      <g
        id='Icon/16px/search'
        transform='translate(10.000000, 10.000000)'
      >
        <g
          id='icons8-checkmark-(1)'
          transform='translate(12.187500, 14.062500)'
          fill='#FCFCFC'
        >
          <path
            d='M34.6904467,0.0102713127 C34.6611498,0.0175960002 34.6318529,0.0285816252 34.602556,0.0395681877 C34.3571945,0.101823813 34.1447926,0.25929475 34.0166185,0.479021313 L14.8564623,30.1860526 L1.67286854,18.2915213 C1.44215542,17.9985526 1.05763417,17.8703785 0.695085103,17.9655938 C0.332536978,18.0608082 0.0652022908,18.3647635 0.0102713533,18.7346363 C-0.0446605217,19.1045101 0.123796041,19.470721 0.442399791,19.6684744 L14.4170092,32.3247244 C14.6220873,32.5041676 14.8967457,32.5847344 15.1677414,32.544451 C15.4387379,32.5041676 15.6767748,32.3503594 15.8232592,32.1196463 L35.5986498,1.47511506 C35.8147145,1.16749788 35.825701,0.7610035 35.6279467,0.442400688 C35.4301932,0.123796938 35.0603195,-0.0446605623 34.6904467,0.0102713127 Z'
            id='Path'
          ></path>
        </g>
      </g>
    </g>
  </g>
</g>
</svg>`;

export function SuccessIcon(props) {
  return (
    <div style={{ paddingBottom: "32px" }}>
      <SvgStyled src={source} {...props} />
    </div>
  );
}
