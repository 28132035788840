import { Box } from "@mui/material";
import React from "react";
import { PrimaryButton, RedButton } from "../../CTAButtons/CTAButtons";
import { Description } from "../../Description/Description";
import { FormattedDate } from "../../misc/FormattedDate/FormattedDate";
import { List } from "../../misc/List/List";
import { ListItem } from "../../misc/List/ListItem";
import { Heading } from "../../misc/Typography/Heading";
import { Paragraph } from "../../misc/Typography/Paragraph";
import { VictoryIcon } from "../../SvgIcons/VictoryIcon/VictoryIcon";
import { RepresentativeExample } from "../components/RepresentativeExample";
import { TotalAmount } from "../components/TotalAmount";
import { StatusContentLayout } from "./StatusContentLayout";

export const ApprovedStatusFragment = props => {
  const { fadeIn, setUserDeclined, loanOffer, onAcceptLoanOffer } = props;

  const {
    monthlyCost,
    totalMonthlyCostIncludingPpi,
    applicationExpiryDate,
    ppi,
    requiredResolvedAmount,
    requiredResolvedInternalAmount,
    repexHelptext,
    repexText,
    repexTitle,
    moreInfoAboutRequiredResolvedAmountText,
    moreInfoAboutRequiredResolvedAmountTitle,
    ...offerDetails
  } = loanOffer;

  const resolvedAmountDetails = {
    requiredResolvedAmount: loanOffer?.requiredResolvedAmount ?? null,
    requiredResolvedInternalAmount:
      loanOffer?.requiredResolvedInternalAmount ?? null,
  };

  const monthlyCostDetails = {
    monthlyCost: loanOffer?.monthlyCost,
    ppi: loanOffer?.ppi,
  };

  const representativeExampleData = {
    repexTitle: loanOffer?.repexTitle,
    repexText: loanOffer?.repexText,
    repexHelptext: loanOffer?.repexHelptext,
  };

  const convertedExpiryDate = new Date(loanOffer?.applicationExpiryDate * 1000);

  return (
    <StatusContentLayout fadeIn={fadeIn} textAlign='left'>
      <VictoryIcon />
      <Heading>Din ansökan är beviljad!</Heading>

      <Paragraph textAlignLeft>
        Vi har nu behandlat din låneansökan och kan erbjuda dig nedan
        presenterat lån. Ditt erbjudande är giltigt i 30 dagar (t.o.m.&nbsp;
        {<FormattedDate date={convertedExpiryDate} />}).
      </Paragraph>

      <Paragraph bold textAlignLeft>
        Det är viktigt att du går igenom uppgifterna innan du väljer att gå
        vidare med aktuellt låneerbjudande.
      </Paragraph>

      <List>
        <ListItem>
          Notera att erbjudandet inte är bindande och att avtal om lån ingås
          först när lånehandlingar undertecknats.
        </ListItem>

        <ListItem>
          Om du vill avstå, eller av annan anledning inte önskar gå vidare med
          ditt erbjudande, kan du välja att tacka nej.
        </ListItem>
      </List>

      <Description
        data={offerDetails}
        heading={{
          title: "Ditt låneerbjudande",
          ingressText: {
            linkTitle: "Mer information om erbjudandet",
            text: "<p>Erbjudandet baseras på hittills angivna förutsättningar, som du uppgivit i låneansökan. Skulle det uppkomma nya omständigheter hänförliga till dig eller syftet med krediten, förbehåller sig Banken rätten att ompröva erbjudandet.</p>",
          },
        }}
      />

      {(requiredResolvedAmount !== 0 ||
        requiredResolvedInternalAmount !== 0) && (
        <Description
          data={resolvedAmountDetails}
          heading={{
            title: "Ditt lösenkrav",
            ingressText: {
              linkTitle:
                loanOffer?.moreInfoAboutRequiredResolvedAmountTitle || null,
              text: loanOffer?.moreInfoAboutRequiredResolvedAmountText || null,
            },
          }}
        />
      )}

      <Description
        heading={{ title: "Din månadskostnad" }}
        data={monthlyCostDetails}
      />

      <TotalAmount
        title='Total månadskostnad'
        monthly
        totalAmount={loanOffer?.totalMonthlyCostIncludingPpi}
      />

      {loanOffer.repexText && (
        <RepresentativeExample example={representativeExampleData} />
      )}

      <Box pt={2}>
        <PrimaryButton variant='contained' onClick={onAcceptLoanOffer}>
          Ja, jag vill gå vidare med erbjudandet
        </PrimaryButton>
        <RedButton variant='contained' onClick={() => setUserDeclined(true)}>
          Nej, jag väljer att avstå
        </RedButton>
      </Box>
    </StatusContentLayout>
  );
};
