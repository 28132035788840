import { Box, Container } from "@mui/material";

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons";

import { RedButton, TextButton } from "../CTAButtons/CTAButtons";

import { Heading } from "../misc/Typography/Heading";
import { Paragraph } from "../misc/Typography/Paragraph";
import { FlagIcon } from "../SvgIcons/FlagIcon/FlagIcon";

export const DeclineLoanOfferForm = ({
  heading,
  message,
  icon,
  submitButtonText,
  cancelButtonText,
  onCancel,
  onDeclineLoanOffer,
}) => {
  return (
    <Container disableGutters>
      {icon && (
        <Box mb={4}>
          <FlagIcon />
        </Box>
      )}
      <Heading>{heading}</Heading>

      <Box pb={6}>
        <Paragraph textAlignLeft>{message}</Paragraph>
      </Box>

      <RedButton
        variant='contained'
        type='button'
        onClick={() => onDeclineLoanOffer()}
      >
        {submitButtonText}
      </RedButton>

      <TextButton
        variant='text'
        disableRipple
        startIcon={<FontAwesomeIcon icon={faChevronLeft} />}
        onClick={onCancel}
      >
        {cancelButtonText}
      </TextButton>
    </Container>
  );
};
