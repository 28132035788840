import { Grid, styled } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";

import React, { createRef, useEffect } from "react";
import lottie from "lottie-web";
import { PrimaryButton } from "../../CTAButtons/CTAButtons";

import animationData from "../../../assets/animation/heartAmp.json";
import { Heading } from "../../misc/Typography/Heading";
import { Paragraph } from "../../misc/Typography/Paragraph";
import { List } from "../../misc/List/List";
import { ListItem } from "../../misc/List/ListItem";
import { StatusContentLayout } from "../../StatusPage/fragments/StatusContentLayout";
import { PaperLayout } from "../../Layout/PaperLayout";

const AnimatedIcon = styled("div")(({ theme }) => ({
  width: "200px",
  margin: "auto",
}));

export const BeginCompletionScreen = ({ onNextMove }) => {
  const animationContainer = createRef();

  useEffect(() => {
    const animation = lottie.loadAnimation({
      container: animationContainer.current,
      animationData: animationData,
      renderer: "svg",
      loop: false,
      autoplay: true,
    });

    return () => animation.destroy();
  }, [animationContainer]);

  return (
    <PaperLayout>
      <Grid container justifyContent='center'>
        <StatusContentLayout fadeIn={true} paddingSm='paddingSm'>
          <AnimatedIcon ref={animationContainer}></AnimatedIcon>
          <Heading>Tack för att du valt oss!</Heading>
          <Paragraph textAlignLeft>
            Du har valt att gå vidare med ett låneerbjudande från oss. Innan
            lånet kan betalas ut behöver du först besvara några kompletterande
            frågor. Efter genomförd komplettering ska du, och eventuell
            medlåntagare, skriva under avtalshandlingar för aktuellt lån.
            Underskrift görs digitalt med BankID.
          </Paragraph>

          <List>
            <ListItem>
              Alla lånekunder ska lämna den kompletterande information som
              efterfrågas.
            </ListItem>

            <ListItem>
              Vid två låntagare är det huvudlåntagaren som lämnar uppgifter för
              er båda.
            </ListItem>
          </List>

          <PrimaryButton
            variant='contained'
            endIcon={<FontAwesomeIcon icon={faChevronRight} />}
            onClick={() => {
              onNextMove();
            }}
          >
            Påbörja kompletteringen
          </PrimaryButton>
        </StatusContentLayout>
      </Grid>
    </PaperLayout>
  );
};
