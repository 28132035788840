import { Grid, useMediaQuery, useTheme, styled } from "@mui/material";
import React from "react";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { QuickHelp } from "../QuickHelp/QuickHelp";
import { ModalLayout } from "./ModalLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ContactUs } from "../ContactUs/ContactUs";
import { ModalDialog } from "../../styles/ModalComponents";

const HelpModalContent = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "stretch",
}));

const HelpModalHeader = styled("div")(({ theme }) => ({
  padding: "32px 32px 0 32px",
  display: "flex",
  justifyContent: "space-between",
}));

const HelpModalTitle = styled("h3")(({ theme }) => ({
  fontWeight: 900,
  fontSize: "32px",
  margin: 0,

  [theme.breakpoints.down("sm")]: { fontSize: "24px" },
}));

const HelpModalCloseButton = styled("div")(({ theme }) => ({
  textTransform: "uppercase",
  fontSize: "14px",
  lineHeight: "17px",
  fontWeight: 500,
  color: "#206152",
  cursor: "pointer",
  textAlign: "center",

  [theme.breakpoints.down("sm")]: {
    width: "20px",
    height: "20px",
    textAlign: "center",
  },

  "& .svg-inline--fa": {
    fontSize: "13px",
    marginLeft: "7.5px",
    color: "#13614F",

    [theme.breakpoints.up("lg")]: {
      verticalAlign: "middle",
    },

    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      marginLeft: 0,
    },
  },
}));

const HelpModalBody = styled("div")(({ theme }) => ({
  padding: "28px 32px",
  flex: 1,
}));

export const HelpModal = props => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <ModalDialog
      open={props.openModal}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      closeAfterTransition
      disableAutoFocus={true}
      PaperComponent={ModalLayout}
      hideBackdrop={isSmallScreen}
      fullScreen={isSmallScreen}
      onBackdropClick={() => props.closeModal("help")}
    >
      <HelpModalContent>
        <HelpModalHeader>
          <HelpModalTitle>Vanliga frågor &amp; svar</HelpModalTitle>
          <HelpModalCloseButton
            role='button'
            onClick={() => props.closeModal("help")}
          >
            {!isSmallScreen && <span>Stäng</span>}
            <FontAwesomeIcon icon={faTimes} />
          </HelpModalCloseButton>
        </HelpModalHeader>

        <HelpModalBody>
          <QuickHelp />
        </HelpModalBody>

        <Grid container justifyContent='center'>
          <Grid item sm={12}>
            <ContactUs alignCenter />
          </Grid>
        </Grid>
      </HelpModalContent>
    </ModalDialog>
  );
};
