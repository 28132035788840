import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { styled } from "@mui/material";

const EconomicAdvicesRoot = styled("div")(({ theme, expanded }) => ({
  position: "relative",
  width: "100%",
  backgroundColor: "#fff",
  transition: `.2s all ${theme.transitions.easing.easeOut}`,
  transform: expanded ? "translateY(0px)" : "translateY(calc(100% - 58px))",
  height: expanded ? "auto" : "58px",
  overflow: expanded ? "visible" : "hidden",
  zIndex: 1100,

  "& p": {
    lineHeight: "1.5rem",
  },
}));

const EconomicAdvicesHeading = styled("div")(({ theme, expanded }) => ({
  backgroundColor: theme.palette.primaryColor[10],
  color: theme.palette.text.greenTitle,
  display: expanded ? "block" : "none",
  top: expanded && "142px",
  cursor: "pointer",
  position: expanded ? "sticky" : "relative",
  zIndex: 1200,

  [theme.breakpoints.up("sm")]: {
    display: "block",
    position: expanded && "relative",
    top: expanded && 0,
  },
}));

const EconomicAdvicesToggler = styled("div")(({ theme }) => ({
  margin: 0,
  textAlign: "center",
  fontWeight: 700,
  fontSize: "17px",
  lineHeight: "58px",
}));

const EconomicAdvicesIcon = styled(FontAwesomeIcon)(({ theme, expanded }) => ({
  fontSize: "13px",
  marginTop: "2px",
  marginLeft: "1rem",
  visibility: expanded && "hidden",
}));

const EconomicAdvicesCloseButton = styled("div")(({ theme, expanded }) => ({
  position: "absolute",
  right: "20px",
  textTransform: "uppercase",
  top: "20px",
  transition: ".1s opacity linear",
  transitionDelay: ".1s",
  opacity: expanded ? 1 : 0,
  fontSize: "13px",

  "& span": {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  [theme.breakpoints.up("md")]: {
    right: "10%",
  },
}));

const EconomicAdvicesContent = styled("div")(({ theme, expanded }) => ({
  animation: `disappearing .2s ${theme.transitions.easing.easeOut}`,
  transform: !expanded && "translateY(100%)",
  opacity: !expanded && 0,
  height: !expanded && 0,

  "& h1": {
    fontSize: "1.5rem",
    lineHeight: "33px",
    fontWeight: 900,
  },

  "& img": {
    display: expanded ? "block" : "none",
  },

  "@keyframes disappearing": {
    "0%": {
      opacity: 1,
      transform: "translateY(0)",
    },
    "100%": {
      opacity: 0,
      height: 0,
      transform: "translateY(calc(100% + 58px))",
      display: "none",
    },
  },
}));

export {
  EconomicAdvicesRoot,
  EconomicAdvicesHeading,
  EconomicAdvicesToggler,
  EconomicAdvicesIcon,
  EconomicAdvicesCloseButton,
  EconomicAdvicesContent,
};
