import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { CompletionForm } from "./CompletionForm";
import { Fade, styled } from "@mui/material";
import InlineSVG from "svg-inline-react";
import { StatusPageScreen } from "../StatusPage/StatusPageScreen";
import { useIntervalHook } from "../helpers/UseIntervalHook";
import * as Scroll from "react-scroll";

import { useState } from "react";
import { Footer } from "./Footer";
import { SessionExpiredModal } from "../SessionExpiredModal/SessionExpiredModal";
import { Header } from "./Header";
import { HelpModal } from "../modals/HelpModal";
import { ChangeRequestModal } from "../modals/ChangeRequestModal";
import { DeclineLoanOfferModal } from "../modals/DeclineLoanOfferModal";

import CompletionContextProvider from "../contexts/CompletionContext";
import { useMutation, useQuery } from "@apollo/client";
import { GET_APP_OFFER } from "../../graphql/GetAppOffer.query";
import { ErrorScreen } from "../ErrorScreen/ErrorScreen";
import { loanStatus } from "../../config/loanStatus";
import { Spinner } from "../../components/misc/Spinner/Spinner";
import { GET_COMPLETION_APP } from "../../graphql/GetCompletionApp.query";
import { ReminderScreen } from "./ReminderScreen/ReminderScreen";
import { BeginCompletionScreen } from "./BeginCompletionScreen/BeginCompletionScreen";
import { USER_DECLINE_LOAN_OFFER } from "../../graphql/UserDeclineLoanOffer.mutation";
import { USER_ACCEPT_LOAN_OFFER } from "../../graphql/UserAcceptLoanOffer.mutation";
import { MaintenanceModeScreen } from "../MaintenanceModeScreen/MaintenanceModeScreen";

const wave = `<svg viewBox="0 0 1556 120" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<defs>
    <linearGradient x1="13.6293051%" y1="49.9759494%" x2="81.775783%" y2="50.0419704%" id="linearGradient-1">
        <stop stop-color="#2E5B50" offset="0%"></stop>
        <stop stop-color="#002427" offset="100%"></stop>
    </linearGradient>
</defs>
<g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <path d="M1555.555,0 L1556,21.4167995 C1402.66667,19.0091852 1280.33333,30.5044818 1189,55.9026891 C1052,94 1002.40877,119.5 847.716726,119.5 C633.925972,119.5 618.209245,29.8794573 284.780855,21.4167995 C271.740737,21.0858317 101.888462,15.4395212 0.801,55.71538 L0.801,0 L1555.555,0 Z" id="wave-application" fill="url(#linearGradient-1)" fill-rule="nonzero"></path>
</g>
</svg>`;

const MainPageFormContainer = styled("section")(({ theme }) => ({
  '& [aria-hidden="true"]': {
    display: "initial",
    visibility: "visible",
  },
}));

const Wave = styled("div")(({ theme }) => ({
  margin: 0,
  width: "100%",
  overflow: "hidden",
  [theme.breakpoints.down("sm")]: {
    height: "72px",
  },

  "& figure": {
    margin: 0,
  },

  "& svg": {
    maxWidth: "100%",
    height: "auto",
    objectFit: "contain",
    [theme.breakpoints.down("sm")]: {
      transform: "scaleX(3) scaleY(3.4)",
    },
  },
}));

const MainPageRoot = styled("div")(({ theme, completed }) => ({
  display: completed ? "flex" : "block",
  flexDirection: completed && "column",
  height: completed && "100vh",
}));

const MainPageContent = styled("div")(({ theme }) => ({
  paddingTop: theme.spacing(10),

  [theme.breakpoints.up("sm")]: {
    paddingTop: theme.spacing(12),
  },
}));

export const MainPageScreen = () => {
  const [showForm, setShowForm] = useState(false);

  const [offerMissing, setOfferMissing] = useState(false);

  const [errorsOnLoading, setErrorsOnLoading] = useState(false);

  const [showStatusPage, setShowStatusPage] = useState(false);

  const [showReminder, setShowReminder] = useState(false);

  const [showLoading, setShowLoading] = useState(false);

  const [userAcceptedOffer, setUserAcceptedOffer] = useState(false); // customer accepted loan offer

  const [userDeclinedOffer, setUserDeclinedOffer] = useState(false); // customer doesn't accept loan offer after login

  const [userCancelApplication, setUserCancelApplication] = useState(false); // customer accepted offer but cancels application later

  const [showBeginCompletionScreen, setShowBeginCompletionScreen] =
    useState(false);

  const [showProcessingIntermediary, setShowProcessingIntermediary] =
    useState(false);

  const [status, setStatus] = useState(loanStatus.loading);

  const [loanApplicationOffer, setLoanApplicationOffer] = useState(null);

  const [statusInterfaceText, setStatusInterfaceText] = useState(null);

  const [maintenanceMode, setMaintenanceMode] = useState({
    mode: false,
    msg: null,
  });

  const meRef = useRef(null);

  const {
    data: applicationData,
    loading: applicationLoading,
    error: applicationError,
    refetch: applicationRefetch,
  } = useQuery(GET_COMPLETION_APP);

  const {
    data: offerData,
    loading: offerLoading,
    error: offerError,
    refetch: offerRefetch,
  } = useQuery(GET_APP_OFFER, {
    skip:
      status !== loanStatus.accepted ||
      status !== loanStatus.acceptedByCustomer,
  });

  const [userAcceptLoanOffer] = useMutation(USER_ACCEPT_LOAN_OFFER, {
    onError: error => {
      if (error.networkError.statusCode === 503) {
        return setMaintenanceMode({ mode: true, msg: error.message });
      }
      showErrorMessage();
    },
    onCompleted: res =>
      res?.declineLoanOffer?.accepted === false && showErrorMessage(),
  });

  const [userDeclineLoanOffer] = useMutation(USER_DECLINE_LOAN_OFFER, {
    onError: error => {
      if (error.networkError.statusCode === 503) {
        return setMaintenanceMode({ mode: true, msg: error.message });
      }
      showErrorMessage();
    },
    onCompleted: res =>
      res?.declineLoanOffer?.declined === false && showErrorMessage(),
  });

  const completionResponse = applicationData ?? null;

  const requiresLoanRepayment =
    applicationData?.completion?.data?.requiresLoanRepayment ?? false;

  const hasInternalLoanRepayment =
    applicationData?.completion?.data?.hasInternalLoanRepayment ?? false;

  const requiredResolvedInternalAmount =
    applicationData?.completion?.data?.requiredResolvedInternalAmount ?? null;

  const isCompletionTouched = completion => {
    if (completion === null || completion === undefined) {
      return false;
    }
    if (
      completion.data.loanRepayments.length > 0 ||
      completion.data.citizenship.countries.length > 0
    ) {
      return true;
    }
    return false;
  };

  const errorsLoanApplicationStatus = useMemo(
    () => applicationData?.completion?.errors ?? [],
    [applicationData?.completion]
  );

  const errorsLoanOffer = useMemo(
    () => offerData?.loanApplicationOffer?.errors ?? [],
    [offerData?.loanApplicationOffer]
  );

  const resourceNotFound =
    errorsLoanApplicationStatus?.find(
      error => error.code === "RESOURCE_NOT_FOUND"
    ) || errorsLoanOffer?.find(error => error.code === "RESOURCE_NOT_FOUND");

  const scroll = Scroll.animateScroll;

  const [openedModals, setOpenedModals] = useState({
    help: false,
    declineOffer: false,
    changeRequest: false,
  });

  const toggleModals = modals => {
    Object.keys(modals).forEach(key => {
      modals[key] = false;
    });
    return modals;
  };

  function onOpenModal(modal) {
    let closedModals = toggleModals(openedModals);
    closedModals[modal] = true;

    setOpenedModals({ ...closedModals });
  }

  function onCloseModal(modal) {
    setOpenedModals(prev => ({ ...prev, [modal]: false }));
  }

  const updateLoanOffer = useCallback(() => {
    offerRefetch()
      .then(result => {
        if (
          !result?.data?.loanApplicationOffer ||
          result.data?.loanApplicationOffer?.errors.length > 0
        ) {
          return setErrorsOnLoading(true);
        }
        setLoanApplicationOffer(result?.data?.loanApplicationOffer);
      })
      .catch(error => {
        setErrorsOnLoading(true);
        showErrorMessage();
      });
  }, [offerRefetch]);

  const showScreenByStatus = useCallback(
    (status, formIsTouched, userDeclinedOffer, userCancelApplication) => {
      switch (status) {
        case loanStatus.accepted:
        case loanStatus.waiting:
        case loanStatus.rejected:
        case loanStatus.complete:
        case loanStatus.documentsSigned:
        case loanStatus.paidOut:
          showStatusScreen();
          break;

        case loanStatus.processingIntermediary:
          displayProcessingIntermediaryScreen();
          break;

        case loanStatus.acceptedByCustomer:
          if (formIsTouched) {
            showFormScreen();
          } else {
            displayBeginCompletionScreen();
          }
          break;
        case loanStatus.pendingManualHandling:
        case loanStatus.error:
          setErrorsOnLoading(true);
          break;
        case loanStatus.declinedByCustomer:
          if (userDeclinedOffer || userCancelApplication) {
            showStatusScreen();
          } else {
            showOfferMissingScreen();
          }
          break;

        default:
          return loanStatus.error;
      }
    },
    []
  );

  const updateStatus = useCallback(
    async (skipUpdateInterfaceText = false) => {
      try {
        const res = await applicationRefetch();

        if (
          !res?.data?.completion ||
          res?.data?.completion?.errors.length > 0
        ) {
          return setErrorsOnLoading(true);
        }
        let updatedStatus = res?.data?.completion?.data?.status;

        const formIsTouched = isCompletionTouched(res?.data?.completion);

        setStatus(updatedStatus);

        if (!skipUpdateInterfaceText) {
          setStatusInterfaceText(
            res?.data?.completion?.data?.statusInterfaceText || null
          );
        }

        showScreenByStatus(
          updatedStatus,
          formIsTouched,
          userDeclinedOffer,
          userCancelApplication
        );
      } catch (error) {
        setErrorsOnLoading(true);
        showErrorMessage();
      }
    },
    [
      applicationRefetch,
      showScreenByStatus,
      userDeclinedOffer,
      userCancelApplication,
    ]
  );

  const onAcceptLoanOffer = useCallback(async () => {
    setUserAcceptedOffer(true);
    setShowLoading(true);

    try {
      const response = await userAcceptLoanOffer();

      if (response.data.acceptLoanOffer.accepted) {
        if (response.data.acceptLoanOffer?.statusAcceptInterfaceText) {
          setStatusInterfaceText(
            response.data.acceptLoanOffer?.statusAcceptInterfaceText
          );
        }
        setStatus(loanStatus.waiting);
        updateStatus(true);
      } else {
        showErrorMessage();
      }
      setShowLoading(false);
    } catch (error) {
    } finally {
      setShowLoading(false);
    }
  }, [userAcceptLoanOffer, updateStatus]);

  // customer doesn't accept loan offer or cancel application after acceptance
  const onDeclineLoanOffer = useCallback(async () => {
    if (status === loanStatus.accepted) {
      setUserDeclinedOffer(true);
    } else {
      setUserCancelApplication(true);
    }

    setShowLoading(true);

    const input = {
      reason: "No reason",
    };

    try {
      const response = await userDeclineLoanOffer({ variables: input });

      if (response.data.declineLoanOffer.declined) {
        if (response.data.declineLoanOffer?.statusCancelInterfaceText) {
          setStatusInterfaceText(
            response.data.declineLoanOffer?.statusCancelInterfaceText
          );
        }
        setStatus(loanStatus.waiting);
        showStatusScreen();
        updateStatus(true);
      }
    } catch (error) {
    } finally {
      setShowLoading(false);
    }
  }, [userDeclineLoanOffer, updateStatus, status]);

  const showStatusScreen = () => {
    setShowStatusPage(true);
    setShowBeginCompletionScreen(false);
    setShowForm(false);
    setShowReminder(false);
  };

  const displayProcessingIntermediaryScreen = () => {
    setShowProcessingIntermediary(true);
    setShowBeginCompletionScreen(false);
    setShowForm(false);
    setShowReminder(false);
  };

  const displayBeginCompletionScreen = () => {
    setShowStatusPage(false);
    setShowBeginCompletionScreen(true);
    setShowForm(false);
    setShowReminder(false);
  };

  const showFormScreen = () => {
    setShowStatusPage(false);
    setShowBeginCompletionScreen(false);
    setShowForm(true);
    setShowReminder(false);
  };

  const showReminderScreen = () => {
    setShowStatusPage(false);
    setShowBeginCompletionScreen(false);
    setShowForm(false);
    setShowReminder(true);
  };

  const showOfferMissingScreen = () => {
    setErrorsOnLoading(true);
    setOfferMissing(true);
    setShowStatusPage(false);
    setShowForm(false);
  };

  const showErrorMessage = () => {
    setShowStatusPage(false);
    setShowBeginCompletionScreen(false);
    setShowForm(false);
    setErrorsOnLoading(true);
    setShowLoading(false);
  };

  const onBeginCompletion = () => {
    if (requiresLoanRepayment || hasInternalLoanRepayment) {
      showReminderScreen();
    } else {
      showFormScreen();
    }
    setShowBeginCompletionScreen(false);
  };

  useEffect(() => {
    if (!meRef.current && status === loanStatus.loading) {
      updateStatus();
      meRef.current = true;
    }
  }, [status, updateStatus]);

  useEffect(() => {
    if (status === loanStatus.accepted && loanApplicationOffer === null) {
      updateLoanOffer();
    }
  }, [status, updateLoanOffer, showStatusPage, loanApplicationOffer]);

  useEffect(() => {
    return () => {
      scroll.scrollToTop({
        smooth: true,
        duration: 100,
      });
    };
  });

  useEffect(() => {
    if (resourceNotFound) {
      showOfferMissingScreen();
    }
  }, [resourceNotFound]);

  useEffect(() => {
    if (applicationError || offerError) {
      setErrorsOnLoading(true);

      if (applicationError?.networkError?.statusCode === 503) {
        return setMaintenanceMode({
          mode: true,
          msg: applicationError.message,
        });
      }

      if (offerError?.networkError?.statusCode === 503) {
        return setMaintenanceMode({ mode: true, msg: offerError.message });
      }
    }
  }, [offerError, applicationError]);

  useIntervalHook(
    () => {
      updateStatus(true);
    },

    status === loanStatus.waiting && !errorsOnLoading ? 5000 : null
  );

  if (applicationLoading || offerLoading) {
    return <Spinner fullscreen />;
  }

  if (applicationData?.completion === null) {
    return <ErrorScreen />;
  }

  if (maintenanceMode.mode) {
    return <MaintenanceModeScreen />;
  }

  return (
    <CompletionContextProvider
      status={status}
      completionResponse={completionResponse}
      loading={applicationLoading}
    >
      <MainPageRoot completed={status === "completed" && "completed"}>
        {(!errorsOnLoading || showForm) && (
          <Header userLoggedIn={true} openModal={onOpenModal} status={status} />
        )}

        <MainPageContent>
          {(errorsOnLoading ||
            status === loanStatus.error ||
            applicationData?.completion.errors.length > 0) && (
            <ErrorScreen errorCode={offerMissing ? "offerMissing" : "error"} />
          )}

          {showLoading && <Spinner overlay />}

          {showStatusPage && (
            <StatusPageScreen
              loanApplicationOffer={loanApplicationOffer}
              status={status}
              statusInterfaceText={statusInterfaceText}
              onAcceptLoanOffer={onAcceptLoanOffer}
              onDeclineLoanOffer={onDeclineLoanOffer}
              userCancelApplication={userCancelApplication}
              userAcceptedOffer={userAcceptedOffer}
            />
          )}

          {showReminder && (
            <ReminderScreen
              onNextMove={showFormScreen}
              internalLoan={requiredResolvedInternalAmount}
              hasExternalLoan={requiresLoanRepayment}
            />
          )}

          {showBeginCompletionScreen && (
            <BeginCompletionScreen onNextMove={onBeginCompletion} />
          )}

          {showProcessingIntermediary && (
            <ErrorScreen errorCode='offerMissing' />
          )}

          {showForm && (
            <MainPageFormContainer>
              <Wave>
                <InlineSVG element='figure' src={wave} alt='wave' />
              </Wave>

              <CompletionForm />

              <Fade in={showForm} timeout={1200}>
                <Footer />
              </Fade>
            </MainPageFormContainer>
          )}
        </MainPageContent>
        <SessionExpiredModal />

        <HelpModal openModal={openedModals.help} closeModal={onCloseModal} />
        <DeclineLoanOfferModal
          openModal={openedModals.declineOffer}
          closeModal={onCloseModal}
          onDeclineLoanOffer={onDeclineLoanOffer}
        />
        <ChangeRequestModal
          openModal={openedModals.changeRequest}
          closeModal={onCloseModal}
        />
      </MainPageRoot>
    </CompletionContextProvider>
  );
};
