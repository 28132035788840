import { Grid, styled } from "@mui/material";

const FooterContactUsWrapper = styled("div")(({ theme }) => ({
  position: "relative",
  bottom: 0,
  zIndex: 1200,
  width: "100%",
  background: `url(${"/assets/wave-footer.svg"}) 0 120px no-repeat`,
  backgroundSize: "cover",
}));

const FooterContactUsContainer = styled(Grid)(({ theme }) => ({
  padding: "4.5rem 5rem",
  border: "1px solid #F2F2F2",
  boxShadow: "0 0 20px 0 rgba(97,120,136,0.15)",
  borderRadius: "8px",
  fontFamily: theme.typography.fontFamily,
  color: "#393939",
  fontSize: "1.125rem",
  backgroundColor: "#fff",
  alignItems: "center",

  [theme.breakpoints.down("md")]: {
    padding: "3rem 2rem",
  },

  [theme.breakpoints.down("sm")]: {
    padding: "2rem 1rem",
  },

  "& p": {
    textAlign: "center",
    margin: 0,

    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
      fontSize: "1.125rem",
    },
  },
}));

const FooterContactUsTitle = styled("h3")(({ theme, small }) => ({
  fontSize: small ? "1.5rem" : "2rem",
  lineHeight: 1.0625,
  fontWeight: 900,
  color: theme.palette.neutral.main,
  marginBottom: small ? "0.25rem" : "0.875rem",
  marginTop: 0,
  textAlign: "center",
  letterSpacing: "0.3px",

  [theme.breakpoints.down("sm")]: {
    fontSize: small ? "1rem" : "1.625rem",
    textAlign: "left",
    marginBottom: small ? 0 : "0.5rem",
  },
}));

const FooterContactUsCard = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",

  [theme.breakpoints.down("sm")]: {
    marginTop: theme.spacing(4),
    flexDirection: "row",
    justifyContent: "flex-start",
    gap: theme.spacing(2),
  },
}));

const FooterContactUsContent = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",

  [theme.breakpoints.down("sm")]: {
    alignItems: "flex-start",
  },
}));

const FooterText = styled(Grid)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(12),

  "& > div": {
    display: "flex",
    alignItems: "center",
  },
  "& p": {
    fontSize: "14px",
    lineHeight: "24px",
    color: "#24473F",
  },
}));

export {
  FooterContactUsContainer,
  FooterContactUsWrapper,
  FooterContactUsTitle,
  FooterContactUsContent,
  FooterContactUsCard,
  FooterText,
};
