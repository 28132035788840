import * as yup from "yup";

export const moreAboutYouValidationSchema = yup.object().shape({
  pepSelf: yup.string().required(),
  pepContact: yup.string().required(),
  hasCoApplicant: yup.boolean(),
  coApplicant: yup.object({}).when("hasCoApplicant", {
    is: true,
    then: schema =>
      schema.shape({
        pepSelf: yup.string().required(),
        pepContact: yup.string().required(),
      }),
    otherwise: schema => schema.notRequired(),
  }),
});
