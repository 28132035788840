import InlineSVG from "svg-inline-react";
import React from "react";
import { SvgWrapper } from "../SvgWrapper";

const source = `<svg width="150px" height="150px" viewBox="0 0 80 80" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<title>New-icon/check Copy 4@1x</title>
<g id="Bullet-icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
    <g id="Delprojekt-8---kompletterings-ikoner" transform="translate(-376.000000, -560.000000)">
        <g id="Icons8/Confimation-bullets/Exclamation-mark" transform="translate(376.000000, 560.000000)">
            <rect id="Rectangle-Copy" fill="#E12727" x="0" y="0" width="80" height="80" rx="40"></rect>
            <g id="Icon/16px/search" transform="translate(10.000000, 10.000000)" fill="#FCFCFC">
                <polygon id="Path" points="13.5351563 12.1875 12.1875 13.5351563 28.3886719 29.7363281 12.1875 45.9375 13.5351563 47.2851562 29.7363281 31.0839844 45.9375 47.2851562 47.2851562 45.9375 31.0839844 29.7363281 47.2851562 13.5351563 45.9375 12.1875 29.7363281 28.3886719"></polygon>
            </g>
        </g>
    </g>
</g>
</svg>`;

export function FailIcon(props) {
  return (
    <SvgWrapper>
      <InlineSVG src={source} {...props} />
    </SvgWrapper>
  );
}
