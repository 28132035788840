import React, { useState, useEffect, useMemo } from "react";

import { Grid, LinearProgress } from "@mui/material";
import useSound from "use-sound";

import { SignApplication } from "../step_review/SignApplication";
import { Footer } from "../MainPage/Footer";
import { PaperLayout } from "../Layout/PaperLayout";
import { EconomicAdvicesScreen } from "../EconomicAdvices/EconomicAdvicesScreen";

import {
  AnimatedGrid,
  StatusPageResult,
  StatusPageRoot,
  StatusPageStatus,
  StatusPageStickyDesktop,
  StatusPageStickyMobile,
} from "../../styles/StatusPageComponents";
import { ApprovedStatusFragment } from "./fragments/ApprovedStatusFragment";
import { UserRejectedFragment } from "./fragments/UserRejectedFragment";
import { WaitingStatusFragment } from "./fragments/WaitingStatusFragment";
import { RejectedStatusFragment } from "./fragments/RejectedStatusFragment";

import * as Scroll from "react-scroll";

import celebrateAudio from "../../assets/audio/status-accepted.wav";
import rejectedAudio from "../../assets/audio/status-rejected.wav";
import { usePreviousValue } from "../helpers/UsePreviousValueHook";
import { loanStatus } from "../../config/loanStatus";
import { Spinner } from "../misc/Spinner/Spinner";
import { DocumentsSignedStatusFragment } from "./fragments/DocumentsSignedStatusFragment";
import { PaidOutStatusFragment } from "./fragments/PaidOutStatusFragment";
import { InfoSection } from "../InfoSection/InfoSection";
import { ProgressList } from "../QuickHelp/ProgressList";
import { ProcessingIntermediaryFragment } from "./fragments/ProcessingIntermediaryFragment";
import { UserDeclinedOfferResultFragment } from "./fragments/UserDeclinedOfferResultFragment";

const statusQuery = {
  WAITING: "Din ansökan behandlas",
  ACCEPTED_BY_CUSTOMER: "Beviljad",
  ACCEPTED: "Beviljad",
  REJECTED: "Ej Beviljad",
  COMPLETE: "Inskickad",
};

export const StatusPageScreen = ({
  loanApplicationOffer,
  status,
  formSubmitted = false,
  statusInterfaceText,
  onAcceptLoanOffer,
  onDeclineLoanOffer,
  userCancelApplication,
  userAcceptedOffer,
}) => {
  const [statusApp, setStatusApp] = useState(status);

  const [userDeclined, setUserDeclined] = useState(false);

  const [shownEconomicAdvices, setShownEconomicAdvices] = useState(false);

  const [playCelebrate] = useSound(celebrateAudio);
  const [playRejected] = useSound(rejectedAudio);

  const prevStatus = usePreviousValue(statusApp);

  const waitingStatusLabel = statusInterfaceText
    ? statusInterfaceText?.label
    : statusQuery.WAITING;

  function showStatusHeading(status, userDeclined, formSubmitted) {
    switch (status) {
      case loanStatus.accepted:
      case loanStatus.rejected:
      case loanStatus.declinedByCustomer:
        if (userDeclined) {
          return false;
        }
        return true;
      case loanStatus.documentsSigned:
      case loanStatus.paidOut:
      case loanStatus.complete:
      case loanStatus.processingIntermediary:
        return false;
      case loanStatus.waiting:
        if (formSubmitted) {
          return false;
        }
        return true;
      default:
        return true;
    }
  }

  function shouldShowEconomicAdvices(status, formSubmitted, hideAdvicesButton) {
    switch (status) {
      case loanStatus.waiting:
        if (hideAdvicesButton) {
          return false;
        }
        if (!formSubmitted || shownEconomicAdvices) {
          return true;
        }

        return false;
      default:
        return false;
    }
  }

  function showProgressMap(status, formSubmitted) {
    switch (status) {
      case loanStatus.complete:
        if (!formSubmitted) {
          return true;
        }
        return false;

      case loanStatus.documentsSigned:
        return true;

      default:
        return false;
    }
  }

  useEffect(() => {
    setStatusApp(status ?? "loading");
  }, [status]);

  var AudioContext =
    window.AudioContext || // Default
    window.webkitAudioContext; // Safari and old versions of Chrome

  const audioContext = useMemo(() => new AudioContext(), [AudioContext]);

  useEffect(() => {
    if (
      prevStatus === loanStatus.waiting &&
      statusApp === loanStatus.accepted
    ) {
      audioContext.resume().then(() => playCelebrate());
    }

    if (
      prevStatus === loanStatus.waiting &&
      statusApp === loanStatus.rejected
    ) {
      audioContext.resume().then(() => playRejected());
    }
  }, [statusApp, playCelebrate, playRejected, prevStatus, audioContext]);

  const scroll = Scroll.animateScroll;

  useEffect(() => {
    if (statusApp !== loanStatus.waiting && shownEconomicAdvices) {
      setShownEconomicAdvices(false);

      scroll.scrollToTop({
        smooth: true,
        duration: 300,
      });
    }
  }, [statusApp, shownEconomicAdvices, scroll]);

  useEffect(() => {
    if (userDeclined) {
      scroll.scrollToTop({
        smooth: true,
        duration: 300,
      });
    }
  }, [userDeclined, scroll]);

  const showAdvices = () => {
    setShownEconomicAdvices(true);
  };

  if (
    (status === loanStatus.accepted && !loanApplicationOffer) ||
    statusApp === loanStatus.loading
  ) {
    return <Spinner fullscreen />;
  }

  return (
    <React.Fragment>
      <StatusPageRoot>
        <StatusPageStickyMobile disableGutters>
          <Grid container justifyContent='center'>
            <Grid item xs={12} sm={9} md={8} lg={8} xl={8}>
              {showStatusHeading(
                statusApp,
                userDeclined || userCancelApplication,
                formSubmitted
              ) && (
                <StatusPageStatus
                  approved={statusApp === loanStatus.accepted && "approved"}
                  rejected={statusApp === loanStatus.rejected && "rejected"}
                >
                  Status:&nbsp;
                  <StatusPageResult
                    approved={statusApp === loanStatus.accepted && "approved"}
                    rejected={statusApp === loanStatus.rejected && "rejected"}
                  >
                    {statusApp === loanStatus.waiting
                      ? waitingStatusLabel
                      : statusQuery[statusApp]}
                  </StatusPageResult>
                </StatusPageStatus>
              )}

              {statusApp === loanStatus.waiting && <LinearProgress />}
            </Grid>
          </Grid>
        </StatusPageStickyMobile>

        <PaperLayout
          displayInsideForm={statusApp === loanStatus.complete && formSubmitted}
          center={userDeclined}
          shownEconomicAdvices={shownEconomicAdvices}
        >
          <StatusPageStickyDesktop>
            {showStatusHeading(
              statusApp,
              userDeclined || userCancelApplication,
              formSubmitted
            ) && (
              <StatusPageStatus
                approved={statusApp === loanStatus.accepted && "approved"}
                rejected={statusApp === loanStatus.rejected && "rejected"}
              >
                Status:&nbsp;
                <StatusPageResult
                  approved={statusApp === loanStatus.accepted && "approved"}
                  rejected={statusApp === loanStatus.rejected && "rejected"}
                >
                  {statusApp === loanStatus.waiting
                    ? waitingStatusLabel
                    : statusQuery[statusApp]}
                </StatusPageResult>
              </StatusPageStatus>
            )}

            {statusApp === loanStatus.waiting && <LinearProgress />}
          </StatusPageStickyDesktop>

          <AnimatedGrid
            container
            justifyContent='center'
            exit={shownEconomicAdvices ? "exit" : null}
          >
            {statusApp === loanStatus.waiting && (
              <WaitingStatusFragment
                fadeIn
                showAdvices={showAdvices}
                formSubmitted={formSubmitted}
                interfaceText={statusInterfaceText}
                hideAdvicesButton={
                  userCancelApplication || userAcceptedOffer || userDeclined
                }
              />
            )}

            {statusApp === loanStatus.accepted && !userDeclined && (
              <ApprovedStatusFragment
                fadeIn
                onAcceptLoanOffer={onAcceptLoanOffer}
                setUserDeclined={setUserDeclined}
                loanOffer={loanApplicationOffer?.data}
              />
            )}

            {userDeclined && statusApp === loanStatus.accepted && (
              <UserRejectedFragment
                fadeIn={userDeclined}
                setUserDeclined={setUserDeclined}
                onDeclineLoanOffer={onDeclineLoanOffer}
              />
            )}

            {userDeclined && statusApp === loanStatus.declinedByCustomer && (
              <UserDeclinedOfferResultFragment fadeIn />
            )}

            {userCancelApplication &&
              statusApp === loanStatus.declinedByCustomer && (
                <UserDeclinedOfferResultFragment
                  fadeIn
                  userCancelApplication={userCancelApplication}
                />
              )}

            {statusApp === loanStatus.rejected && (
              <RejectedStatusFragment fadeIn />
            )}

            {statusApp === loanStatus.complete && <SignApplication />}

            {statusApp === loanStatus.documentsSigned && (
              <DocumentsSignedStatusFragment fadeIn />
            )}

            {statusApp === loanStatus.paidOut && (
              <PaidOutStatusFragment fadeIn />
            )}

            {statusApp === loanStatus.processingIntermediary && (
              <ProcessingIntermediaryFragment fadeIn />
            )}
          </AnimatedGrid>
          {shouldShowEconomicAdvices(
            statusApp,
            formSubmitted,
            userCancelApplication || userAcceptedOffer || userDeclined
          ) && (
            <EconomicAdvicesScreen
              shownEconomicAdvices={shownEconomicAdvices}
              setShownEconomicAdvices={setShownEconomicAdvices}
            />
          )}
        </PaperLayout>

        {showProgressMap(status, formSubmitted) && (
          <InfoSection
            noPaddings
            neutral
            title='Var i låneprocessen befinner jag mig?'
            content={<ProgressList status={status} />}
          />
        )}

        {!formSubmitted && <Footer />}
      </StatusPageRoot>
    </React.Fragment>
  );
};
