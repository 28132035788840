import ccheckIcon from "../../../assets/images/ccheck.png";
import bulletIcon from "../../../assets/images/list-bullet.svg";
import { styled } from "@mui/material";

export const ListItem = styled("li")(({ theme, bullet }) => ({
  background: bullet
    ? `url(${bulletIcon}) left 8px no-repeat`
    : `url(${ccheckIcon}) left 7px no-repeat`,
  backgroundSize: "14px",
  lineHeight: "1.5rem",
  padding: "0 0 16px 32px",

  "&:last-of-type": {
    paddingBottom: 0,
  },
}));
