import { Controller, useFormContext } from "react-hook-form";

import React, { useContext, useEffect } from "react";

import { Grid } from "@mui/material";
import { LsbFormControl } from "../../form-components/LsbFormControl";
import { LsbSelect } from "../../form-components/LsbSelect";
import { createCountriesSelectOptions } from "../../helpers/createCountriesSelectOptions";
import { sanitizedString } from "../../helpers/SanitizedString";
import { RemoveTaxButton } from "../RemoveTaxButton";
import {
  CompletionContext,
  taxesDataSchema,
} from "../../contexts/CompletionContext";
import { FormBlock } from "../../../styles/ApplicationFormComponents";

export const TaxesFormControls = props => {
  const {
    index,
    inReview,
    defaultValues,
    setDisabledAddButton,
    onRemoveTaxField,
  } = props;

  const { interfaceText, countries } = useContext(CompletionContext);

  const { watch } = useFormContext();

  const selectedCountry = watch(
    `otherTaxResidences.${[index]}.${
      taxesDataSchema.otherTaxResidences.countrycode
    }`,
    null
  );

  const field = watch(`otherTaxResidences.${[index]}`, null);

  const taxId = watch(`otherTaxResidences.${[index]}.taxID`, "");

  const otherCountry = watch(`otherTaxResidences.${[index]}.otherCountry`, "");

  useEffect(() => {
    if (inReview) {
      return;
    }

    if (field && (selectedCountry === "" || taxId === "")) {
      return setDisabledAddButton(true);
    }

    if (field && selectedCountry === "OTHER" && otherCountry === "") {
      return setDisabledAddButton(true);
    }

    setDisabledAddButton(false);
  }, [
    field,
    selectedCountry,
    taxId,
    otherCountry,
    inReview,
    setDisabledAddButton,
  ]);

  const countriesSelectOptions = createCountriesSelectOptions(
    countries?.otherTaxDomicileCountryList
  );

  return (
    <FormBlock
      fullwidth={!inReview && "full-width"}
      review={inReview && "inReview"}
    >
      {!inReview && (
        <RemoveTaxButton
          onRemove={() => {
            setDisabledAddButton(false);
            onRemoveTaxField(index);
          }}
        />
      )}
      <Grid item xs={12} sm={10} md={8} lg={6}>
        <Controller
          name={`otherTaxResidences.${[index]}.id`}
          defaultValue={defaultValues?.id ?? null}
          render={() => {
            return null;
          }}
        />

        <LsbSelect
          name={`otherTaxResidences.${[index]}.${
            taxesDataSchema.otherTaxResidences.countrycode
          }`}
          label={sanitizedString(
            interfaceText?.otherTaxDomicileCountryListLabel
          )}
          options={countriesSelectOptions}
          defaultValue={defaultValues?.countrycode}
          index={index}
          disabled={inReview}
          helpText={
            !inReview &&
            sanitizedString(interfaceText?.otherTaxDomicileCountryListHelp)
          }
        />

        {selectedCountry === "OTHER" && (
          <LsbFormControl
            label={sanitizedString(
              interfaceText?.otherTaxDomicileOtherCountryLabel
            )}
            name={`otherTaxResidences.${[index]}.${
              taxesDataSchema.otherTaxResidences.otherCountry
            }`}
            placeholder={interfaceText?.otherTaxDomicileOtherCountryPlaceholder}
            defaultValue={defaultValues?.otherCountry}
            index={index}
            disabled={inReview}
            helpText={
              !inReview &&
              sanitizedString(interfaceText?.otherTaxDomicileOtherCountryHelp)
            }
          />
        )}

        <LsbFormControl
          type='common'
          label={sanitizedString(interfaceText?.otherTaxDomicileTaxIDLabel)}
          name={`otherTaxResidences.${[index]}.${
            taxesDataSchema.otherTaxResidences.taxID
          }`}
          placeholder={interfaceText?.otherTaxDomicileTaxIDLabelPlaceholder}
          defaultValue={defaultValues?.taxID}
          index={index}
          disabled={inReview}
          helpText={
            !inReview &&
            sanitizedString(interfaceText?.otherTaxDomicileTaxIDHelp)
          }
        />
      </Grid>
    </FormBlock>
  );
};
