import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { CoApplicantSection } from "../../../Layout/CoApplicantSection";
import { CoApplicantTaxesFormControls } from "./CoApplicantTaxesFormControls";
import { CoApplicantTaxesRadioButtonsGroup } from "./CoApplicantTaxesRadioButtonsGroup";
import { AddMoreButton } from "../../../../styles/ApplicationFormComponents";

export const CoApplicantTaxesFormComponent = ({
  addTaxField,
  fields,
  removeTaxFields,
  isCoAppOtherCountriesTaxesValid,
  onRemoveTaxField,
  personalData,
}) => {
  const { watch } = useFormContext();

  const [disabledAddButton, setDisabledAddButton] = useState(false);

  const watchFields = watch();

  const { coApplicant } = watchFields;

  useEffect(() => {
    if (fields.length === 0) {
      return;
    }
    let hasInvalidTax =
      fields.length !== 0 &&
      fields.some(item => item?.countrycode === "" || item?.taxID === "");
    if (hasInvalidTax) {
      setDisabledAddButton(true);
    }
  }, [fields]);

  useEffect(() => {
    isCoAppOtherCountriesTaxesValid(!disabledAddButton);
  }, [isCoAppOtherCountriesTaxesValid, disabledAddButton]);

  return (
    <CoApplicantSection
      personalData={personalData}
      title='Medsökandes skatterättsliga hemvist'
    >
      <CoApplicantTaxesRadioButtonsGroup
        addTaxField={addTaxField}
        removeTaxFields={removeTaxFields}
      />

      {coApplicant?.hasOtherTaxResidences === "other_countries" &&
        fields.map((field, index) => (
          <CoApplicantTaxesFormControls
            key={field.idn}
            defaultValues={field}
            index={index}
            setDisabledAddButton={setDisabledAddButton}
            onRemoveTaxField={onRemoveTaxField}
          />
        ))}

      {coApplicant?.hasOtherTaxResidences === "other_countries" && (
        <AddMoreButton
          color='primary'
          variant='outlined'
          onClick={addTaxField}
          disabled={disabledAddButton}
        >
          Lägg till fler skatterättsliga hemvister
        </AddMoreButton>
      )}
    </CoApplicantSection>
  );
};
