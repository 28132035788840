import { Divider, MenuItem } from "@mui/material";
import { styled } from "@mui/system";

const SelectDivider = styled(Divider)(({ theme }) => ({
  width: "calc(100% - 38px)",
  margin: "auto",
  backgroundColor: theme.palette.primary.main,
}));

const SelectMenuItem = styled(MenuItem)(({ theme }) => ({
  whiteSpace: "break-spaces",
  fontSize: 16,
}));

export const createSelectOptions = array => {
  let firstOption;

  if (!array || !array.length) {
    return;
  }

  let options = array.map(item => {
    if (item.name.includes("Nej,")) {
      firstOption = (
        <SelectMenuItem value={item.value} key={item.value}>
          {item.name}
        </SelectMenuItem>
      );
    }
    return (
      <SelectMenuItem value={item.value} key={item.value}>
        {item.name}
      </SelectMenuItem>
    );
  });

  options.pop(item => {
    item.name.includes("Nej,");
  });

  options.unshift(firstOption);
  options.unshift(
    <SelectMenuItem value='' disabled key='n00'>
      Välj
    </SelectMenuItem>
  );

  options.splice(2, 0, <SelectDivider key='n01' />);
  return options;
};
