import React, { useState } from "react";
import { styled } from "@mui/material";

import { sanitizedString } from "../helpers/SanitizedString";
import { IngressTextCard } from "../HelpText/IngressText/IngressTextCard";
import { IngressTextLink } from "../HelpText/IngressText/IngressTextLink";

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  textAlign: "left",
}));

const Heading = styled("h3")(({ theme }) => ({
  display: "inline-block",
  margin: 0,
  fontWeight: 900,
  fontSize: "1.125rem",
  lineHeight: "2rem",
  paddingRight: "0.785rem",
}));

export const DescriptionHeading = ({ title, ingressText }) => {
  const [openIngressText, setOpenIngressText] = useState(false);

  function handleOpenIngressText() {
    setOpenIngressText(true);
  }

  return (
    <Root>
      <Heading>{title}</Heading>
      {ingressText && (
        <IngressTextLink
          title={sanitizedString(ingressText.linkTitle)}
          handleOpenIngressText={handleOpenIngressText}
        />
      )}
      {openIngressText && (
        <IngressTextCard
          text={ingressText.text}
          openIngressText={openIngressText}
          closeIngressText={setOpenIngressText}
        />
      )}
    </Root>
  );
};
