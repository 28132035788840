import { Checkbox, FormControlLabel, styled } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";

const LabelWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
}));

const LabelIcon = styled("img")(({ theme }) => ({
  width: "32px",
  marginRight: ".5rem",
}));

const CheckboxLabel = ({ icon, label }) => {
  return (
    <LabelWrapper>
      {icon && <LabelIcon src={icon} alt='img' />}
      {label}
    </LabelWrapper>
  );
};

export const FormCheckbox = ({
  name,
  label,
  control,
  disabled,
  onChangeCheckbox,
  defaultValue,
  scopeId,
  icon,
}) => {
  const modificatedName = scopeId ? scopeId + "." + name : name;
  return (
    <FormControlLabel
      disabled={disabled}
      control={
        <Controller
          name={modificatedName}
          control={control}
          defaultValue={defaultValue}
          render={({ field: { value, onChange } }) => {
            return (
              <Checkbox
                name={modificatedName}
                disabled={disabled}
                checked={value}
                color='secondary'
                onChange={e => {
                  onChange(e.target.checked);
                  onChangeCheckbox(e.target.checked, name);
                }}
              />
            );
          }}
        />
      }
      label={<CheckboxLabel icon={icon} label={label} />}
    />
  );
};
