import { styled } from "@mui/material";
import React from "react";

const Root = styled("div")(({ theme, justifyend, layoutxs }) => ({
  display: "flex",
  justifyContent: justifyend ? "flex-end" : "space-between",
  gap: "10px",

  [theme.breakpoints.down("sm")]: {
    flexDirection: layoutxs ? "column" : "row",
    justifyContent:
      layoutxs && justifyend
        ? "space-between"
        : justifyend && !layoutxs && "space-between",
  },
}));

export const ButtonGroup = ({ children, layoutXS, justifyEnd }) => {
  return (
    <Root justifyEnd={justifyEnd} layoutxs={layoutXS}>
      {children}
    </Root>
  );
};
