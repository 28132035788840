import { Box } from "@mui/material";
import React from "react";

import { ContextLink } from "../misc/ContextLink/ContextLink";
import { Heading } from "../misc/Typography/Heading";
import { QuestionBlurbIcon } from "../SvgIcons/QuestionBlurbIcon/QuestionBlurbIcon";
import { ErrorText } from "./ErrorScreen";

export const OfferMissingScreen = () => {
  const BASE_SITE_URL = process.env.REACT_APP_BASE_URL;

  return (
    <div>
      <Box py={2}>
        <QuestionBlurbIcon />

        <Heading>Det finns inget pågående låneärende</Heading>
        <ErrorText textAlignLeft>
          Det verkar inte som att det finns någon aktiv låneansökan, eller något
          pågående låneärende, i ditt namn.
        </ErrorText>
        <ErrorText textAlignLeft>
          Om du vill logga in i Internetbanken gör du det via&nbsp;
          <ContextLink
            link={BASE_SITE_URL}
            text='www.lsb.se'
            title='LSB Lån & Spår Bank'
          />
          . Klicka på&nbsp;
          <span style={{ fontWeight: 700 }}>”Logga in”</span>
          &nbsp;och välj&nbsp;
          <span style={{ fontWeight: 700 }}>”Internetbanken”</span>.
        </ErrorText>
        <ErrorText textAlignLeft>
          Vid frågor är du välkommen att höra av dig till oss på
          telefon&nbsp;&nbsp;
          <ContextLink
            link='tel:+4640205600'
            text='040-205 600'
            title='Ring oss'
          />
          . Du når oss helgfria vardagar&nbsp;
          <span style={{ whiteSpace: "nowrap" }}>kl. 09.00-16.00</span>
        </ErrorText>
      </Box>
    </div>
  );
};
