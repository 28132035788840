import { Fade, Grid } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Scroll from "react-scroll";

import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback,
} from "react";
import { Controller, useForm } from "react-hook-form";

import { Section } from "../Layout/Section";
import { CompletionContext } from "../contexts/CompletionContext";
import { SanitizedHtml } from "../helpers/SanitizedHtml";
import { IngressTextLink } from "../HelpText/IngressText/IngressTextLink";
import { IngressTextCard } from "../HelpText/IngressText/IngressTextCard";
import { sanitizedString } from "../helpers/SanitizedString";
import { CoApplicantMoreAboutYou } from "./coApplicant/CoApplicantMoreAboutYou";
import { MoreAboutYouFormComponent } from "./components/MoreAboutYouFormComponent";

import { useUpdateCompletionData } from "../../graphql/useUpdateCompletionData";
import { moreAboutYouValidationSchema } from "./validationSchemaMoreAboutYouStep";
import { Spinner } from "../misc/Spinner/Spinner";
import { StepHeading } from "../misc/Typography/StepHeading";
import { StepPreamble } from "../misc/Typography/StepPreamble";
import { StepContent } from "../Layout/StepContent";
import { HelpTooltip } from "../HelpTooltip/HelpTooltip";
import { Name } from "../misc/Typography/Name";
import { StepSectionPaddingTop } from "../../styles/ApplicationFormComponents";

export const MoreAboutYouStep = props => {
  const {
    disableCheckStep,
    stepIndex,
    handleMove,
    checkCompleted,
    setDisableMove,
  } = props;
  const [fadeIn, setfadeIn] = useState(false);
  const [openIngressText, setOpenIngressText] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    interfaceText,
    handleStepsCompleted,
    stepsCompleted,
    completionData,
    completionInput,
    setUpdatedData,
  } = useContext(CompletionContext);

  const stepRef = useRef(false);

  const hasCoApplicant = completionData?.hasCoApplicant ?? false;

  const applicantPersonalData = completionData?.extraInformation ?? null;
  const coApplicantPersonalData =
    completionData?.coApplicant?.extraInformation ?? null;

  const scroll = Scroll.animateScroll;

  const formMethods = useForm({
    mode: "onChange",
    resolver: yupResolver(moreAboutYouValidationSchema),
  });

  const { handleSubmit, formState, control } = formMethods;

  const updateCompletionData = useUpdateCompletionData();

  const toggleCompleted = useCallback(
    completed => {
      handleStepsCompleted(stepIndex, completed);
    },
    [handleStepsCompleted, stepIndex]
  );

  const onSubmit = useCallback(
    data => {
      handleStepsCompleted(true);
    },
    [handleStepsCompleted]
  );

  const sendCompletionData = useCallback(
    async data => {
      try {
        scroll.scrollToTop({
          smooth: true,
          duration: 100,
        });

        setLoading(true);
        setDisableMove(true);

        const res = await updateCompletionData({
          variables: {
            input: data,
          },
        });

        if (res?.data?.updateCompletion?.errors.length === 0) {
          setUpdatedData(res?.data?.updateCompletion.data, handleMove);
          setLoading(false);
        }
      } catch (error) {
      } finally {
        setLoading(false);
        setDisableMove(false);
      }
    },
    [updateCompletionData, handleMove, setUpdatedData, scroll, setDisableMove]
  );

  useEffect(() => {
    if (!stepRef) return;
    setfadeIn(true);
    disableCheckStep();
    stepRef.current = true;

    return () => {
      stepRef.current = false;
    };
  }, [disableCheckStep]);

  useEffect(() => {
    if (!stepRef.current || formState.isDirty) {
      return;
    }
    if (completionData?.pepSelf !== null) {
      formMethods.setValue("pepSelf", completionData.pepSelf, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }

    if (completionData?.pepContact !== null) {
      formMethods.setValue(
        "pepContact",
        completionData.pepContact ? "yes" : "no",
        { shouldValidate: true, shouldDirty: true }
      );
    }

    if (completionData?.coApplicant?.pepSelf !== null) {
      formMethods.setValue(
        "coApplicant.pepSelf",
        completionData.coApplicant.pepSelf,
        {
          shouldValidate: true,
          shouldDirty: true,
        }
      );
    }

    if (completionData?.coApplicant?.pepContact !== null) {
      formMethods.setValue(
        "coApplicant.pepContact",
        completionData.coApplicant.pepContact ? "yes" : "no",
        { shouldValidate: true, shouldDirty: true }
      );
    }
  }, [completionData, formMethods, formState.isDirty]);

  useEffect(() => {
    toggleCompleted(formState.isDirty && formState.isValid);
  }, [formState.isDirty, formState.isValid, toggleCompleted]);

  useEffect(() => {
    if (checkCompleted && stepsCompleted[stepIndex]) {
      const moreAboutYouInput = formMethods.getValues();

      const input = {
        ...completionInput,
        pepSelf: moreAboutYouInput.pepSelf,
        pepContact: moreAboutYouInput.pepContact === "yes",
        coApplicant: hasCoApplicant
          ? {
              ...completionInput.coApplicant,
              pepSelf: moreAboutYouInput?.coApplicant?.pepSelf,
              pepContact: moreAboutYouInput?.coApplicant?.pepContact === "yes",
            }
          : null,
      };

      sendCompletionData(input);
    }
  }, [
    checkCompleted,
    formMethods,
    sendCompletionData,
    stepIndex,
    stepsCompleted,
    hasCoApplicant,
    completionInput,
  ]);

  function handleOpenIngressText() {
    setOpenIngressText(true);
  }

  return (
    <Fade in={fadeIn} timeout={{ appear: 100, enter: 1000, exit: 1500 }}>
      <StepContent>
        {loading && <Spinner overlay />}

        <Section>
          <Grid item xs={12}>
            <StepHeading
              title={<SanitizedHtml html={interfaceText?.moreAboutYouTitle} />}
              helpText={
                interfaceText?.moreAboutYouHelp && (
                  <HelpTooltip
                    text={sanitizedString(interfaceText?.moreAboutYouHelp)}
                  />
                )
              }
            />
          </Grid>
          <Grid item sm={8}>
            <StepPreamble>
              <SanitizedHtml html={interfaceText?.moreAboutYouDescription} />

              <IngressTextLink handleOpenIngressText={handleOpenIngressText} />
            </StepPreamble>
          </Grid>

          {openIngressText && (
            <Grid item sm={8}>
              <IngressTextCard
                text={interfaceText?.moreAboutYouIngress}
                openIngressText={openIngressText}
                closeIngressText={setOpenIngressText}
              />
            </Grid>
          )}
        </Section>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name='hasCoApplicant'
            defaultValue={hasCoApplicant}
            render={() => {
              return null;
            }}
          />

          <Section>
            <Grid item xs={12}>
              <StepSectionPaddingTop>
                {applicantPersonalData && (
                  <Grid item xs={12}>
                    <Name personalData={applicantPersonalData} />
                  </Grid>
                )}
                <MoreAboutYouFormComponent
                  {...props}
                  formMethods={formMethods}
                />
              </StepSectionPaddingTop>
            </Grid>
          </Section>

          {hasCoApplicant && (
            <CoApplicantMoreAboutYou
              {...props}
              formMethods={formMethods}
              personalData={coApplicantPersonalData}
            />
          )}
        </form>
      </StepContent>
    </Fade>
  );
};
