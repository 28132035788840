import React from "react";
import { SvgIcon, styled } from "@mui/material";

const SvgRoot = styled(SvgIcon)(({ theme }) => ({
  width: theme.spacing(10),
  height: theme.spacing(10),
  fontSize: 2,
}));

export function LoginIcon(props) {
  return (
    <SvgRoot {...props} viewBox='0 0 80 80' width='80' height='80'>
      <g
        id='La&#204;&#138;n &#38; Spar Bank _ Trygga &#38; fo&#204;&#136;rma&#204;&#138;nliga la&#204;&#138;n-1 1'
        clipPath='url(#clip0_1424_106)'
      >
        <g id='Bullet-icons'>
          <g id='Delprojekt-8---&#195;&#132;ndra-ikoner'>
            <g id='Icons8/Circle-PrimaryLight/komplettera'>
              <path
                id='Rectangle-Copy'
                d='M80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80C62.0914 80 80 62.0914 80 40Z'
                fill='#499D81'
              />
              <g id='Icon/16px/search'>
                <g id='icons8-signing-a-document'>
                  <path
                    id='Shape'
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M23.125 18.4375V61.5625H56.875V57.3279C56.2278 57.3279 55.7125 57.3279 55 57.3279V59.6875H25V20.3125H43.75V31.5625H55V34.375C55.6501 34.375 56.275 34.375 56.875 34.375V30.2405L45.072 18.4375H23.125ZM45.625 21.6345L53.678 29.6875H45.625V21.6345ZM30.625 42.8125V44.6875H38.8757C38.8757 43.9346 38.8757 43.3536 38.8757 42.8125H30.625ZM30.625 48.4375V50.3125H45.9103C45.9103 49.9347 45.9103 49.6461 45.9103 49.2725C45.9103 48.8989 45.9103 48.8104 45.9103 48.4375H30.625ZM30.625 37.1875V39.0625H45.0905C45.0905 38.7051 45.0905 38.4651 45.0905 38.0225C45.0905 37.6441 45.0905 37.5683 45.0905 37.1875H30.625ZM56.875 34.375H55V57.3279C55.4263 57.3279 55.6338 57.3279 56.0274 57.3279C56.3638 57.3279 56.5069 57.3279 56.875 57.3279V34.375Z'
                    fill='#FCFCFC'
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <defs>
        <clipPath id='clip0_1424_106'>
          <rect width='80' height='80' fill='white' />
        </clipPath>
      </defs>
    </SvgRoot>
  );
}
