import React, { useEffect } from "react";

import * as Scroll from "react-scroll";
import { PrimaryButton } from "../CTAButtons/CTAButtons";

import {
  SignAppHeading,
  SignAppIcon,
  SignAppRoot,
  SignAppText,
} from "../../styles/SignAppComponents";
import { Box, Grid } from "@mui/material";
import { SuccessIcon } from "../SvgIcons/SuccessIcon/SuccessIcon";

export const SignApplication = () => {
  useEffect(() => {
    const scroll = Scroll.animateScroll;
    scroll.scrollToTop({
      smooth: true,
      duration: 300,
    });
  });

  return (
    <SignAppRoot item container justifyContent='center' lg={8}>
      <SignAppIcon>
        <SuccessIcon />
      </SignAppIcon>

      <SignAppHeading>Nu kan du signera dina avtal</SignAppHeading>

      <Grid item sm={9}>
        <SignAppText textAlignLeft textLight>
          Dina avtalshandlingar har skapats och kan nu signeras. Du skriver
          under lånehandlingarna digitalt med ditt BankID. Om det finns en
          medlåntagare är det viktigt att ni båda skriver under med respektive
          låntagares BankID.
        </SignAppText>

        <Box pt={3}>
          <PrimaryButton
            variant='contained'
            href='https://internetbank.lsb.se/lobby/login'
          >
            Signera avtal
          </PrimaryButton>
        </Box>
      </Grid>
    </SignAppRoot>
  );
};
