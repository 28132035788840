import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

import { NumericFormat } from "react-number-format";
import { DescriptionHeading } from "./DescriptionHeading";
import { HelpTooltip } from "../HelpTooltip/HelpTooltip";
import { styled } from "@mui/material";

const dataSchema = {
  approvedAmount: {
    translation: "Beviljat lånebelopp",
    weight: 0,
  },
  repaymentTime: {
    translation: "Återbetalningstid",
    weight: 1,
  },
  interestRate: {
    translation: "Nominell ränta (rörlig)",
    weight: 2,
  },
  effectiveRate: {
    translation: "Effektiv ränta (rörlig)",
    weight: 3,
  },
  feesAmount: {
    translation: "Uppläggningsavgift",
    weight: 4,
  },
  repaymentType: {
    translation: "Lånetyp",
    weight: 5,
  },

  requiredResolvedAmount: {
    translation: "Lösenkrav hos andra banker",
    weight: 6,
  },
  requiredResolvedInternalAmount: {
    translation: "Lösenkrav hos Lån & Spar Bank",
    weight: 7,
  },

  monthlyCost: {
    translation: "Annuitetsbelopp",
    weight: 8,
  },
  ppi: {
    translation: "Låneskydd",
  },
  totalMonthlyCostIncludingPpi: {
    translation: "Total månadskostnad",
  },
};

const helpTexts = {
  repaymentTimeHelp:
    "Anger tidsperioden för återbetalning av lånet – från den dag lånet tecknades fram till den dag då lånet ska vara fullständigt återbetalat.",
  interestRateHelp:
    " Nominell ränta är den grundläggande räntan för lånet. Eventuella avgifter och kostnader som tillkommer inkluderas inte i denna räntesats.",
  effectiveRateHelp:
    "Effektiv ränta inkluderar alla eventuella avgifter och kostnader som tillkommer. Utgör den ränta du faktiskt betalar för lånet. Kan ses som ett slags kilopris på lån.",
  feesAmountHelp:
    "En administrativ avgift som tas ut i samband med att lånet betalas ut. Utgör en kostnad för den hantering som krävs innan utbetalning är genomförd.",
  repaymentTypeHelp: null,
  requiredResolvedAmountHelp:
    "Lösenkrav är ett villkor för att beviljat lån ska betalas ut och innebär att hela, eller delar av, dina lån i andra banker behöver lösas. Lånen du väljer att lösa måste uppgå till minst det belopp som framgår av lösenkravet. Eventuell medlåntagare och denne persons lån hos oss inkluderas också i aktuellt lösenkrav.",
  ppiHelp:
    "En frivillig gruppförsäkring som hjälper dig att betala dina lånekostnader om du blir sjukskriven, förlorar din inkomst på grund av uppsägning eller behöver vårda en nära anhörig. Täcker även den försäkrades del av lånebeloppet upp till ett belopp om 350 000 kr vid eventuellt dödsfall. Låntagare under 65 år ansluts automatiskt till försäkringen.", //Låneskydd Omkostnad
  monthlyCostHelp: null,
  requiredResolvedInternalAmountHelp:
    "Lösenkrav är ett villkor för att beviljat lån ska betalas ut och innebär att hela, eller delar av, det lånebelopp du blivit beviljad ska användas för att lösa lån som du har hos oss. Eventuell medlåntagare och denne persons lån hos oss inkluderas också i aktuellt lösenkrav.",
};

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  paddingBottom: theme.spacing(5),
  border: 0,
}));

const DescriptionTableContainer = styled(TableContainer)(({ theme }) => ({
  paddingTop: theme.spacing(1.125),
  overflowX: "unset",
}));

export const Description = ({ data, heading }) => {
  const sortData = data => {
    return data.sort((prev, next) => prev?.weight - next?.weight);
  };
  const NameTableCell = styled(TableCell)(({ theme }) => ({
    borderBottom: 0,

    "&.MuiTableCell-body": {
      fontSize: 15,
      lineHeight: 1.5,
      padding: "12px 8px",
      position: "relative",
    },
  }));

  const ValueTableCell = styled(TableCell)(({ theme }) => ({
    borderBottom: 0,

    "&.MuiTableCell-body": {
      fontSize: 15,
      lineHeight: 1.5,
      padding: "12px 16px",
      fontWeight: 700,
      width: "40%",
      borderLeft: "1px solid #B4B4B4",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.neutralColor[10],
    },
  }));

  const rows = Object.entries(data).map(item => {
    let obj = {
      name: item[0],
      value: item[1],
      weight: dataSchema[item[0]].weight,
    };

    return obj;
  });

  const renderValue = (name, value) => {
    switch (name) {
      case "approvedAmount":
      case "feesAmount":
      case "ppi":
      case "totalCost":
      case "monthlyCost":
      case "totalMonthlyCostIncludingPpi":
      case "requiredResolvedAmount":
      case "requiredResolvedInternalAmount":
        return (
          <NumericFormat
            value={value}
            displayType='text'
            suffix=' kr'
            thousandSeparator=' '
          />
        );

      case "repaymentTime":
        return <NumericFormat value={value} displayType='text' suffix=' år' />;

      case "interestRate":
      case "effectiveRate":
        return <NumericFormat value={value} displayType='text' suffix=' %' />;
      case "repaymentType":
        return value === "ANNUITY" ? "Annuitet" : "Fast ränta";
      default:
        return value;
    }
  };

  const getHelpText = (name, helpTexts) => {
    if (helpTexts === null) {
      return false;
    }

    for (let key in helpTexts) {
      if (key === name.concat("Help")) {
        return helpTexts[key];
      }
    }

    return false;
  };

  const sortedRows = sortData(rows);

  return (
    <Root>
      {heading && <DescriptionHeading {...heading} />}
      <DescriptionTableContainer>
        <Table aria-label={heading ? heading.title : "details"}>
          <TableBody>
            {sortedRows.map(row => {
              if (row.name === "repaymentType") {
                return null;
              }

              if (row.value === 0) {
                return null;
              }

              const formattedValue = renderValue(row.name, row.value);
              const helpText = getHelpText(row.name, helpTexts);

              return (
                <StyledTableRow key={row.name}>
                  <NameTableCell component='th' scope='row'>
                    {dataSchema[row.name].translation}

                    {helpText && <HelpTooltip text={helpText} />}
                  </NameTableCell>
                  <ValueTableCell align='left' width='35%'>
                    {formattedValue}
                  </ValueTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </DescriptionTableContainer>
    </Root>
  );
};
