import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { styled } from "@mui/material";
import React from "react";

const Root = styled("div")(({ theme, fullscreen, overlay }) => ({
  paddingTop: !fullscreen && theme.spacing(5),
  paddingBottom: !fullscreen && theme.spacing(8),
  textAlign: "center",

  position: overlay || fullscreen ? "absolute" : "relative",
  zIndex: fullscreen ? 3000 : 1280,
  width: overlay ? "100vw" : "100%",
  height: overlay ? "350px" : fullscreen ? "100vh" : "auto",
  display: overlay || fullscreen ? "flex" : "block",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: fullscreen && "#fff",
}));

export const Spinner = ({ fullscreen, overlay }) => {
  return (
    <Root
      fullscreen={fullscreen && "fullscreen"}
      overlay={overlay && "overlay"}
    >
      <FontAwesomeIcon icon={faSpinner} pulse size='8x' color='#6FAE9F' />
    </Root>
  );
};
