import { styled } from "@mui/material";
import React from "react";

const Root = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.primaryColor[20],
  padding: "1rem",
}));

const Paragraph = styled("p")(({ theme, textalign = "left" }) => ({
  padding: 0,
  margin: 0,
  fontSize: 14,
  letterSpacing: "0.26px",
  lineHeight: "1.125rem",
  color: theme.palette.neutralColor[90],
  textAlign: textalign,
}));

const Link = styled("a")(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: "underline",
}));

export const ContactUs = ({ alignCenter }) => {
  return (
    <Root>
      <Paragraph textalign={alignCenter}>
        Har du några frågor? Ring oss på{" "}
        <Link href='tel:+4640205600' title='Ring oss'>
          040-205 600
        </Link>{" "}
        Vi har öppet helgfria vardagar kl. 09.00-16.00.
      </Paragraph>
    </Root>
  );
};
