import React from "react";
import { Paragraph } from "../misc/Typography/Paragraph";
import { StepHeading } from "../misc/Typography/StepHeading";
import image from "../../assets/images/maintenance.png";
import logoImage from "../../assets/images/logo-text.png";
import { styled } from "@mui/system";

const Root = styled("div")({
  textAlign: "center",
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "1rem",
  paddingLeft: "1.5rem",
  paddingRight: "1.5rem",

  "& > p": {
    maxWidth: "75%",
  },
});

const Poster = styled("img")({
  width: "100%",
  maxWidth: "480px",
});

const Logo = styled("img")({
  width: "100%",
  maxWidth: "219px",
});

const defaultMessage =
  "Sidan du vill komma åt är just nu i underhållsläge och kommer vara tillgänglig snart igen!";
export const MaintenanceModeScreen = ({ message }) => {
  return (
    <Root>
      <StepHeading title='Håll ut! Vi bygger om.' />
      <Paragraph>{message || defaultMessage}</Paragraph>

      <Poster src={image} alt='maintenance' />
      <Logo src={logoImage} alt='Lån&Spar Bank' />
    </Root>
  );
};
