import { Box, styled } from "@mui/material";

import React from "react";

import { PrimaryButton } from "../../CTAButtons/CTAButtons";
import { SanitizedHtml } from "../../helpers/SanitizedHtml";
import { sanitizedString } from "../../helpers/SanitizedString";
import { Heading } from "../../misc/Typography/Heading";
import { StatusContentLayout } from "./StatusContentLayout";

const ScreenHeading = styled(Heading)(({ theme }) => ({
  fontSize: "2rem",
  lineHeight: "2.5rem",
  fontWeight: 900,
  margin: "0 0 1rem 0",

  [theme.breakpoints.down("md")]: {
    fontSize: "1.45rem",
  },
}));

const Text = styled("div")(({ theme }) => ({
  "& p": {
    fontSize: "1rem",
    lineHeight: "1.5rem",
    margin: "0 0 1rem 0",
    color: theme.palette.neutral.main,
    textAlign: "left",

    "&:last-of-type": {
      marginBottom: 0,
    },
  },

  "& h2": {
    fontSize: "1.5rem",
    lineHeight: "2rem",
    textAlign: "center",
    fontWeight: 900,
    margin: `18px 0 8px 0`,
    width: "100%",
  },
}));

const AdviceToggler = styled(PrimaryButton)(({ theme }) => ({
  display: "flex",

  [theme.breakpoints.up("sm")]: {
    display: "none",
  },
}));

export const WaitingStatusFragment = ({
  fadeIn,
  showAdvices,
  formSubmitted,
  hideAdvicesButton,
  interfaceText,
}) => {
  const defaultInterfaceText = {
    title: "Inväntar svar",
    description:
      "<p>Vi handlägger nu din inskickade ansökan om lån. I vanliga fall får du besked inom några minuter. Har du inte fått något besked inom det närmaste dygnet är du välkommen att kontakta oss på telefon 040-205 600. Du kan även komma att bli kontakta av oss vid eventulla frågor.</p>",
  };

  const heading =
    interfaceText && interfaceText?.title
      ? sanitizedString(interfaceText.title)
      : defaultInterfaceText.title;

  const text =
    interfaceText && interfaceText?.description ? (
      <SanitizedHtml html={interfaceText.description} tags={["p", "h2"]} />
    ) : (
      <SanitizedHtml
        html={defaultInterfaceText.description}
        tags={["p", "h2"]}
      />
    );

  return (
    <StatusContentLayout fadeIn={fadeIn} fullHeight='fullheight'>
      <Box pb={2}>
        <ScreenHeading>{heading}</ScreenHeading>
        <Text>{text}</Text>
      </Box>

      {!hideAdvicesButton && !formSubmitted && (
        <AdviceToggler variant='outlined' onClick={showAdvices}>
          Ta del av ekonomitips medan du väntar
        </AdviceToggler>
      )}
    </StatusContentLayout>
  );
};
