import React, { useContext, useEffect, useRef } from "react";

import { LsbFormControl } from "../../form-components/LsbFormControl";
import {
  citizenshipDataSchema,
  CompletionContext,
} from "../../contexts/CompletionContext";
import { Controller, useFormContext } from "react-hook-form";
import { sanitizedString } from "../../helpers/SanitizedString";
import { FormCheckbox } from "../../form-components/FormCheckbox";
import { FormControl, FormLabel } from "@mui/material";
import { FormBlock } from "../../../styles/ApplicationFormComponents";

export const CitizenshipFormControls = props => {
  const { inReview } = props;

  const { watch, control, setValue } = useFormContext();

  const meRef = useRef(false);

  useEffect(() => {
    if (!meRef.current) {
      meRef.current = true;
    }
  }, []);

  const { completionData, interfaceText, countries } =
    useContext(CompletionContext);

  const citizenshipDefaultValue = completionData?.citizenship?.countries ?? [];
  const citizenship = watch(citizenshipDataSchema.citizenship.countries, "");

  function onChangeCheckbox(checked, value) {
    let array = citizenship.slice();

    if (checked) {
      array.push(value);
    } else {
      array = array.filter(i => i !== value);
    }

    setValue("citizenship.countries", array, { shouldValidate: true });
  }

  const usCountry = countries?.citizenshipCountryList.find(
    item => item.countrycode === "US"
  );
  const otherCountry = countries?.citizenshipCountryList.find(
    item => item.countrycode === "OTHER"
  );

  return (
    <div style={{ width: "100%" }}>
      <Controller
        control={control}
        name={citizenshipDataSchema.citizenship.countries}
        defaultValue={citizenshipDefaultValue}
        render={() => {
          return null;
        }}
      />

      <Controller
        control={control}
        name='usCitizen'
        defaultValue={completionData?.usCitizen}
        render={() => {
          return null;
        }}
      />

      <FormControl>
        <FormLabel color='secondary'>
          {sanitizedString(interfaceText?.citizenshipLabel)}

          {interfaceText?.citizenshipHelp &&
            !inReview &&
            sanitizedString(interfaceText?.citizenshipHelp)}
        </FormLabel>

        {countries?.citizenshipCountryList.map(option => (
          <FormCheckbox
            key={option.countrycode}
            name={option.countrycode}
            label={option.name}
            icon={option.icon}
            disabled={inReview}
            control={control}
            onChangeCheckbox={onChangeCheckbox}
            defaultValue={citizenshipDefaultValue.includes(option.countrycode)}
          />
        ))}
      </FormControl>

      {citizenship.includes("US") && (
        <FormBlock fullwidth='full-width' review={inReview && "inReview"}>
          <LsbFormControl
            maxLength={11}
            formatType='common'
            disabled={inReview}
            label={sanitizedString(interfaceText?.usCitizenTaxID)}
            name={citizenshipDataSchema.usTaxID}
            placeholder={interfaceText?.usCitizenTaxIDPlaceholder}
            defaultValue={completionData?.usTaxID}
            helpText={
              !inReview && sanitizedString(interfaceText?.usCitizenTaxIDHelp)
            }
            icon={usCountry.icon}
          />
        </FormBlock>
      )}

      {citizenship.includes("OTHER") && (
        <FormBlock fullwidth='full-width' review={inReview && "inReview"}>
          <LsbFormControl
            formatType='common'
            disabled={inReview}
            label={sanitizedString(interfaceText?.citizenshipOtherCountryLabel)}
            name={citizenshipDataSchema.citizenship.otherCountry}
            placeholder={interfaceText?.citizenshipOtherCountryPlaceholder}
            defaultValue={completionData?.citizenship.otherCountry}
            helpText={
              !inReview &&
              sanitizedString(interfaceText?.citizenshipOtherCountryHelp)
            }
            icon={otherCountry.icon}
          />
        </FormBlock>
      )}
    </div>
  );
};
