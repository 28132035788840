import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  darken,
  Fab,
  Grid,
  styled,
  useMediaQuery,
} from "@mui/material";
import { faBars } from "@fortawesome/pro-regular-svg-icons";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";

import logoImage from "../../assets/images/logo-text.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Menu } from "../Menu/Menu";

import { useClickOutside } from "../helpers/UseClickOutside";
import { LightButton } from "../CTAButtons/CTAButtons";
import { logout } from "../../auth-service/auth-service";
import { loanStatus } from "../../config/loanStatus";

const Root = styled("header")(({ theme }) => ({
  position: "fixed",
  width: "100%",
  zIndex: 1350,
  display: "flex",
  alignItems: "center",
  height: theme.spacing(10),
  backgroundColor: theme.palette.neutralColor[5],
  boxShadow: "0 1px 4px 0 #EFF0F0",

  [theme.breakpoints.up("sm")]: {
    height: theme.spacing(12),
  },
}));

const LogoLink = styled("a")(({ theme }) => ({
  display: "block",

  "& img": {
    width: "100%",
    maxWidth: "221px",
    height: "auto",
    verticalAlign: "top",
  },
}));

const MenuToggler = styled("div")(({ theme }) => ({
  display: "inline-flex",
  justifyContent: "flex-end",
  alignItems: "center",
  cursor: "pointer",
  color: theme.palette.primary.main,
  float: "right",

  "&:hover": {
    color: darken(theme.palette.primary.main, 0.2),
  },

  "& svg": {
    fontSize: "1.25rem",
  },

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

const MenuTogglerTitle = styled("span")(({ theme }) => ({
  display: "inline-block",
  textTransform: "uppercase",
  fontWeight: 900,
  fontSize: "0.875rem",
  marginRight: "15px",
  letterSpacing: "0.38px",

  [theme.breakpoints.down("sm")]: {
    fontSize: "10px",
    order: 2,
    textAlign: "center",
    marginRight: 0,
    marginTop: "4px",
  },
}));

export const Header = ({ userLoggedIn, openModal, status }) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const [disableRequest, setDisableRequest] = useState(false);

  const contentRef = useRef(null);

  const BASE_SITE_URL = process.env.REACT_APP_BASE_URL;

  const isMobile = useMediaQuery(theme => theme.breakpoints.down("md"));

  const showMenu = (userStatus, appStatus) => {
    switch (userStatus) {
      case false:
        return false;
      case true:
        if (
          appStatus === loanStatus.processingIntermediary ||
          appStatus === loanStatus.declinedByCustomer
        ) {
          return false;
        }
        return true;
      default:
        return false;
    }
  };

  const defineDisableRequest = status => {
    switch (status) {
      case loanStatus.rejected:
      case loanStatus.waiting:
        return true;

      default:
        return false;
    }
  };

  useClickOutside(contentRef, null, () => {
    if (menuIsOpen) {
      setMenuIsOpen(false);
    }
  });

  function onToggleMenu() {
    setMenuIsOpen(!menuIsOpen);
  }

  function onGoBack() {
    logout();
  }

  const displayMenu = showMenu(userLoggedIn, status);

  useEffect(() => {
    setDisableRequest(defineDisableRequest(status));
  }, [status]);

  useEffect(() => {
    if (status === loanStatus.declinedByCustomer && menuIsOpen) {
      setMenuIsOpen(false);
    }
  }, [status, menuIsOpen]);

  return (
    <Root>
      <Container maxWidth='lg'>
        <Grid
          container
          justifyContent='space-between'
          style={{ position: "relative" }}
        >
          <Grid
            item
            container
            xs={6}
            justifyContent='flex-start'
            alignItems='center'
          >
            <LogoLink href={BASE_SITE_URL} title='Lån & Spår Bank'>
              <img src={logoImage} alt='Logo' />
            </LogoLink>
          </Grid>

          <Grid item xs={6} container justifyContent='flex-end'>
            {!displayMenu && (
              <LightButton
                size='small'
                endIcon={<FontAwesomeIcon icon={faArrowRight} />}
                onClick={() => onGoBack()}
              >
                {isMobile ? "Till lsb.se" : "Tillbaka till lsb.se"}
              </LightButton>
            )}
            {displayMenu && (
              <MenuToggler onClick={() => onToggleMenu()} ref={contentRef}>
                <MenuTogglerTitle>Meny</MenuTogglerTitle>
                <Fab
                  size='small'
                  color='primary'
                  aria-label='logout'
                  disableRipple
                >
                  <FontAwesomeIcon icon={faBars} />
                </Fab>
              </MenuToggler>
            )}
          </Grid>
          {menuIsOpen && (
            <Menu
              isOpen={menuIsOpen}
              closeMenu={onToggleMenu}
              openModal={openModal}
              disableRequest={disableRequest}
            />
          )}
        </Grid>
      </Container>
    </Root>
  );
};
