import * as yup from "yup";

export const loanBasicValidationSchema = yup.object().shape({
  bank: yup.string().required(),
  data: yup.object().shape({
    amountToRepay: yup.number().typeError().required(),
    cardNumber: yup
      .string()
      .test(value => value !== "0")
      .required(),
  }),
});

export const loanExtraFieldsValidationSchema = yup.object().shape({
  bank: yup.string().required(),
  data: yup.object().shape({
    amountToRepay: yup.number().typeError().required(),
    cardNumber: yup
      .string()
      .test(value => value !== "0")
      .required(),
    bankPlusGiro: yup
      .string()
      .test(value => value !== "0")
      .required(),
    ocrNumber: yup
      .string()
      .test(value => value !== "0")
      .required(),
  }),
});

export const loanOtherBankValidationSchema = yup.object().shape({
  bank: yup.string().required(),
  data: yup.object().shape({
    amountToRepay: yup.number().typeError().required(),
    cardNumber: yup
      .string()
      .test(value => value !== "0")
      .required(),
    otherBankName: yup
      .string()
      .test(value => value !== "0")
      .required(),
  }),
});

export const loanOtherBankWithExtraFieldsValidationSchema =
  loanOtherBankValidationSchema.concat(loanExtraFieldsValidationSchema);

export const getLoanValidationSchema = (
  banksWithExtraFields = [],
  otherBank
) => {
  return yup.lazy(values => {
    if (otherBank && values.bank === otherBank.id) {
      return loanOtherBankWithExtraFieldsValidationSchema;
    }
    if (banksWithExtraFields.includes(values.bank)) {
      return loanExtraFieldsValidationSchema;
    }

    return loanBasicValidationSchema;
  });
};
