import React from "react";
import { sanitizedString } from "../../helpers/SanitizedString";
import { HelpTooltip } from "../../HelpTooltip/HelpTooltip";
import { styled } from "@mui/material";

const Root = styled("div")(({ theme }) => ({
  paddingTop: "1.125rem",
  paddingBottom: "1.5rem",

  [theme.breakpoints.down("sm")]: {
    paddingBottom: "1rem",
  },
}));

const Title = styled("h3")(({ theme }) => ({
  fontSize: "1rem",
  color: "#363A38",
  lineHeight: "1.25rem",
  position: "relative",
  margin: "0 0 1rem 0",
}));

const Text = styled("p")(({ theme }) => ({
  fontSize: "0.875rem",
  lineHeight: "22px",
  letterSpacing: "0.26px",
  margin: 0,
}));

export const RepresentativeExample = ({ example }) => {
  return (
    <Root>
      <Title>
        {example?.repexTitle
          ? sanitizedString(example.repexTitle)
          : "Representativt exempel"}
        &nbsp;
        {example.repexHelptext && (
          <HelpTooltip text={sanitizedString(example.repexHelptext)} />
        )}
      </Title>

      <Text>{sanitizedString(example.repexText)}</Text>
    </Root>
  );
};
