import InlineSVG from "svg-inline-react";
import React from "react";
import { SvgWrapper } from "../SvgWrapper";

const source = `<svg width="150px" height="150px" viewBox="0 0 80 80" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<title>New-icon/delete-file@1x</title>
<g id="Bullet-icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
    <g id="Delprojekt-8---kompletterings-ikoner" transform="translate(-763.000000, -172.000000)">
        <g id="Icons8/Confimation-bullets/Exclamation-mark" transform="translate(763.000000, 172.000000)">
            <rect id="Rectangle-Copy" fill="#E1EFE4" x="0" y="0" width="80" height="80" rx="40"></rect>
            <g id="Icon/16px/search" transform="translate(10.000000, 10.000000)">
                <path d="M11.25,9.375 L11.25,49.8608169 L29.6183004,49.8608169 C29.9183508,49.8640609 30.1988331,49.7059134 30.3521191,49.4423342 C30.5021447,49.178755 30.5021447,48.8558709 30.3521191,48.5922917 C30.1988331,48.3287125 29.9183508,48.170565 29.6183004,48.1738595 L12.9198455,48.1738595 L12.9198455,11.0619069 L27.9484549,11.0619069 L27.9484549,22.8702555 L39.6373734,22.8702555 L39.6373734,34.678604 C39.6341122,34.9817201 39.7906602,35.2650682 40.0515736,35.4199203 C40.3124869,35.5714788 40.6321054,35.5714788 40.8930187,35.4199203 C41.1539321,35.2650682 41.3104801,34.9817201 41.3072689,34.678604 L41.3072689,21.684149 L41.0723969,21.4205698 L29.3834784,9.61222129 L29.1225651,9.375 L11.25,9.375 Z M29.6183004,12.2743713 L38.437172,21.1833485 L29.6183004,21.1833485 L29.6183004,12.2743713 Z M33.7146402,34.6522461 C33.6787644,34.658836 33.6428894,34.6687196 33.6102748,34.678604 C33.2971788,34.7346144 33.0460499,34.9652466 32.9579914,35.2716572 C32.869933,35.5813632 32.9612526,35.9108372 33.1928135,36.1282897 L40.1070175,43.1131387 L33.1928135,50.0979877 C32.8601493,50.4340516 32.8601493,50.974389 33.1928135,51.3104521 C33.5254784,51.646516 34.0603508,51.646516 34.3930149,51.3104521 L41.3072189,44.325603 L48.2214229,51.3104521 C48.5540879,51.646516 49.0889602,51.646516 49.4216244,51.3104521 C49.7542893,50.974389 49.7542893,50.4340516 49.4216244,50.0979877 L42.5074204,43.1131387 L49.4216244,36.1282897 C49.6923222,35.8745949 49.7640729,35.4726362 49.6010025,35.1398676 C49.4411933,34.8038046 49.0791758,34.6192991 48.7171583,34.678604 C48.5312577,34.6983729 48.3551408,34.7840357 48.2214229,34.9158253 L41.3072189,41.9006743 L34.3930149,34.9158253 C34.22016,34.7280253 33.9690311,34.6324781 33.7146402,34.6522461 Z" id="Shape" fill="#0D5D4B"></path>
            </g>
        </g>
    </g>
</g>
</svg>`;

export function DeleteFileIcon(props) {
  return (
    <SvgWrapper>
      <InlineSVG src={source} {...props} />
    </SvgWrapper>
  );
}
