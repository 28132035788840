import { useEffect, useState } from "react";

export const useIntervalAsyncHook = (callback, delay) => {
  let [randomI, setRandomI] = useState(Math.random());

  useEffect(() => {
    let timer;

    if (delay === null) {
      return;
    }

    if (delay !== null) {
      timer = setTimeout(async () => {
        await callback();
        setRandomI(Math.random());
        clearTimeout(timer);
      }, delay);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [callback, delay, randomI]);
};
