import { darken } from "@mui/material";
import { styled } from "@mui/material";
import Button, { buttonClasses } from "@mui/material/Button";

const style = {
  display: "flex",
  width: "100%",
  height: "auto",
  fontFamily: "Lato",
  fontWeight: 600,
  margin: "0 auto 16px auto",
  maxWidth: "346px",
  padding: "16px 24px",
  lineHeight: "24px",

  "&.cta-little": {
    width: "auto",
  },

  "&.cta-bold": {
    fontSize: "1.125rem",
    fontWeight: 700,
  },
};

export const RedButton = styled(Button)(({ theme }) => ({
  ...style,
  backgroundColor: theme.palette.red,
  color: "#F7F7F7",

  [theme.breakpoints.up("lg")]: {
    marginBottom: "24px",
    maxWidth: "346px",
  },

  [theme.breakpoints.down("sm")]: {
    whiteSpace: "break-spaces",
    lineHeight: "22px",
  },

  "&:hover": {
    backgroundColor: darken(theme.palette.red, 0.2),
  },
}));

export const PrimaryButton = styled(Button)(({ theme }) => ({
  ...style,
  backgroundColor: theme.palette.primary,

  [theme.breakpoints.up("lg")]: {
    marginBottom: "24px",
    maxWidth: "346px",
  },

  [theme.breakpoints.down("sm")]: {
    whiteSpace: "break-spaces",
    lineHeight: "22px",
  },

  "&.MuiButton-contained": {
    backgroundColor: theme.palette.primary,
    color: "#F7F7F7",

    "&:hover": {
      backgroundColor: theme.palette.primary,
    },
  },

  [`&.${buttonClasses.contained}`]: {
    boxShadow: "none",
    "&:hover, &:active": {
      boxShadow: "none",
      color: "#fff",
    },
    [`&.${buttonClasses.disabled}`]: {
      boxShadow: "none",
      backgroundColor: "rgba(0 0 0 0 0.25)",
      color: theme.palette.text.secondary,
    },
  },

  [`&.${buttonClasses.outlined}`]: {
    color: theme.palette.neutral.lighter,
    borderColor: theme.palette.neutral.main,
    borderRadius: "32px",

    "&:hover": {
      backgroundColor: "transparent",
      borderColor: theme.palette.neutralColor[30],
    },
  },

  [`&.${buttonClasses.outlinedPrimary}`]: {
    color: theme.palette.primary,
    borderColor: theme.palette.primary,
    borderRadius: "32px",

    "&:hover": {
      backgroundColor: "transparent",
      borderColor: theme.palette.primaryColor[80],
    },

    [`&.${buttonClasses.disabled}`]: {
      boxShadow: "none",
      cursor: "default",
      color: "rgba(0, 0, 0, 0.26)",
      border: "1px solid rgba(0, 0, 0, 0.12);",
    },
  },
}));

export const TextButton = styled(Button)(({ theme }) => ({
  ...style,
  padding: "1rem 2rem",
}));

export const LightButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.neutralColor[10],
  color: theme.palette.neutralColor[90],
  border: "1px solid",
  borderColor: theme.palette.neutralColor[20],
  boxShadow: "none",

  "&.MuiButton-sizeSmall": {
    height: "auto",
    padding: "10px 16px",
    fontSize: 14,

    "& .MuiButton-iconSizeSmall svg": {
      fontSize: "13px",
      marginTop: "-2px",
    },
  },

  "&:hover": {
    border: "1px solid",
    borderColor: theme.palette.neutralColor[20],
    backgroundColor: theme.palette.neutralColor[4],
    color: theme.palette.neutralColor[90],
    boxShadow: "none",
  },
}));

export const NextButton = styled(Button)(({ theme, processing }) => ({
  pointerEvents: processing === "processing" && "none",
}));

export const NeutralButton = styled(Button)(({ theme }) => ({}));
