import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useContext } from "react";
import { Controller, FormProvider } from "react-hook-form";
import {
  CompletionContext,
  moreInfoDataSchema,
} from "../../contexts/CompletionContext";
import { LsbSelect } from "../../form-components/LsbSelect";
import { sanitizedString } from "../../helpers/SanitizedString";
import { HelpTooltip } from "../../HelpTooltip/HelpTooltip";

import { createSelectOptions } from "../createPoliticServicesOptionsArray";
import politicServicesData from "../politicServices.json";
import { MultilineOptionSelect } from "../../../styles/ApplicationFormComponents";

export const MoreAboutYouFormComponent = props => {
  const { inReview, formMethods } = props;

  const { completionData, interfaceText } = useContext(CompletionContext);

  const politicServicesSelectOptions = politicServicesData.length
    ? createSelectOptions(politicServicesData)
    : [];

  const { control } = formMethods;

  const pepContactDefaultValue = completionData?.pepContact
    ? "yes"
    : completionData?.pepContact === null
    ? null
    : "no";

  const pepSelfDefalutValue = completionData?.pepSelf ?? "";

  return (
    <FormProvider {...formMethods}>
      <Grid item xs={12} sm={10} md={7} lg={6}>
        <MultilineOptionSelect>
          <LsbSelect
            options={politicServicesSelectOptions}
            name={moreInfoDataSchema.pepSelf}
            defaultValue={pepSelfDefalutValue}
            disabled={inReview}
            label={sanitizedString(interfaceText?.pepSelfLabel)}
            helpText={!inReview && sanitizedString(interfaceText?.pepSelfHelp)}
          />
        </MultilineOptionSelect>

        <Controller
          name={moreInfoDataSchema.pepContact}
          control={control}
          defaultValue={pepContactDefaultValue}
          render={({ field: { onChange, value, name } }) => {
            return (
              <FormControl
                component='fieldset'
                disabled={inReview}
                margin={inReview && "dense"}
                color='secondary'
              >
                <FormLabel
                  component='legend'
                  htmlFor={moreInfoDataSchema.pepContact}
                  id='pepContactLabel'
                >
                  {sanitizedString(interfaceText?.pepContactLabel)}
                  {!inReview && (
                    <HelpTooltip
                      inline
                      text={sanitizedString(interfaceText?.pepContactHelp)}
                    />
                  )}
                </FormLabel>
                <RadioGroup
                  id={moreInfoDataSchema.pepContact}
                  name={name}
                  aria-label='Person i politiskt utsatt ställning'
                  onChange={e => {
                    onChange(e);
                  }}
                  value={value}
                >
                  <FormControlLabel
                    value='yes'
                    control={<Radio size='small' color='secondary' />}
                    label='Ja'
                  />
                  <FormControlLabel
                    value='no'
                    control={<Radio size='small' color='secondary' />}
                    label='Nej'
                  />
                </RadioGroup>
              </FormControl>
            );
          }}
        />
      </Grid>
    </FormProvider>
  );
};
