import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Section } from "../../Layout/Section";
import { Name } from "../../misc/Typography/Name";
import { TaxesFormControls } from "./TaxesFormControls";
import { TaxesRadioButtonsGroup } from "./TaxesRadioButtonsGroup";
import {
  AddMoreButton,
  StepSectionPaddingTop,
} from "../../../styles/ApplicationFormComponents";

export const TaxesFormComponent = ({
  addTaxField,
  fields,
  removeTaxFields,
  isOtherCountriesTaxesValid,
  onRemoveTaxField,
  personalData,
}) => {
  const { watch } = useFormContext();

  const hasOtherTaxResidences = watch("hasOtherTaxResidences", null);

  const [disabledAddButton, setDisabledAddButton] = useState(false);

  useEffect(() => {
    if (fields.length === 0) {
      return;
    }
    let hasInvalidTax =
      fields.length !== 0 &&
      fields.some(item => item?.countrycode === "" || item?.taxID === "");
    if (hasInvalidTax) {
      setDisabledAddButton(true);
    }
  }, [fields]);

  useEffect(() => {
    isOtherCountriesTaxesValid(!disabledAddButton);
  }, [isOtherCountriesTaxesValid, disabledAddButton]);

  return (
    <StepSectionPaddingTop>
      <Section>
        {personalData && (
          <Grid item xs={12}>
            <Name personalData={personalData} />{" "}
          </Grid>
        )}

        <TaxesRadioButtonsGroup
          addTaxField={addTaxField}
          removeTaxFields={removeTaxFields}
        />

        {hasOtherTaxResidences === "other_countries" &&
          fields.map((field, index) => (
            <TaxesFormControls
              key={field.idn}
              defaultValues={field}
              index={index}
              setDisabledAddButton={setDisabledAddButton}
              onRemoveTaxField={onRemoveTaxField}
            />
          ))}

        {hasOtherTaxResidences === "other_countries" && (
          <Grid item xs={12}>
            <AddMoreButton
              color='primary'
              variant='outlined'
              onClick={addTaxField}
              disabled={disabledAddButton}
            >
              Lägg till fler skatterättsliga hemvister
            </AddMoreButton>
          </Grid>
        )}
      </Section>
    </StepSectionPaddingTop>
  );
};
