import { Grid } from "@mui/material";
import React from "react";
import { PaperLayout } from "../../Layout/PaperLayout";
import { ShowReminderFragment } from "../../StatusPage/fragments/ShowReminderFragment";

export const ReminderScreen = ({
  onNextMove,
  internalLoan,
  hasExternalLoan,
}) => {
  return (
    <PaperLayout>
      <Grid container justifyContent='center'>
        <ShowReminderFragment
          fadeIn={true}
          onNextMove={onNextMove}
          internalLoan={internalLoan}
          hasExternalLoan={hasExternalLoan}
        />
      </Grid>
    </PaperLayout>
  );
};
