import { gql } from "@apollo/client";

export const GET_BANKS = gql`
  query getBanks {
    banks {
      errors
      data {
        id
        name
        icon
        loanRepaymentFields
        loanRepaymentDisplayExtraInfo
      }
    }
  }
`;
