import React from "react";
import { CoApplicantSection } from "../../Layout/CoApplicantSection";
import { CoApplicantMoreAboutYouFormComponent } from "./components/CoApplicantMoreAboutYouFormComponent";

export const CoApplicantMoreAboutYou = props => {
  return (
    <CoApplicantSection
      personalData={props.personalData}
      title='Mer kunskap om din medsökande'
    >
      <CoApplicantMoreAboutYouFormComponent {...props} />
    </CoApplicantSection>
  );
};
