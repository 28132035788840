import { Grid, styled } from "@mui/material";
import { Heading } from "../components/misc/Typography/Heading";
import { Paragraph } from "../components/misc/Typography/Paragraph";

const SignAppRoot = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyItems: "center",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(10),
  paddingLeft: theme.spacing(2.5),
  paddingRight: theme.spacing(2.5),
  height: "100%",
}));

const SignAppIcon = styled("div")(({ theme }) => ({
  color: theme.palette.secondary.main,
  animation: `animateCheck .7s ${theme.transitions.easing.easeInOut}`,
  animationFillMode: "forwards",
  textAlign: "center",

  "@keyframes animateCheck": {
    "0%": {
      opacity: 0,
      fontSize: "1rem",
      transform: "scale(0)",
    },
    "50%": {
      transform: "scale(1.1)",
    },
    "100%": {
      opacity: 1,
      fontSize: "7.25rem",
      transform: "scale(1)",
    },
  },
}));

const SignAppText = styled(Paragraph)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  [theme.breakpoints.up("sm")]: {
    textAlign: "center",
  },
}));

const SignAppHeading = styled(Heading)(({ theme }) => ({
  opacity: 0,
  animation: `fadeIn 1s ${theme.transitions.easing.easeInOut}`,
  animationDelay: ".6s",
  animationFillMode: "forwards",

  "@keyframes fadeIn": {
    "0%": {
      opacity: 0,
    },

    "100%": {
      opacity: 1,
    },
  },
}));

export { SignAppRoot, SignAppIcon, SignAppHeading, SignAppText };
