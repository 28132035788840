import { styled } from "@mui/system";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";

export const LsbStepConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 17,
    left: "calc(-55% + 18px)",
    right: "calc(45% + 18px)",
    position: "absolute",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.secondary.main,
      animation: `fillingLine ${theme.transitions.easing.easeInOut}`,
      animationDuration: "1.2s",
      animationFillMode: "forwards",
      [theme.breakpoints.up("sm")]: {
        animationDuration: "1.5s",
      },
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.secondary.main,
      animation: "none",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: "#eaeaf0",
    borderTopWidth: 3,
    transition: theme.transitions.create("border-color"),
  },

  "@keyframes fillingLine": {
    "0%": {
      width: 0,
    },
    "100%": {
      width: "100%",
    },
  },
}));
