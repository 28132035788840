import { styled, Button, FormLabel } from "@mui/material";
import InlineSVG from "svg-inline-react";

const rootClass = {
  paddingTop: "5rem",
};

const overflowHiddenClass = { paddingBottom: "5px", overflow: "hidden" };

const sectionPaddingsClass = { paddingTop: "2.5rem", paddingBottom: "2.5rem" };

const sectionPaddingTopClass = { paddingTop: "2.5rem" };

const sectionPaddingBottomSmClass = { paddingTop: "3rem" };

const fullwidthClass = { width: "100%" };

const StepSectionPaddingTop = styled("div")(({ theme }) => ({
  paddingTop: theme.spacing(5),
}));

const BtnGroup = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  gap: theme.spacing(2),

  [theme.breakpoints.down("sm")]: {
    justifyContent: "space-between",
  },
}));

const LineShadow = styled("div")(({ theme }) => ({
  position: "relative",
  top: "20px",
  height: "3px",
  backgroundColor: "#eaeaf0",
  margin: "0 5px",
}));

const MainPageRoot = styled("section")(({ theme }) => ({
  '& [aria-hidden="true"]': {
    display: "initial",
    visibility: "visible",
  },
}));

const MainPageHeaderImage = styled(InlineSVG)(({ theme }) => ({
  margin: 0,
  width: "100%",
  overflow: "hidden",

  [theme.breakpoints.down("sm")]: {
    height: "72px",
  },
  "& svg": {
    maxWidth: "100%",
    height: "auto",
    objectFit: "contain",
    [theme.breakpoints.down("sm")]: {
      transform: "scaleX(3) scaleY(3.4)",
    },
  },
}));

const AddMoreButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const FormLabelLegend = styled(FormLabel)(({ theme }) => ({
  fontSize: "15px",
  lineHeight: "22px",
  fontWeight: 900,

  "&.Mui-focused": {
    color: theme.palette.neutral.main,
  },

  [theme.breakpoints.down("sm")]: {
    whiteSpace: "break-spaces",
  },

  [theme.breakpoints.up("sm")]: {
    fontSize: "16px",
    lineHeight: "24px",
  },
}));

const FormBlock = styled("div")(({ theme, fullwidth, review }) => ({
  position: "relative",
  boxSizing: "border-box",
  padding: "20px 20px 2px 20px",
  backgroundColor: review ? "#fff" : "#F7F7F7",
  border: "1px solid #E5E6E5",
  marginBottom: theme.spacing(3),
  minWidth: fullwidth && !review && "100%",

  [theme.breakpoints.up("sm")]: {
    marginBottom: theme.spacing(4),
    borderRadius: "4px",
  },

  [theme.breakpoints.down("sm")]: {
    borderLeft: 0,
    borderRight: 0,
    marginLeft: fullwidth && "-20px",
    marginRight: fullwidth && "-20px",
  },
}));

const MultilineOptionSelect = styled("div")(({ theme }) => ({
  "& .MuiSelect-selectMenu": {
    whiteSpace: "normal",
  },
}));

const classes = {
  root: rootClass,
  overflowHidden: overflowHiddenClass,
  sectionPaddings: sectionPaddingsClass,
  sectionPaddingsTop: sectionPaddingTopClass,
  sectionPaddingBottomSm: sectionPaddingBottomSmClass,
  fullwidth: fullwidthClass,
};

export {
  classes,
  LineShadow,
  BtnGroup,
  MainPageRoot,
  MainPageHeaderImage,
  AddMoreButton,
  FormLabelLegend,
  FormBlock,
  MultilineOptionSelect,
  StepSectionPaddingTop,
};
