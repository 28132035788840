import * as yup from "yup";

const tinRegEx =
  /^(?=[0-9-]{9,11})(?!(.*-){3})(?=(.*\d){9})(?!(.*\d){10})[0-9-]+$/;

export const citizenshipValidationSchema = yup.object().shape({
  citizenship: yup.object().shape({
    countries: yup
      .array()
      .of(yup.string())
      .min(1, "Vänligen välj alternativ.")
      .max(3, "Vänligen välj max 3 alternativ."),
    otherCountry: yup.string().when("countries", {
      is: array => {
        if (array) {
          return array.includes("OTHER");
        }
        return false;
      },
      then: schema =>
        schema
          .required("Vänligen ange landets/ländernas namn.")
          .max(60, "Fältet ska innehålla max 60 tecken."),
      otherwise: schema => schema.notRequired(),
    }),
  }),
  usCitizen: yup.boolean(),
  usTaxID: yup.string().when("usCitizen", {
    is: true,
    then: schema =>
      schema
        .required("Vänligen ange TIN.")
        .matches(tinRegEx, "TIN består av 9 siffror och 1 eller 2 bindestreck.")
        .min(9, "TIN består av 9 siffror och 1 eller 2 bindestreck.")
        .max(11, "TIN består av 9 siffror och 1 eller 2 bindestreck."),
    otherwise: schema => schema.notRequired(),
  }),
  hasCoApplicant: yup.boolean(),
  coApplicant: yup.object({}).when("hasCoApplicant", {
    is: true,
    then: schema =>
      schema.shape({
        citizenship: yup.object().shape({
          countries: yup
            .array()
            .of(yup.string())
            .min(1, "Vänligen välj alternativ.")
            .max(3, "Vänligen välj max 3 alternativ."),
          otherCountry: yup.string().when("countries", {
            is: array => array.includes("OTHER"),
            then: schema => schema.required().max(50, "Max 50 tecken."),
            otherwise: schema => schema.notRequired(),
          }),
        }),
        usCitizen: yup.boolean(),
        usTaxID: yup.string().when("usCitizen", {
          is: true,
          then: schema =>
            schema
              .required()
              .matches(
                tinRegEx,
                "TIN består av 9 siffror och 1 eller 2 bindestreck."
              )
              .min(9, "TIN består av 9 siffror och 1 eller 2 bindestreck.")
              .max(11, "TIN består av 9 siffror och 1 eller 2 bindestreck."),
          otherwise: schema => schema.notRequired(),
        }),
      }),
    otherwise: schema => schema.notRequired(),
  }),
});
