import { Box, styled } from "@mui/material";
import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-light-svg-icons";

import { PrimaryButton } from "../../CTAButtons/CTAButtons";

import { IngressTextLink } from "../../HelpText/IngressText/IngressTextLink";
import { IngressTextCard } from "../../HelpText/IngressText/IngressTextCard";
import { StatusContentLayout } from "./StatusContentLayout";
import { Heading } from "../../misc/Typography/Heading";
import { Paragraph } from "../../misc/Typography/Paragraph";
import { List } from "../../misc/List/List";
import { ListItem } from "../../misc/List/ListItem";
import { InfoBlurbIcon } from "../../SvgIcons/InfoBlurbIcon/InfoBlurbIcon";
import { TotalAmount } from "../components/TotalAmount";

const ReminderContent = styled(Box)(({ theme }) => ({
  textAlign: "left",

  "& button.MuiButtonBase-root": { float: "right" },
}));

const ReminderHeading = styled(Heading)(({ theme }) => ({
  fontSize: "1.5rem",
  textAlign: "left",
  marginBottom: theme.spacing(2),
}));

const ReminderTexbox = styled("div")(({ theme }) => ({
  paddingBottom: theme.spacing(4),
}));

const ReminderListbox = styled("div")(({ theme }) => ({
  paddingTop: theme.spacing(1.5),
  paddingRight: theme.spacing(3),
  paddingLeft: theme.spacing(3),
  border: "1px solid #E5E6E5",
  borderRadius: "4px",
  marginTop: 0,
  marginBottom: theme.spacing(5),
}));

const texts = {
  title: {
    default: "Vad innebär det att lösa in lån?",
    internalLoan: "Du har ett lösenkrav hos oss på Lån & Spar Bank",
  },

  text: {
    default:
      "Det låneerbjudande som du valt att gå vidare med inkluderar ett så kallat lösenkrav. Det innebär att hela, eller delar av, det lånebelopp du blivit beviljad ska användas för att lösa lån och krediter som du har i andra banker/kreditinstitut. Eventuell medlåntagare och denne persons lån inkluderas också i aktuellt lösenkrav.",
    internalLoan:
      "Det låneerbjudande som du valt att gå vidare med inkluderar ett så kallat lösenkrav. Det innebär att hela, eller delar av, det lånebelopp du blivit beviljad ska användas för att lösa lån som du har hos oss. Eventuell medlåntagare och denne persons lån hos oss inkluderas också i aktuellt lösenkrav.",
  },
};

export const ShowReminderFragment = props => {
  const { fadeIn, onNextMove, internalLoan, hasExternalLoan } = props;

  const [openIngressText, setOpenIngressText] = useState(false);

  function handleOpenIngressText() {
    setOpenIngressText(true);
  }

  const showExternalLoanReminder = internalLoan && !hasExternalLoan;

  return (
    <StatusContentLayout fadeIn={fadeIn}>
      <InfoBlurbIcon />
      <ReminderContent>
        <ReminderTexbox>
          <ReminderHeading>
            {showExternalLoanReminder
              ? texts.title.internalLoan
              : texts.title.default}
          </ReminderHeading>
          <Paragraph textAlignLeft>
            {showExternalLoanReminder
              ? texts.text.internalLoan
              : texts.text.default}
            <IngressTextLink
              title='Läs mer'
              handleOpenIngressText={handleOpenIngressText}
            />
          </Paragraph>
          {openIngressText && (
            <IngressTextCard
              text={
                "<h3>Vad är ett lösenkrav?</h3><p>Ett lösenkrav är en form av villkor (ett krav) för att det lånet du blivit beviljad ska betalas ut. Det innebär att hela, eller delar av, dina lån i andra banker/kreditinstitut behöver lösas. Den sammanlagda summan av lånen du väljer att lösa måste uppgå till minst det belopp som framgår av lösenkravet. Om ekonomiskt utrymme finns kan vi hjälpa dig att lösa lån till ett högre belopp.</p><h3>Vilka lån omfattas av lösenkravet?</h3><p>Lösenkravet omfattar endast så kallade lån utan säkerhet (blancolån). Eventuella kort- eller kontokrediter inkluderas ej och kan därför inte anges för att nå upp i den summa som kravet är ställt till. <p>Om det finns en medlåntagare inkluderas även denne persons eventuella lån/kredit i aktuellt lösenkrav. Det innebär att dina lån, medlåntagarens lån och gemensamma lån kan anges för att uppnå den summa som framgår av kravet.</p> <p>Notera även att extraamorteringar inkluderas i lösenkravet. Ett lån behöver därmed inte lösas i sin helhet.</p>"
              }
              openIngressText={openIngressText}
              closeIngressText={setOpenIngressText}
            />
          )}
        </ReminderTexbox>

        {showExternalLoanReminder && (
          <React.Fragment>
            <TotalAmount
              title='Beloppet som ska lösas in'
              totalAmount={internalLoan}
            />
          </React.Fragment>
        )}

        {!showExternalLoanReminder && (
          <React.Fragment>
            <ReminderTexbox>
              <ReminderHeading>Vilka uppgifter behövs?</ReminderHeading>
              <Paragraph textAlignLeft>
                Vilka uppgifter som krävs för att lösa lån varierar beroende på
                vilken bank eller kreditgivare som lånet finns hos. En tumregel
                är att du alltid ska ha så mycket information som möjligt om
                aktuella lån/krediter.
              </Paragraph>
              <Paragraph textAlignLeft>
                I listan nedan kan du se vilken information som, i de flesta
                fall, behövs vid lösen av lån. En del av dessa uppgifter kan du
                hitta på din senaste låneavisering. Vår rekommendation är dock
                alltid att du kontaktar den bank/kreditgivare som lånet finns
                hos. Detta för att du ska vara säker på att uppgifterna är
                korrekta.
              </Paragraph>
            </ReminderTexbox>
            <ReminderHeading>
              Information som behövs vid lösen av lån
            </ReminderHeading>
            <ReminderListbox>
              <List bullet='bullet'>
                <ListItem>Aktuell bank/kreditgivare</ListItem>
                <ListItem>Belopp för lösen av lån/extraamortering</ListItem>
                <ListItem>Aktuellt lånenummer/kreditnummer</ListItem>
                <ListItem>
                  Bankens/kreditgivarens bankgiro eller plusgiro
                </ListItem>
                <ListItem>
                  OCR-/referensnummer eller meddelande vid inbetalning
                </ListItem>
              </List>
            </ReminderListbox>
          </React.Fragment>
        )}
        <PrimaryButton
          variant='contained'
          className='cta-little'
          endIcon={<FontAwesomeIcon icon={faChevronRight} />}
          onClick={() => {
            onNextMove();
          }}
        >
          Fortsätt
        </PrimaryButton>
      </ReminderContent>
    </StatusContentLayout>
  );
};
