import { useMutation } from "@apollo/client";
import { useContext } from "react";
import { CompletionContext } from "../components/contexts/CompletionContext";
import { UPDATE_COMPLETION_DATA } from "./UpdateCompletionData.mutation";

export const useUpdateCompletionData = () => {
  const { setErrorOnRequest, setUpdatedData, setMaintenanceMode } =
    useContext(CompletionContext);

  const [updateCompletionData] = useMutation(UPDATE_COMPLETION_DATA, {
    onError: error => {
      if (error.networkError.statusCode === 503) {
        return setMaintenanceMode({ mode: true, msg: error.message });
      }
      setErrorOnRequest(error.message);
    },
    onCompleted: response => {
      if (
        response?.updateCompletion?.errors &&
        !response?.updateCompletion?.errors.length
      ) {
        setUpdatedData(response.updateCompletion.data);
      } else {
        setErrorOnRequest("Error on update");
        console.error("Errors on update: ", response.updateCompletion.errors);
      }
    },
  });

  return updateCompletionData;
};
