import React from "react";
import { TextualLinkButton } from "../../TextualLinkButton/TextualLinkButton";

export const IngressTextLink = ({
  openIngressText,
  handleOpenIngressText,
  title = "Läs mer",
}) => {
  return (
    <TextualLinkButton
      disabled={openIngressText}
      onClick={handleOpenIngressText}
    >
      {title}
    </TextualLinkButton>
  );
};
