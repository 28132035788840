import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useRef,
} from "react";
import * as Scroll from "react-scroll";

import { Fade, Grid } from "@mui/material";
import { Section } from "../Layout/Section";
import { SanitizedHtml } from "../helpers/SanitizedHtml";
import { IngressTextCard } from "../HelpText/IngressText/IngressTextCard";
import { IngressTextLink } from "../HelpText/IngressText/IngressTextLink";
import { CompletionContext } from "../contexts/CompletionContext";
import { OtherCountriesTaxesForm } from "./OtherCountriesTaxesForm";
import { useUpdateCompletionData } from "../../graphql/useUpdateCompletionData";
import { StepHeading } from "../misc/Typography/StepHeading";
import { sanitizedString } from "../helpers/SanitizedString";
import { StepPreamble } from "../misc/Typography/StepPreamble";
import { StepContent } from "../Layout/StepContent";
import { HelpTooltip } from "../HelpTooltip/HelpTooltip";
import { Spinner } from "../misc/Spinner/Spinner";

export const OtherCountriesTaxesStep = props => {
  const {
    stepIndex,
    disableCheckStep,
    checkCompleted,
    handleMove,
    setDisableMove,
  } = props;

  const [fadeIn, setfadeIn] = useState(false);

  const [completed, setCompleted] = useState(false);

  const [loading, setLoading] = useState(false);

  const scroll = Scroll.animateScroll;

  const {
    handleStepsCompleted,
    stepsCompleted,
    completionData,
    interfaceText,
    completionInput,
  } = useContext(CompletionContext);

  const hasCoApplicant = completionData?.hasCoApplicant ?? false;

  const applicantPersonalData = completionData?.extraInformation ?? null;
  const coApplicantPersonalData =
    completionData?.coApplicant?.extraInformation ?? null;

  const initialValues = {
    otherTaxResidences: completionData.otherTaxResidences
      ? completionData.otherTaxResidences
      : [],
    coApplicant: {
      otherTaxResidences: completionData?.coApplicant.otherTaxResidences
        ? completionData?.coApplicant.otherTaxResidences
        : [],
    },
  };

  const [openIngressText, setOpenIngressText] = useState(false);

  const updateCompletionData = useUpdateCompletionData();

  const stepRef = useRef(false);

  const sendCompletionData = useCallback(
    async data => {
      const input = {
        ...completionInput,
        hasOtherTaxResidences:
          data?.hasOtherTaxResidences === "other_countries",
        otherTaxResidences: data?.otherTaxResidences ?? [],
        coApplicant: hasCoApplicant
          ? {
              ...completionInput.coApplicant,
              hasOtherTaxResidences:
                data?.coApplicant?.hasOtherTaxResidences === "other_countries",
              otherTaxResidences: data?.coApplicant?.otherTaxResidences ?? [],
            }
          : null,
      };

      scroll.scrollToTop({
        smooth: true,
        duration: 100,
      });

      try {
        setLoading(true);
        setDisableMove(true);

        const res = await updateCompletionData({
          variables: {
            input: input,
          },
        });

        if (!res?.data?.errors) {
          handleMove();
          setLoading(false);
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    },
    [
      updateCompletionData,
      handleMove,
      hasCoApplicant,
      completionInput,
      scroll,
      setDisableMove,
    ]
  );

  useEffect(() => {
    handleStepsCompleted(stepIndex, completed);
  }, [completed, handleStepsCompleted, stepIndex]);

  useEffect(() => {
    if (stepRef.current) return;

    stepRef.current = true;

    setfadeIn(props.fadeIn);
    disableCheckStep();

    return () => {
      stepRef.current = false;
    };
  }, [completionData, props.fadeIn, disableCheckStep]);

  function handleOpenIngressText() {
    setOpenIngressText(true);
  }

  return (
    <Fade in={fadeIn} timeout={{ appear: 100, enter: 1000, exit: 1500 }}>
      <StepContent>
        {loading && <Spinner overlay />}

        <Section>
          <Grid item xs={12}>
            <StepHeading
              title={
                <SanitizedHtml html={interfaceText?.otherTaxDomicileTitle} />
              }
              helpText={
                interfaceText?.otherTaxDomicileHelp && (
                  <HelpTooltip
                    text={sanitizedString(interfaceText?.otherTaxDomicileHelp)}
                  />
                )
              }
            />
          </Grid>
          <Grid item sm={8}>
            <StepPreamble>
              <SanitizedHtml
                html={interfaceText?.otherTaxDomicileDescription || ""}
              />

              <IngressTextLink handleOpenIngressText={handleOpenIngressText} />
            </StepPreamble>
          </Grid>

          {openIngressText && (
            <Grid item sm={8}>
              <IngressTextCard
                text={interfaceText?.otherTaxDomicileIngress || ""}
                openIngressText={openIngressText}
                closeIngressText={setOpenIngressText}
              />
            </Grid>
          )}
        </Section>

        <OtherCountriesTaxesForm
          otherTaxResidences={initialValues}
          hasCoApplicant={hasCoApplicant}
          setCompleted={completed => setCompleted(completed)}
          submitForm={checkCompleted && stepsCompleted[stepIndex] && !loading}
          onSubmit={sendCompletionData}
          applicantPersonalData={applicantPersonalData}
          coApplicantPersonalData={coApplicantPersonalData}
        />
      </StepContent>
    </Fade>
  );
};
