import React, { useEffect, useState } from "react";
import { Grid, styled } from "@mui/material";
import { NumericFormat } from "react-number-format";
import thumbUpIcon from "../../../assets/images/thumb.png";

const SummationBlock = styled(Grid)(({ theme, success }) => ({
  border: "2px solid",
  borderColor: success ? theme.palette.secondary.main : "#F2B5A7",
  backgroundColor: success ? "#fff" : "#FEFCFB",
  borderRadius: "8px",
  paddingTop: theme.spacing(3),
  paddingBottom: success ? theme.spacing(3) : theme.spacing(5),
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  marginTop: theme.spacing(3),

  [theme.breakpoints.up("sm")]: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4.5),
  },
}));

const SummationHeading = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
}));

const SummationTitle = styled("h3")(({ theme }) => ({
  color: theme.palette.neutral.main,
  fontSize: "1.5rem",
  fontWeight: 900,
  lineHeight: "40px",
  margin: 0,
}));

const SummationSuccessIcon = styled("img")(({ theme }) => ({
  width: "42px",
  height: "42px",
  marginRight: "14px",
}));

const SummationTotal = styled(NumericFormat)(({ theme, error }) => ({
  color: error ? "#CD332D" : "#5F6161",
  whiteSpace: "nowrap",
}));

const ErrorOverflow = styled("p")(({ theme }) => ({
  fontSize: "0.875rem",
  lineHeight: "17px",
  color: "#E12727",
  marginBottom: 0,
}));

const SumamtionList = styled("dl")(({ theme }) => ({
  margin: "16px 0 0 0",

  "& dt": {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    lineHeight: "1.75rem",
    color: theme.palette.neutral.main,
    marginBottom: "4px",

    "& span:first-of-type": {
      fontSize: "0.875rem",
      lineHeight: "1.85rem",
    },
  },
}));

export const SummationComponent = ({
  approvedAmount,
  totalDebts,
  completed,
  totalAmountToRepay,
  amountOverflow,
  feesAmount,
  internalLoanAmount,
}) => {
  const [sumOk, setSumOk] = useState(false);

  const maxAmount = approvedAmount - feesAmount;

  useEffect(() => {
    setSumOk(completed && totalDebts <= maxAmount);
  }, [completed, totalDebts, maxAmount]);

  return (
    <SummationBlock
      success={sumOk ? "success" : null}
      item
      xs={12}
      sm={8}
      md={6}
    >
      <SummationHeading>
        {sumOk && <SummationSuccessIcon src={thumbUpIcon} alt='status' />}
        <SummationTitle>Summering</SummationTitle>
      </SummationHeading>
      <SumamtionList>
        <dt>
          <span>Beviljat lånebelopp:</span>
          <NumericFormat
            value={approvedAmount}
            displayType='text'
            thousandSeparator={" "}
            suffix={" kr"}
            style={{ whiteSpace: "nowrap" }}
          />
        </dt>

        {internalLoanAmount && (
          <dt>
            <span>
              Lösenkrav hos{" "}
              <span style={{ whiteSpace: "nowrap" }}>Lån & Spar Bank</span>:
            </span>
            <NumericFormat
              value={internalLoanAmount}
              displayType='text'
              thousandSeparator={" "}
              suffix={" kr"}
              style={{ whiteSpace: "nowrap" }}
            />
          </dt>
        )}
        <dt>
          <span>Lösenkrav hos andra banker:</span>
          <NumericFormat
            value={totalAmountToRepay}
            displayType='text'
            thousandSeparator={" "}
            suffix={" kr"}
            style={{ whiteSpace: "nowrap" }}
          />
        </dt>
        <dt>
          <span>Angivet belopp att lösa:</span>
          <SummationTotal
            error={!sumOk || amountOverflow ? "error" : null}
            value={totalDebts}
            displayType='text'
            thousandSeparator={" "}
            suffix={" kr"}
          />
        </dt>
      </SumamtionList>

      {amountOverflow && (
        <ErrorOverflow>
          Angiven summa för lösen av lån överstiger ditt beviljade lånebelopp.
          Vänligen kontrollera uppgifterna nedan och säkerställ att det totala
          beloppet inte är högre än{" "}
          <NumericFormat
            value={maxAmount}
            displayType='text'
            thousandSeparator={" "}
            suffix={" kr"}
            style={{ whiteSpace: "nowrap" }}
          />
          &nbsp;(exkl. {feesAmount} kr i uppläggningsavgift).
        </ErrorOverflow>
      )}
    </SummationBlock>
  );
};
