import React from "react";
import { SvgIcon, styled } from "@mui/material";

const SvgRoot = styled(SvgIcon)(({ theme, mb }) => ({
  width: theme.spacing(9),
  height: theme.spacing(9),
  marginBottom: mb && theme.spacing(mb),

  [theme.breakpoints.down("sm")]: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    marginBottom: 0,
  },
}));

export function EnvelopeIcon(props) {
  return (
    <SvgRoot {...props} width='80' height='81' viewBox='0 0 80 81'>
      <circle cx='40' cy='40.4386' r='40' fill='#DE4D48' />
      <path
        d='M41.3707 43.5841C40.6393 43.9872 39.7525 43.9872 39.0211 43.5841L21.9684 34.1768V51.7867C21.9704 53.7992 23.6014 55.4302 25.6139 55.4323H54.7785C56.791 55.4302 58.422 53.7992 58.424 51.7867V34.1768L41.3707 43.5841Z'
        fill='white'
      />
      <path
        d='M54.7785 25.0525H25.6139C23.6014 25.0545 21.9704 26.6855 21.9684 28.6981V31.736C21.9684 31.9574 22.0886 32.1614 22.2825 32.2683L39.9027 41.9898C40.0854 42.0906 40.307 42.0906 40.4897 41.9898L58.1099 32.2683C58.3038 32.1614 58.424 31.9574 58.424 31.736V28.6981C58.422 26.6855 56.791 25.0545 54.7785 25.0525Z'
        fill='white'
      />
    </SvgRoot>
  );
}
