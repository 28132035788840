import React, { forwardRef } from "react";
import { NumericFormat } from "react-number-format";
import { useState } from "react";
import { FormControl, Input, FormHelperText, FormLabel } from "@mui/material";
import { HelpTooltip } from "../HelpTooltip/HelpTooltip";

const NumberFormatCustom = forwardRef(function NumberFormatCustom(props, ref) {
  const { onChange, name, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={values => {
        onChange({
          target: {
            name: name,
            value: values.value,
          },
        });
      }}
      allowNegative={false}
      thousandSeparator=' '
      valueIsNumericString
      suffix=' kr'
      inputMode='numeric'
    />
  );
});

export const LsbMoneyInput = props => {
  // state to hide and show placeholder on focus and blur input
  const [hidePlaceholder, setHidePlaceholder] = useState(false);

  const togglePlaceholder = () => {
    hidePlaceholder ? setHidePlaceholder(false) : setHidePlaceholder(true);
  };

  return (
    <FormControl error={props.errors ? true : false}>
      {props.label && (
        <FormLabel
          htmlFor={props.inputId}
          color='secondary'
          className={props.helpText ? "MuiFormLabel-withHelp" : ""}
        >
          {props.label}

          {props.helpText && <HelpTooltip text={props.helpText} />}
        </FormLabel>
      )}

      <Input
        name={props.name}
        placeholder={!hidePlaceholder ? props.placeholder : ""}
        onFocus={togglePlaceholder}
        onBlur={togglePlaceholder}
        color='secondary'
        ref={props.inputRef}
        onChange={props.onChange}
        inputComponent={NumberFormatCustom}
        disabled={props.disabled}
        defaultValue={props.defaultValue}
      />
      {props.errors && (
        <FormHelperText>
          {props.errors.message
            ? props.errors.message
            : "Vänligen ange beloppet"}
        </FormHelperText>
      )}
    </FormControl>
  );
};
