import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { citizenshipValidationSchema } from "../step_citizenship/validationSchemaCitizenshipStep";
import {
  loanBasicValidationSchema,
  loanExtraFieldsValidationSchema,
  loanOtherBankWithExtraFieldsValidationSchema,
} from "../step_loansToResolve/validationSchemaLoansToResolveStep";
import { moreAboutYouValidationSchema } from "../step_MoreAboutYou/validationSchemaMoreAboutYouStep";
import { otherCountriesValidationSchema } from "../step_otherCountriesTaxes/validationSchemaOtherCountriesTaxesStep";

export const getValidationSchemaReviewStep = (
  banksWithExtraFields = [],
  otherBank
) => {
  return yupResolver(
    yup
      .object()
      .shape({
        termsAndConditionsAgreement: yup
          .boolean()
          .required("Vänligen kryssa i för att intyga")
          .test(value => value === true),

        loanRepayments: yup.array(
          yup.lazy(values => {
            if (otherBank && values.bank === otherBank.id) {
              return loanOtherBankWithExtraFieldsValidationSchema;
            }
            if (banksWithExtraFields.includes(values.bank)) {
              return loanExtraFieldsValidationSchema;
            }

            return loanBasicValidationSchema;
          })
        ),
      })
      .concat(moreAboutYouValidationSchema)
      .concat(citizenshipValidationSchema)
      .concat(otherCountriesValidationSchema)
  );
};
