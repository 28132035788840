import React, { useCallback, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

import { checkToken } from "../../auth-service/auth-service";
import { ErrorScreen } from "../ErrorScreen/ErrorScreen";

export const AuthenticatedRoute = ({ children, maintMode, ...props }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const storedToken = localStorage.getItem("completion_token");

  const checkLogin = useCallback(async () => {
    setLoading(true);

    if (storedToken === null) {
      setIsAuthenticated(false);
      setLoading(false);
      return;
    }

    try {
      const response = await checkToken(storedToken);
      if (response.status === 200) {
        setIsAuthenticated(true);
        setLoading(false);
      } else {
        setError(true);
        setLoading(false);
        localStorage.removeItem("completion_token");
        localStorage.removeItem("refresh_token");
      }
    } catch (error) {
      setError(true);
      setLoading(false);
    }
  }, [storedToken]);

  useEffect(() => {
    if (!isAuthenticated && !maintMode) {
      checkLogin();
    }

    return () => setLoading(false);
  }, [checkLogin, isAuthenticated, maintMode]);

  if (error) {
    return <ErrorScreen errorCode='Unauthorized' />;
  }

  if (loading) {
    return null;
  }

  if (!isAuthenticated && !loading) {
    return <Navigate to='/login' />;
  }

  return children;
};
