import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { CompletionContext } from "../../contexts/CompletionContext";
import { FormLabelLegend } from "../../../styles/ApplicationFormComponents";

export const TaxesRadioButtonsGroup = props => {
  const { inReview, addTaxField, removeTaxFields } = props;
  const { interfaceText, completionData } = useContext(CompletionContext);

  function getInitialValue(value) {
    if (value === null) {
      return null;
    }

    if (value) {
      return "other_countries";
    }

    return "sweden_only";
  }

  const initialValue = getInitialValue(completionData?.hasOtherTaxResidences);

  const [taxValue, setTaxValue] = useState(initialValue);

  const { control } = useFormContext();

  function handleOnChange(e) {
    setTaxValue(e.target.value);
    if (e.target.value === "other_countries") {
      addTaxField();
    } else {
      removeTaxFields();
    }
  }

  return (
    <Grid item xs={12} sm={8} md={7}>
      <Controller
        name='hasOtherTaxResidences'
        defaultValue={taxValue}
        control={control}
        render={({ field: { onChange, value, name } }) => (
          <FormControl
            component='fieldset'
            disabled={inReview}
            margin={inReview && "dense"}
            color='secondary'
          >
            <FormLabelLegend component='legend'>
              {interfaceText?.otherTaxDomicileHasOtherTaxDomicileLabel}
            </FormLabelLegend>
            <RadioGroup
              aria-label='residence'
              name={name}
              onChange={e => {
                onChange(e);
                handleOnChange(e);
              }}
              value={value}
            >
              <FormControlLabel
                value='other_countries'
                control={<Radio size='small' color='secondary' />}
                label='Ja'
              />
              <FormControlLabel
                value='sweden_only'
                control={<Radio size='small' color='secondary' />}
                label='Nej'
              />
            </RadioGroup>
          </FormControl>
        )}
      />
    </Grid>
  );
};
