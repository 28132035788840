import { Container, Grid, Paper, styled } from "@mui/material";

import React from "react";

const ModalLayoutRoot = styled(Container)(({ theme }) => ({
  pointerEvents: "none",

  // [theme.breakpoints.between(600, 960)]: {
  //   position: "relative",
  //   top: "60px",
  // },

  // [theme.breakpoints.between(769, "lg")]: {
  //   position: "relative",
  //   top: "50px",
  // },

  // [theme.breakpoints.up("md")]: {
  //   position: "relative",
  //   top: "40px",
  // },
}));

const ModalLayoutContent = styled(Paper)(({ theme }) => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  boxSizing: "border-box",
  pointerEvents: "auto",

  [theme.breakpoints.down("sm")]: {
    width: "100%",
    minHeight: "calc(100vh - 80px)",
    top: "80px",
    overflow: "scroll",
  },

  "&.MuiPaper-elevation24": {
    boxShadow: "none",
  },
}));

export const ModalLayout = props => {
  return (
    <ModalLayoutRoot disableGutters>
      <Grid container justifyContent='center'>
        <Grid item xs={12} sm={9} md={8} lg={8} xl={8}>
          <ModalLayoutContent elevation={24} square>
            {props.children}
          </ModalLayoutContent>
        </Grid>
      </Grid>
    </ModalLayoutRoot>
  );
};
