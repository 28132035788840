import { useEffect } from "react";

export const useClickOutside = (ref, childRef = null, callback) => {
  useEffect(() => {
    function handleClick(event) {
      event.stopPropagation();
      if (childRef) {
        if (
          ref.current &&
          ref.current.contains(event.target) === false &&
          childRef.current &&
          childRef.current.contains(event.target) === true
        ) {
          return;
        }
      }
      if (ref.current && ref.current.contains(event.target) === false) {
        callback();
      }
    }
    document.addEventListener("click", handleClick, false);

    return () => {
      document.removeEventListener("click", handleClick, false);
    };
  }, [ref, childRef, callback]);
};
