import React, { useRef } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { styled } from "@mui/material";

import questionIcon from "../../assets/images/icon-orange-question.png";
import { useState } from "react";
import { PopperWithDismissButton } from "./PopperWithDismissButton";
import { useClickOutside } from "../helpers/UseClickOutside";

const ToBeStyledTooltip = ({ className, ...props }) => (
  <Tooltip {...props} classes={{ tooltip: className }} />
);

const CustomTooltip = styled(ToBeStyledTooltip)(({ theme }) => ({
  minWidth: 400,
  padding: "0 20px",
  backgroundColor: "transparent",
  margin: 0,

  '.MuiTooltip-popper[data-popper-placement="bottom"] &': {
    marginTop: 0,
  },

  '.MuiTooltip-popper[data-popper-placement="bottom-end"] &': {
    marginTop: 0,
    marginLeft: 0,
  },

  [theme.breakpoints.down("sm")]: {
    minWidth: "initial",
    width: `100vw`,
    maxWidth: "100vw",
    marginLeft: 0,
  },
}));

const TooltipRoot = styled("div")(({ theme, tooltipShown }) => ({
  display: "inline-block",
  position: "relative",
  top: "-2px",
  right: "-4px",
  zIndex: tooltipShown ? 200 : 100,
  width: "32px",
  height: "32px",

  "& .MuiIconButton-root": {
    padding: 0,
    width: "32px",
    height: "32px",
  },
}));

const TooltipIcon = styled("img")(({ theme }) => ({
  display: "inherit",
  padding: "4px",
  width: "32px",
  height: "32px",
}));

export const HelpTooltip = ({ text, ...props }) => {
  const contentRef = useRef(null);

  const childRef = useRef(null);

  useClickOutside(contentRef, childRef, () => {
    toggleTooltip(false);
  });

  const isMobile = () => {
    return window.matchMedia("(max-width: 414px)").matches === true;
  };

  const [tooltipShown, setTooltipShown] = useState(false);

  function toggleTooltip(shown) {
    setTooltipShown(shown);

    if (props?.tooltipShown) {
      props.tooltipShown(shown);
    }
  }

  return (
    <TooltipRoot tooltipShown={tooltipShown} ref={contentRef}>
      <CustomTooltip
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={
          <PopperWithDismissButton
            message={text}
            onDismiss={() => toggleTooltip(false)}
            contentRef={childRef}
          />
        }
        placement={isMobile() ? "bottom-end" : "bottom"}
        onOpen={() => toggleTooltip(true)}
        onClose={() => {
          toggleTooltip(false);
        }}
        open={tooltipShown}
        PopperProps={{
          disablePortal: true,
        }}
      >
        <IconButton
          color='primary'
          aria-label='info'
          onClick={() => toggleTooltip(true)}
          size='large'
        >
          <TooltipIcon src={questionIcon} alt='question-icon' />
        </IconButton>
      </CustomTooltip>
    </TooltipRoot>
  );
};
