import { useEffect } from "react";

export const useIntervalHook = (callback, delay) => {
  useEffect(() => {
    if (delay !== null) {
      const timer = setInterval(callback, delay);
      return () => {
        clearInterval(timer);
      };
    }
  }, [callback, delay]);
};
