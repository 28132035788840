import React from "react";
import glassIcon from "../../../assets/images/icon-lookingglass.png";
import { styled } from "@mui/material";

const Root = styled("h2")(({ theme, inreview }) => ({
  position: "relative",
  fontSize: "1.625rem",
  fontWeight: 900,
  lineHeight: "2.125rem",
  letterSpacing: "0.43px",
  margin: "0",
  paddingBottom: "1rem",
  color: theme.palette.neutral.main,
  background: inreview && `url(${glassIcon}) center left no-repeat`,
  backgroundSize: "28px",
  paddingLeft: inreview && "42px",

  [theme.breakpoints.up("sm")]: {
    fontSize: "2rem",
    lineHeight: "2.5rem",
    letterSpacing: "0.6px",
  },
}));

export const StepHeading = ({ title, helpText, inReview = false }) => {
  return (
    <Root inreview={inReview && "inreview"}>
      {title}
      {helpText}
    </Root>
  );
};
