import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useRef } from "react";
import { LsbFormatInput } from "./LsbFormatInput";
import { LsbMoneyInput } from "./LsbMoneyInput";
import { LsbInput } from "./LsbInput";

export const LsbFormControl = props => {
  const { formState, control } = useFormContext();
  const inputRef = useRef();

  const { contextInputMethods } = props;

  function getError(path, obj) {
    var properties = Array.isArray(path) ? path : path.split(".");
    var lastNestedObject = properties
      .slice(0, properties.length - 1)
      .reduce((prev, curr) => {
        if (!prev.hasOwnProperty(curr)) {
          prev[curr] = {};
        }
        return prev[curr];
      }, obj);
    return lastNestedObject[properties[properties.length - 1]];
  }

  const error = getError(props.name, formState.errors);

  const createIdCamelCase = name => {
    const splittedName = name.split(".");
    let id = "";
    if (splittedName.length) {
      for (let i = 0; i < splittedName.length; i++) {
        if (i !== 0) {
          let part =
            splittedName[i].charAt(0).toUpperCase() + splittedName[i].slice(1);
          id = id + part;
        } else if (i === 0) {
          id = id + splittedName[i].toLowerCase();
        }
      }
      return id;
    } else {
      return name;
    }
  };

  const inputId = createIdCamelCase(props.name) + (props?.index ?? "");

  function handleOnChange(value, name, onChange) {
    onChange(value);

    if (contextInputMethods) {
      contextInputMethods.registerChanges(name, value);
    }
  }

  return props.formatType === "textNumbers" ? (
    <Controller
      defaultValue={props?.defaultValue || ""}
      onFocus={() => inputRef.current.focus()}
      name={props.name}
      control={control}
      render={({ field }) => {
        return (
          <LsbFormatInput
            inputRef={inputRef}
            inputId={inputId}
            {...props}
            icon={props.icon}
            label={props.label}
            value={field.value}
            fullWidth={props?.fullWidth ?? false}
            onChange={value => {
              handleOnChange(value, field.name, field.onChange);
            }}
            helpText={props.helpText}
            labelDescription={props.labelDescription}
            errors={error}
            saveItem={props.saveItem}
            disabled={props.disabled}
          />
        );
      }}
    />
  ) : props.formatType === "money" ? (
    <Controller
      defaultValue={props.defaultValue || ""}
      onFocus={() => inputRef.current.focus()}
      name={props.name}
      control={control}
      render={({ field }) => {
        return (
          <LsbMoneyInput
            {...props}
            label={props.label}
            helpText={props.helpText}
            labelDescription={props.labelDescription}
            value={field.value}
            disabled={props.disabled}
            onChange={value => {
              handleOnChange(value, field.name, field.onChange);
            }}
            errors={error}
          />
        );
      }}
    />
  ) : (
    <Controller
      defaultValue={props.defaultValue || ""}
      onFocus={() => inputRef.current.focus()}
      name={props.name}
      control={control}
      render={({ field }) => {
        return (
          <LsbInput
            maxLength={props.maxLength}
            {...props}
            label={props.label}
            icon={props.icon}
            helpText={props.helpText}
            multiline={props.multiline}
            labelDescription={props.labelDescription}
            errors={error}
            saveItem={props.saveItem}
            inputMode={props.inputMode}
            disabled={props.disabled}
            onChange={value => {
              handleOnChange(value, field.name, field.onChange);
            }}
          />
        );
      }}
    />
  );
};
