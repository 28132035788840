import { Box } from "@mui/material";
import React from "react";

import { Heading } from "../misc/Typography/Heading";
import ExclamationIcon from "../../assets/images/exclamation.png";
import { StatusFragmentIcon } from "../StatusPage/components/StatusFragmentIcon";
import { useNavigate } from "react-router";
import { PrimaryButton } from "../CTAButtons/CTAButtons";
import { ErrorText } from "./ErrorScreen";

export const UnauthorizedErrorMessage = () => {
  const navigate = useNavigate();

  return (
    <div>
      <Box py={2}>
        <StatusFragmentIcon icon={ExclamationIcon} />
        <Heading>Session har slutat.</Heading>

        <ErrorText>
          Du har blivit uttloggad på grund av session giltighetstid har löpt ut.
        </ErrorText>

        <ErrorText>Vänligen logga in igen.</ErrorText>
      </Box>

      <Box pt={1}>
        <PrimaryButton
          variant='contained'
          type='submit'
          className='cta-bold'
          onClick={() => navigate("/login")}
        >
          Logga in
        </PrimaryButton>
      </Box>
    </div>
  );
};
