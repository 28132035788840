import { gql } from "@apollo/client";

export const USER_DECLINE_LOAN_OFFER = gql`
  mutation declineLoanOffer($reason: String!) {
    declineLoanOffer(reason: $reason) {
      errors
      declined
      statusCancelInterfaceText {
        label
        title
        description
      }
    }
  }
`;
