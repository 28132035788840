import { Container, Grid, Paper, styled } from "@mui/material";
import React from "react";

const Root = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(18.25),
}));

const Content = styled(Paper)(({ theme }) => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  boxSizing: "border-box",
  paddingBottom: theme.spacing(8),

  "&.MuiPaper-elevation24": {
    [theme.breakpoints.down("sm")]: {
      boxShadow: "none",
    },
  },
}));

export const SessionExpiredModalLayout = props => {
  return (
    <Root disableGutters>
      <Grid container justifyContent='center'>
        <Grid item xs={12} sm={9} md={8} lg={7} xl={6}>
          <Content elevation={24} square>
            {props.children}
          </Content>
        </Grid>
      </Grid>
    </Root>
  );
};
