import React from "react";
import { useRef } from "react";

import { FormControl, FormHelperText, Select, FormLabel } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { HelpTooltip } from "../HelpTooltip/HelpTooltip";

const selectErrorMessage = () => {
  return <FormHelperText>Vänligen välj alternativ</FormHelperText>;
};

export const LsbSelect = props => {
  const { formState, control } = useFormContext();

  const fieldRef = useRef(null);

  function getError(path, obj) {
    var properties = Array.isArray(path) ? path : path.split(".");
    var lastNestedObject = properties
      .slice(0, properties.length - 1)
      .reduce((prev, curr) => {
        if (!prev.hasOwnProperty(curr)) {
          prev[curr] = {};
        }
        return prev[curr];
      }, obj);
    return lastNestedObject[properties[properties.length - 1]];
  }

  const error = getError(props.name, formState.errors);

  const createIdCamelCase = name => {
    const splittedName = name.split(".");
    let id = "";
    if (splittedName.length) {
      for (let i = 0; i < splittedName.length; i++) {
        if (i !== 0) {
          let part =
            splittedName[i].charAt(0).toUpperCase() + splittedName[i].slice(1);
          id = id + part;
        } else if (i === 0) {
          id = id + splittedName[i].toLowerCase();
        }
      }
      return id;
    } else {
      return name;
    }
  };

  const selectId = createIdCamelCase(props.name);

  return (
    <FormControl
      variant='standard'
      color='secondary'
      error={error ? true : false}
      ref={fieldRef}
      component={"div"}
    >
      {props.label && (
        <FormLabel
          htmlFor={selectId + (props?.index ?? "")}
          id={`${selectId}Label`}
          color='secondary'
          className={props.helpText ? "MuiFormLabel-withHelp" : ""}
        >
          {props.label}
          {props.helpText && <HelpTooltip text={props.helpText} />}
        </FormLabel>
      )}

      <Controller
        name={props.name}
        defaultValue={props.defaultValue}
        control={control}
        render={({ field: { onChange, value, name } }) => {
          return (
            <Select
              labelId={`${selectId}Label`}
              id={selectId + (props?.index ?? "")}
              displayEmpty
              disabled={props.disabled}
              onChange={event => {
                onChange(event.target.value);
                props.handleChange && props.handleChange(event.target.value);
              }}
              value={value}
              name={name}
            >
              {props.options}
            </Select>
          );
        }}
      />

      {error ? selectErrorMessage() : ""}
    </FormControl>
  );
};
