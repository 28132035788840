import { ApolloProvider } from "@apollo/client";

import React from "react";
import "promise-polyfill/src/polyfill";
import "core-js";
import { createRoot } from "react-dom/client";
import "./index.css";
import "./config/validation";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { apolloClient } from "./graphql/apolloClient";

const container = document.getElementById("lsb-loan-completion-form");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <ApolloProvider client={apolloClient}>
      <App />
    </ApolloProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
