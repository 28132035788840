import React, { useEffect, useState } from "react";
import { Box, styled } from "@mui/material";
import { PrimaryButton } from "../../CTAButtons/CTAButtons";
import { Heading } from "../../misc/Typography/Heading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import { Paragraph } from "../../misc/Typography/Paragraph";
import { QRCodeRender } from "../QRCodeRender";
import { QRCodeBox } from "./QRCodeBox";
import { TextualLinkButton } from "../../TextualLinkButton/TextualLinkButton";
import { LoginIcon } from "./LoginIcon";
import { Spinner } from "../../misc/Spinner/Spinner";

const LoginHeading = styled(Heading)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    fontSize: "26px",
  },
}));

export const InitialLoginScreen = ({
  pending,
  startLogin,
  sourceQRCode,
  mobileDevice,
}) => {
  const [urlQRCode, setUrlQRCode] = useState(null);
  const [showQRCode, setShowQRCode] = useState(false);
  const [processing, setProcessing] = useState(false);

  const onStart = (callback, type) => {
    setProcessing(true);

    if (type === "qrCode") {
      setShowQRCode(true);
    }
    callback(type);
  };

  useEffect(() => {
    if (sourceQRCode) {
      setUrlQRCode(sourceQRCode);
    }
  }, [sourceQRCode]);

  useEffect(() => {
    setProcessing(pending);
  }, [pending]);

  return (
    <div>
      <Box justifyContent='center' alignItems='center' display='flex' pb={2}>
        <LoginIcon />
      </Box>
      <LoginHeading>Komplettera ansökan</LoginHeading>

      <Box mb={3}>
        <Paragraph textLight textAlignLeft>
          Här loggar du in med ditt BankID för att lämna kompletterande
          uppgifter, eller för att se vilken status din inskickade låneansökan
          har. Notera att du inte skriver under några kreditavtal eller andra
          lånehandlingar i detta skede.
        </Paragraph>
      </Box>

      {mobileDevice && !processing && (
        <>
          <PrimaryButton
            variant='contained'
            type='button'
            endIcon={<FontAwesomeIcon icon={faArrowRight} />}
            onClick={() => onStart(startLogin, "mobile")}
          >
            Starta mobilt BankID
          </PrimaryButton>

          <PrimaryButton
            variant='outlined'
            type='button'
            endIcon={<FontAwesomeIcon icon={faArrowRight} />}
            onClick={() => onStart(startLogin, "qrCode")}
          >
            BankID på annan enhet
          </PrimaryButton>
        </>
      )}

      {mobileDevice && !showQRCode && processing && <Spinner />}

      {mobileDevice && processing && urlQRCode && showQRCode && (
        <>
          <QRCodeBox>
            <QRCodeRender value={urlQRCode} />

            <TextualLinkButton
              onClick={() => onStart(startLogin, "mobile")}
              size='small'
            >
              Använd BankID på denna enhet
            </TextualLinkButton>
          </QRCodeBox>
        </>
      )}

      {!mobileDevice && !processing && (
        <PrimaryButton
          variant='contained'
          type='button'
          sx={{ width: "auto" }}
          endIcon={<FontAwesomeIcon icon={faArrowRight} />}
          onClick={() => onStart(startLogin, "qrCode")}
        >
          Logga in
        </PrimaryButton>
      )}

      {!mobileDevice && urlQRCode && processing && (
        <>
          <QRCodeBox>
            <QRCodeRender value={urlQRCode} />

            <TextualLinkButton
              onClick={() => onStart(startLogin, "desktop")}
              size='small'
            >
              Använd BankID på denna enhet
            </TextualLinkButton>
          </QRCodeBox>
        </>
      )}
    </div>
  );
};
