import {
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
} from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import { Controller, FormProvider } from "react-hook-form";

export const TermsAgreement = ({
  formMethods,
  handleAgreeChange,
  defaultValue,
  isAgree,
}) => {
  const { formState } = formMethods;

  const Root = styled("div")(({ theme }) => ({
    position: "relative",
    marginBottom: theme.spacing(6.5),
    padding: theme.spacing(3),
    backgroundColor: "#FDF6F3",
    color: theme.palette.neutral.main,
  }));

  const TermsAgreementError = styled(FormHelperText)(({ theme }) => ({
    position: "absolute",
    top: "-20px",
    left: "36px",
    marginTop: 0,
    color: "#f44336",
    height: "20px",
    whiteSpace: "nowrap",
  }));

  const TermsParagraph = styled("p")(({ theme }) => ({
    fontWeight: 500,
    fontSize: "1rem",
    lineHeight: "1.5rem",
    color: theme.palette.neutralColor[90],
    marginTop: 0,
    marginBottom: "1.5rem",
  }));

  const CheckboxFormGroup = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    marginLeft: "-4px",

    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-start",
    },

    "& p": {
      margin: 0,
      fontWeight: 700,
    },

    "& .MuiFormControl-root": {
      minWidth: "auto",
      marginBottom: 0,
      paddingRight: "8px",
    },

    "& .MuiCheckbox-root": {
      padding: 0,
    },
  }));

  return (
    <Root>
      <FormProvider {...formMethods}>
        <CheckboxFormGroup>
          <Controller
            name='termsAndConditionsAgreement'
            defaultValue={defaultValue}
            render={({ field: { name, value, onChange } }) => (
              <FormControl
                error={
                  formState?.errors?.termsAndConditionsAgreement?.message
                    ? true
                    : false
                }
              >
                <Checkbox
                  disableRipple
                  color='secondary'
                  checked={value}
                  id='termsAndConditionsAgreement'
                  name={name}
                  onChange={e => {
                    onChange(e);
                    handleAgreeChange(e);
                  }}
                  required
                />
                {isAgree === false &&
                  formState?.errors?.termsAndConditionsAgreement?.message && (
                    <TermsAgreementError>
                      {formState?.errors?.termsAndConditionsAgreement?.message}
                    </TermsAgreementError>
                  )}
              </FormControl>
            )}
          />

          <FormLabel
            htmlFor='termsAndConditionsAgreement'
            sx={{ paddingBottom: 0 }}
          >
            <TermsParagraph>
              Jag intygar att samtliga angivna uppgifter är korrekta och
              sanningsenliga.
            </TermsParagraph>
          </FormLabel>
        </CheckboxFormGroup>
      </FormProvider>
    </Root>
  );
};
