import { gql } from "@apollo/client";

export const GET_APP_OFFER = gql`
  query getLoanOffer {
    loanApplicationOffer {
      data {
        applicationExpiryDate
        approvedAmount
        effectiveRate
        feesAmount
        interestRate
        monthlyCost
        ppi
        repaymentTime
        repaymentType
        requiredResolvedAmount
        totalMonthlyCostIncludingPpi
        repexHelptext
        repexText
        repexTitle
        requiredResolvedInternalAmount
        moreInfoAboutRequiredResolvedAmountTitle
        moreInfoAboutRequiredResolvedAmountText
      }
      errors
    }
  }
`;
