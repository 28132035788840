import * as yup from "yup";

// Set the default messages to display when doing validation with yup.
yup.setLocale({
  mixed: {
    default: "Vänligen fyll fältet korrekt.",
    required: "Vänligen fyll fältet korrekt.",
    notType: "Vänligen fyll fältet korrekt.",
    test: "Vänligen fyll fältet korrekt.",
  },

  string: {
    email: "Måste vara en korrekt e-post adress",
  },
});
