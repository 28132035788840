import { Grid, styled } from "@mui/material";

import React from "react";
import { ReviewBlockHeader } from "./ReviewBlockHeader";

const Root = styled("div")(({ theme, coapp }) => ({
  backgroundColor: coapp ? "#e9eded" : "#F2F9F1",
  padding: "0 35px 1px 35px",
  marginBottom: theme.spacing(4),

  "&:first-of-type": {
    marginTop: coapp && theme.spacing(2),
  },

  [theme.breakpoints.down("sm")]: {
    padding: "0 0px 1px 0px",
  },
}));

export const ReviewBlock = props => {
  const { header, coApplicant } = props;
  return (
    <Grid item xs={12} sm={coApplicant ? 12 : 10}>
      <Root coapp={coApplicant}>
        <ReviewBlockHeader {...header} />

        {props.children}
      </Root>
    </Grid>
  );
};
