import { styled } from "@mui/system";

export const Subheading = styled("h2")(({ theme }) => ({
  fontSize: "1.5rem",
  lineHeight: "2rem",
  textAlign: "center",
  fontWeight: 900,
  margin: `18px 0 0 0`,
  width: "100%",
}));
