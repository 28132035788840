import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";

import { faArrowRight, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EconomicAdviceSlider } from "./EconomicAdvicesSlider";

import tipsImage from "../../assets/images/tips-image@2x.png";
import {
  EconomicAdvicesRoot,
  EconomicAdvicesHeading,
  EconomicAdvicesIcon,
  EconomicAdvicesCloseButton,
  EconomicAdvicesContent,
  EconomicAdvicesToggler,
} from "../../styles/EconomicAdvicesComponents";

export const EconomicAdvicesScreen = props => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
    props.setShownEconomicAdvices(!expanded);
  };

  useEffect(() => {
    setExpanded(props.shownEconomicAdvices);
  }, [props.shownEconomicAdvices]);

  return (
    <EconomicAdvicesRoot expanded={expanded ? "expanded" : undefined}>
      <EconomicAdvicesHeading
        expanded={expanded ? "expanded" : undefined}
        role='button'
        onClick={toggleExpanded}
      >
        <EconomicAdvicesToggler>
          Ta del av ekonomitips medan du väntar
          <EconomicAdvicesIcon
            icon={faArrowRight}
            expanded={expanded ? "expanded" : undefined}
          />
        </EconomicAdvicesToggler>

        <EconomicAdvicesCloseButton
          role='button'
          expanded={expanded ? "expanded" : undefined}
        >
          <span>Stäng</span>{" "}
          <FontAwesomeIcon
            style={{ fontSize: "13px", marginTop: "2px", marginLeft: "5px" }}
            icon={faTimes}
          />
        </EconomicAdvicesCloseButton>
      </EconomicAdvicesHeading>

      <EconomicAdvicesContent expanded={expanded ? "expanded" : undefined}>
        <img
          src={tipsImage}
          alt='Medan du väntar bild'
          style={{ width: "100%" }}
        />
        <Box px={{ xs: 3, sm: 6 }} pb={12}>
          <h1>5 tips för en bättre privatekonomi</h1>
          <p style={{ fontWeight: 700 }}>
            Att förbättra sin privatekonomi är alltid eftersträvansvärt. Men vad
            är viktigt att tänka på och vilka delar ska prioriteras? Vi delar
            med oss av 5 tips som kan hjälpa dig till en bättre ekonomi.
          </p>
          {expanded && <EconomicAdviceSlider />}
        </Box>
      </EconomicAdvicesContent>
    </EconomicAdvicesRoot>
  );
};
