import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material";

const HelpTooltipPopper = styled("div")(({ theme }) => ({
  boxSizing: "border-box",
  padding: "1rem",
  fontSize: "1rem",
  lineHeight: "1.5rem",
  boxShadow: " 0 0 14px 0 rgba(140,169,169,0.3)",
  position: "relative",
  border: "0.5px solid #E1EFE4",
  color: theme.palette.neutral.lighter,
  backgroundColor: "#F4F6F4",
  cursor: "default",
  borderRadius: "8px",
  fontWeight: 500,
  whiteSpace: "normal",
  width: "100%",
}));

const HelpTooltipMessage = styled("div")(({ theme }) => ({
  paddingRight: "1.5rem",
}));

const HelpTooltipDismiss = styled("button")(({ theme }) => ({
  appearance: "none",
  border: 0,
  backgroundColor: "transparent",
  display: "block",
  cursor: "pointer",
  order: 2,
  padding: "10px",
  position: "absolute",
  right: 0,
  top: 0,
  zIndex: 10,

  "& svg": {
    color: theme.palette.neutral.main,
    width: "18px",
  },
}));

export const PopperWithDismissButton = ({ message, onDismiss, contentRef }) => {
  return (
    <HelpTooltipPopper ref={contentRef}>
      <HelpTooltipDismiss
        aria-label='close-info'
        onClick={onDismiss}
        type='button'
      >
        <CloseIcon />
      </HelpTooltipDismiss>
      <HelpTooltipMessage>{message}</HelpTooltipMessage>
    </HelpTooltipPopper>
  );
};
