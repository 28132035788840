import { styled } from "@mui/system";

export const Heading = styled("h1")(({ theme }) => ({
  width: "100%",
  fontSize: "2rem",
  lineHeight: "2.5rem",
  fontWeight: 900,
  margin: "0 0 1rem 0",
  textAlign: "center",

  [theme.breakpoints.down("sm")]: {
    fontSize: "1.5rem",
    lineHeight: "2rem",
  },
}));
