import { Grid, useMediaQuery, useTheme } from "@mui/material";

import React from "react";
import { ModalLayout } from "./ModalLayout";
import { DeclineLoanOfferForm } from "../DeclineLoanOfferForm/DeclineLoanOfferForm";

import { ModalDialog, ModalGridContent } from "../../styles/ModalComponents";

export const DeclineLoanOfferModal = props => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleOnCancel = () => {
    props.onDeclineLoanOffer();
    props.closeModal("declineOffer");
  };

  return (
    <ModalDialog
      open={props.openModal}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      closeAfterTransition
      disableAutoFocus={true}
      PaperComponent={ModalLayout}
      hideBackdrop={isSmallScreen}
      fullScreen={isSmallScreen}
    >
      <Grid container justifyContent='center'>
        <ModalGridContent item xs={12} sm={9} md={9} lg={9}>
          <DeclineLoanOfferForm
            heading='Är du säker på att du vill makulera din ansökan?'
            message='Om du väljer att makulera din låneansökan kommer ditt ärende att raderas. Uppgifterna du angivit i din ansökan kommer också att tas bort. När din ansökan är makulerad kan den inte aktiveras igen. Har du ångrat dig klickar du på ”Nej, jag vill inte makulera” nedan. '
            submitButtonText='Ja, jag vill makulera'
            cancelButtonText='Nej, jag vill inte makulera'
            onCancel={() => props.closeModal("declineOffer")}
            icon
            onDeclineLoanOffer={handleOnCancel}
          />
        </ModalGridContent>
      </Grid>
    </ModalDialog>
  );
};
