import React from "react";
import { List } from "../../misc/List/List";
import { ListItem } from "../../misc/List/ListItem";

import { Heading } from "../../misc/Typography/Heading";
import { Paragraph } from "../../misc/Typography/Paragraph";
import { Subheading } from "../../misc/Typography/Subheading";
import { SuccessIcon } from "../../SvgIcons/SuccessIcon/SuccessIcon";
import { StatusContentLayout } from "./StatusContentLayout";

export const PaidOutStatusFragment = ({ fadeIn }) => {
  return (
    <StatusContentLayout fadeIn={fadeIn}>
      <SuccessIcon />

      <Heading>Allt är klart!</Heading>
      <Paragraph textAlignLeft>
        Lånet har betalats ut och pengarna är insatta på ditt servicekonto här i
        Lån & Spar Bank. Logga in i vår Internetbank (
        <a
          href='https://internetbanken.lsb.se'
          title='Navigera till Internetbank'
        >
          https://internetbanken.lsb.se
        </a>
        ) för att överföra önskat belopp till valfritt konto i annan bank.
      </Paragraph>

      <Subheading>Bra att veta</Subheading>
      <List bullet='bullet'>
        <ListItem>
          När pengar överförs till konto i annan bank kan det ta upp till en
          extra bankdag innan pengarna finns på det mottagande kontot.
        </ListItem>
        <ListItem>
          Om överföring ska göras till en bank med femsiffrigt clearingnummer
          ska den sista femte siffran inte anges. Kontakta din bank vid
          eventuella frågor.
        </ListItem>
      </List>
    </StatusContentLayout>
  );
};
