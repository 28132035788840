import { Container, Grid } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";

import { useForm, FormProvider } from "react-hook-form";

import React, { useCallback, useContext, useEffect, useState } from "react";

import { LoanFormControls } from "./LoanFormControls";

import {
  CompletionContext,
  debtDataSchema,
} from "../../contexts/CompletionContext";
import { getLoanValidationSchema } from "../validationSchemaLoansToResolveStep";

export const LoanFormComponent = props => {
  const {
    setLoansSaved,
    updateDebt,
    removeDebt,
    item,
    loading,
    index,
    hasInternalLoan,
    inReview,
  } = props;

  const { banks, banksWithExtraFields } = useContext(CompletionContext);

  const [debtSaved, setDebtSaved] = useState(false);
  const [debtEdit, setDebtEdit] = useState(false);
  const [disabledControl, setDisabledControl] = useState(false);

  const otherBank =
    banks.data.find(bank =>
      bank.loanRepaymentFields.includes("OTHER_BANK_NAME")
    ) || null;

  const loanValidationSchema = getLoanValidationSchema(
    banksWithExtraFields,
    otherBank
  );

  const formMethods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    shouldFocusError: true,
    resolver: yupResolver(loanValidationSchema),
  });

  const { setValue, formState, trigger, handleSubmit, watch } = formMethods;

  const onEditButton = () => {
    setDebtSaved(false);
    setDebtEdit(true);
    setLoansSaved(false);
  };

  const onSaveButton = () => {
    handleSubmit(onSubmit)()
      .then(() => {
        if (formState.isValid) {
          setDisabledControl(true);
          setDebtSaved(true);
          setDebtEdit(false);
        }
      })
      .finally(() => setDisabledControl(false));
  };

  const onRemoveButton = () => {
    removeDebt(item);
  };

  const buttonsProps = {
    onEditButton: onEditButton,
    onSaveButton: onSaveButton,
    onRemoveButton: onRemoveButton,
    debtEdit: debtEdit,
    debtSaved: debtSaved,
    disabled: disabledControl,
  };

  const onSubmit = useCallback(
    data => {
      trigger();
      if (formState.isValid) {
        let updated = { ...item, ...data };

        updateDebt(updated);
        setLoansSaved(true);
      }
    },

    [trigger, item, setLoansSaved, updateDebt, formState]
  );

  useEffect(() => {
    if (item && item.bank !== "") {
      setDebtSaved(true);
      setValue(debtDataSchema.bank, item.bank);
    }
  }, [item, setValue]);

  const selectedBankId = watch(debtDataSchema.bank, "");

  if (!banks) {
    return null;
  }

  if (loading) {
    return null;
  }

  return (
    <React.Fragment>
      <FormProvider {...formMethods}>
        <Container
          component='form'
          onSubmit={formMethods.handleSubmit(onSubmit)}
          disableGutters
          justify='center'
        >
          <Grid item sm={8} md={6}>
            <LoanFormControls
              debtSaved={debtSaved}
              debtEdit={debtEdit}
              hasInternalLoan={hasInternalLoan}
              index={index}
              item={item}
              inReview={inReview}
              selectedBankId={selectedBankId}
              buttonsProps={buttonsProps}
              disabled={
                (!formState.isValid && !disabledControl) || disabledControl
              }
            />
          </Grid>
        </Container>
      </FormProvider>
    </React.Fragment>
  );
};
