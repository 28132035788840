import { styled } from "@mui/material";

const WarningBlock = styled("div")(({ theme }) => ({
  lineHeight: "25px",
  paddingBottom: theme.spacing(3),

  [theme.breakpoints.up("md")]: {
    width: "75%",
  },
}));

const WarningBlockText = styled("span")(({ theme }) => ({
  color: "#424242",
  lineHeight: "25px",
  fontWeight: 500,
}));

const WarningBlockTitle = styled("span")(({ theme }) => ({
  fontWeight: 700,
  fontSize: "0.875rem",
  display: "block",
  lineHeight: "1.75rem",
  "& svg": {
    marginRight: "4px",
    color: theme.palette.red,
  },
}));

const DebtForm = styled("div")(({ theme, saved, edit }) => ({
  paddingBottom: "24px",
  boxShadow: saved ? "none" : "2px 2px 14px rgba(77,77,77,0.29)",
  backgroundColor: saved
    ? theme.palette.primary.muted
    : edit
    ? "#fff"
    : "transparent",
  marginBottom: saved ? theme.spacing(3) : edit ? theme.spacing(3) : 0,

  [theme.breakpoints.up("sm")]: {
    marginBottom: saved && theme.spacing(4),
    borderRadius: saved && "4px",
  },

  [theme.breakpoints.down("sm")]: {
    borderLeft: saved || (edit && 0),
    borderRight: saved || (edit && 0),
    padding: edit && "24px 0",
  },
}));

const DebtFormHeading = styled("div")(({ theme, saved }) => ({
  padding: "24px 32px 24px 32px",
  borderBottom: "1px solid",
  borderBottomColor: saved ? "#ACD5B3" : "#DCE0E0",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",

  [theme.breakpoints.down("sm")]: {
    padding: "24px 20px 24px 20px",
  },
}));

const DebtFormTitle = styled("div")(({ theme, saved }) => ({
  display: "inline-block",
  backgroundColor: saved ? theme.palette.primary.darken : "#9DA4A4",
  color: "#fff",
  fontWeight: 700,
  fontSize: "18px",
  lineHeight: "24px",
  padding: "2px 16px",
  textAlign: "center",
  whiteSpace: "nowrap",
  borderRadius: "14px",
}));

const DebtFormBody = styled("div")(({ theme }) => ({
  padding: "24px 32px 0px 32px",

  [theme.breakpoints.down("sm")]: {
    padding: "24px 20px 0px 20px",
    overflowX: "hidden",
  },

  "& .MuiFormControl-root": {
    [theme.breakpoints.up("sm")]: {
      maxWidth: "328px",
      minWidth: "unset",
      display: "flex",
    },
  },
}));

const DebtFormFooter = styled("div")(({ theme }) => ({
  padding: "0px 32px 0px 32px",

  [theme.breakpoints.down("sm")]: {
    padding: "24px 20px 0px 20px",
  },
}));

export {
  WarningBlock,
  WarningBlockText,
  WarningBlockTitle,
  DebtForm,
  DebtFormHeading,
  DebtFormTitle,
  DebtFormBody,
  DebtFormFooter,
};
