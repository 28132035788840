import { styled, Grid, Dialog } from "@mui/material";

const ModalGridContent = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  minHeight: "380px",
  justifyContent: "center",
  paddingLeft: theme.spacing(2.5),
  paddingRight: theme.spacing(2.5),
  paddingTop: theme.spacing(8),
  paddingBottom: theme.spacing(8),
  overflowY: "auto",
  height: "100%",

  [theme.breakpoints.down("sm")]: {
    minHeight: "calc(100vh - 80px)",
  },
}));

const ModalDialog = styled(Dialog)(({ theme }) => ({
  outline: "none",
  top: 0,

  [theme.breakpoints.up("sm")]: { top: "96px" },

  "& .MuiDialog-container": {
    overflowY: "auto",
    height: "100%",
    alignItems: "flex-start",
    paddingTop: 0,

    [theme.breakpoints.up("sm")]: { paddingTop: "80px" },
  },
}));

export { ModalGridContent, ModalDialog };
