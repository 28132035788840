import React from "react";
import { sanitizedString } from "../../helpers/SanitizedString";
import {
  DebtForm,
  DebtFormHeading,
  DebtFormTitle,
  DebtFormBody,
} from "../../../styles/LoanFormStyles";
import { LsbFormControl } from "../../form-components/LsbFormControl";
import { FormProvider, useForm } from "react-hook-form";

export const InternalLoan = ({
  loanAmount,
  labelBankField,
  labelAmountField,
}) => {
  const formMethods = useForm({});

  return (
    <FormProvider {...formMethods}>
      <DebtForm saved='saved'>
        <DebtFormHeading saved='saved'>
          <DebtFormTitle saved='saved'>Lån 1</DebtFormTitle>
        </DebtFormHeading>

        <DebtFormBody>
          <LsbFormControl
            label={sanitizedString(labelBankField)}
            name='internalLoanBank'
            defaultValue='Lån & Spar Bank'
            disabled={true}
          />

          <LsbFormControl
            formatType='money'
            label={sanitizedString(labelAmountField)}
            name='internalLoanAmount'
            defaultValue={loanAmount}
            disabled={true}
          />
        </DebtFormBody>
      </DebtForm>
    </FormProvider>
  );
};
