import { useQuery } from "@apollo/client";

import React, {
  createContext,
  useState,
  useCallback,
  useEffect,
  useRef,
} from "react";

import { Spinner } from "../misc/Spinner/Spinner";
import { ErrorScreen } from "../ErrorScreen/ErrorScreen";

import { GET_INTERFACETEXT } from "../../graphql/GetInterfaceText";
import { GET_BANKS } from "../../graphql/GetBanks";
import { GET_COUNTRIES } from "../../graphql/GetCountries.query";
import { GET_SETTINGS } from "../../graphql/GetSettings";
import { loanStatus } from "../../config/loanStatus";
import { GET_STATUS } from "../../graphql/GetStatus.query";
import { useIntervalHook } from "../helpers/UseIntervalHook";
import { MaintenanceModeScreen } from "../MaintenanceModeScreen/MaintenanceModeScreen";

export const debtDataSchema = {
  bank: "bank",
  data: {
    amountToRepay: "data.amountToRepay",
    cardNumber: "data.cardNumber",
    bankPlusGiro: "data.bankPlusGiro",
    ocrNumber: "data.ocrNumber",
    bankName: "data.bankName",
    otherBankName: "data.otherBankName",
  },
};

export const taxesDataSchema = {
  otherTaxResidences: {
    countrycode: "countrycode",
    taxID: "taxID",
    otherCountry: "otherCountry",
  },
};

export const moreInfoDataSchema = {
  pepSelf: "pepSelf",
  pepContact: "pepContact",
  coApplicant: {
    pepSelf: "coApplicant.pepSelf",
    pepContact: "coApplicant.pepContact",
  },
};

export const citizenshipDataSchema = {
  usCitizen: "usCitizen",
  citizenship: {
    countries: "citizenship.countries",
    otherCountry: "citizenship.otherCountry",
  },
  usTaxID: "usTaxID",
  coApplicant: {
    usCitizen: "coApplicant.usCitizen",
    citizenship: {
      countries: "coApplicant.citizenship.countries",
      otherCountry: "coApplicant.citizenship.otherCountry",
    },
    usTaxID: "coApplicant.usTaxID",
  },
};

export const dataSchema = {
  applicationInfo: {
    agree: "applicationInfo.agree",
  },
};

const completionInputObj = {
  citizenship: { countries: [], otherCountry: null },
  hasOtherTaxResidences: null,
  otherTaxResidences: [],
  loanRepayments: [],
  usCitizen: null,
  pepContact: null,
  pepSelf: null,
  usTaxID: null,
  coApplicant: {
    citizenship: { countries: [], otherCountry: null },
    hasOtherTaxResidences: null,
    otherTaxResidences: [],
    pepContact: null,
    pepSelf: null,
    usTaxID: null,
    usCitizen: null,
  },
};

export const CompletionContext = createContext();

const CompletionContextProvider = props => {
  const [completionData, setCompletionData] = useState();

  const [formCompleted, setFormCompleted] = useState(false);

  const [stepsCompleted, setStepsCompleted] = useState({
    0: false,
    1: false,
    2: false,
    3: false,
    4: false,
  });
  const [stepsStateChange, setStepsStateChange] = useState(0);

  const [status, setStatus] = useState(props.status);

  const [validating, setValidating] = useState(false);

  const [errorOnRequest, setErrorOnRequest] = useState(null);

  const [hasLoansToResolve, setHasLoansToResolve] = useState(false);

  const [completionInput, setCompletionInput] = useState(completionInputObj);

  const [maintenanceMode, setMaintenanceMode] = useState({
    mode: false,
    msg: null,
  });

  const [reviewInit, setReviewInit] = useState(false);

  const meRef = useRef(false);

  const {
    data: interfaceTextData,
    loading,
    refetch: refetchInterfaceText,
  } = useQuery(GET_INTERFACETEXT);

  const {
    data: banksData,
    loading: loading_banks,
    refetch: refetchBanks,
  } = useQuery(GET_BANKS);

  const {
    data: countriesData,
    loading: loading_countries,
    refetch: refetchCountries,
  } = useQuery(GET_COUNTRIES);

  const {
    data: settingsData,
    loading: loading_settings,
    refetch: refetchConfig,
  } = useQuery(GET_SETTINGS);

  const { refetch: statusRefetch } = useQuery(GET_STATUS, {
    notifyOnNetworkStatusChange: true,
    onError: error => {
      if (error.networkError.statusCode === 503) {
        return setMaintenanceMode({ mode: true, msg: error.message });
      }
      setErrorOnRequest(true);
    },
    skip: errorOnRequest === true,
  });

  const interfaceText = interfaceTextData?.interfaceText?.completion ?? null;

  const banks = banksData?.banks ?? null;

  const countries = countriesData?.interfaceText?.completion ?? null;

  const appSettings = settingsData?.settings?.data ?? {};

  // create arrays with banks which have extra fields, return bank ids
  const banksWithExtraFields =
    banks !== null &&
    banks?.data
      .filter(bank =>
        bank?.loanRepaymentFields.includes(
          "BANK_PLUS_GIRO",
          "OCR_NUMBER",
          "OTHER_BANK_NAME"
        )
      )
      .map(bank => {
        return bank.id;
      });

  const handleStepsCompleted = useCallback(
    (stepIndex, completed) => {
      let completedSteps = stepsCompleted;
      completedSteps[stepIndex] = completed;
      setStepsCompleted(completedSteps);
      setStepsStateChange(prevStepStateChange => prevStepStateChange + 1);
    },
    [stepsCompleted]
  );

  const resetContext = () => {
    setStepsCompleted({ 0: false });
    setCompletionData({});
    setStepsStateChange(0);
  };

  function setUpdatedData(data, callback) {
    setCompletionData(data);
    setStatus(data?.status);
    const completionInputData = getCompletionInput(data);
    setCompletionInput(completionInputData);

    callback && callback();
  }

  const requestStatus = () => {
    try {
      statusRefetch().then(res => {
        if (res?.data?.completion?.errors.length > 0) {
          return setErrorOnRequest("error");
        }

        let updatedStatus =
          res?.data?.completion?.data?.status ?? loanStatus.loading;

        setStatus(updatedStatus);
        setCompletionData(prev => {
          return {
            ...prev,
            statusInterfaceText:
              res?.data?.completion?.data?.statusInterfaceText,
          };
        });
      });
    } catch (error) {
      setErrorOnRequest("error");
    }
  };

  useIntervalHook(
    () => {
      requestStatus();
    },
    formCompleted && status === loanStatus.waiting && !errorOnRequest
      ? 5000
      : null
  );

  const validateLoansToRepayStep = useCallback(
    (data, stepIndex) => {
      if (!data.requiresLoanRepayment) {
        return;
      }

      if (data?.loanRepayments !== null && data?.loanRepayments.length) {
        let sum = data?.loanRepayments.reduce((total, item) => {
          return total + parseFloat(item?.data?.amountToRepay);
        }, 0);

        if (
          data.totalAmountToRepay <= sum &&
          sum <= data.approvedAmount - data.feesAmount
        ) {
          handleStepsCompleted(stepIndex, true);
        }
      }
    },
    [handleStepsCompleted]
  );

  const validateCitizenshipStep = useCallback(
    (data, stepIndex) => {
      if (
        data?.citizenship !== null &&
        data?.citizenship.countries !== null &&
        data?.citizenship.countries.length
      ) {
        handleStepsCompleted(stepIndex, true);
      }

      if (data.hasCoApplicant) {
        if (
          !data?.coApplicant?.citizenship ||
          !data?.coApplicant?.citizenship.countries ||
          !data?.coApplicant?.citizenship.countries.length
        )
          handleStepsCompleted(stepIndex, false);
      }
    },
    [handleStepsCompleted]
  );

  const validateOtherTaxResidencesStep = useCallback(
    (data, stepIndex) => {
      if (
        data?.hasOtherTaxResidences !== null &&
        (data?.otherTaxResidences !== null || data?.otherTaxResidences.length)
      ) {
        handleStepsCompleted(stepIndex, true);
      }

      if (data.hasCoApplicant) {
        if (
          data?.coApplicant?.hasOtherTaxResidences === null &&
          (!data?.coApplicant?.otherTaxResidences ||
            !data?.coApplicant?.otherTaxResidences.length)
        )
          handleStepsCompleted(stepIndex, false);
      }
    },
    [handleStepsCompleted]
  );

  const validatePepStep = useCallback(
    (data, stepIndex) => {
      if (data?.pepSelf !== null && data?.pepContact !== null) {
        handleStepsCompleted(stepIndex, true);
      }

      if (data.hasCoApplicant) {
        if (
          data?.coApplicant?.pepSelf === null ||
          data?.coApplicant?.pepContact === null
        )
          handleStepsCompleted(stepIndex, false);
      }
    },
    [handleStepsCompleted]
  );

  const getCompletionInput = completion => {
    const input = { ...completionInputObj };

    input.citizenship = completion?.citizenship;
    input.hasOtherTaxResidences = completion?.hasOtherTaxResidences;
    input.usCitizen = completion?.usCitizen;
    input.loanRepayments = completion?.requiresLoanRepayment
      ? completion?.loanRepayments
      : null;
    input.otherTaxResidences = completion?.otherTaxResidences;
    input.usTaxID = completion?.usTaxID;
    input.pepSelf = completion.pepSelf;
    input.pepContact = completion?.pepContact;
    if (completion?.hasCoApplicant) {
      input.coApplicant.citizenship = completion?.coApplicant?.citizenship;
      input.coApplicant.hasOtherTaxResidences =
        completion?.coApplicant?.hasOtherTaxResidences;
      input.coApplicant.usCitizen = completion?.coApplicant?.usCitizen;
      input.coApplicant.otherTaxResidences =
        completion?.coApplicant?.otherTaxResidences;
      input.coApplicant.usTaxID = completion?.coApplicant?.usTaxID;
      input.coApplicant.pepSelf = completion?.coApplicant.pepSelf;
      input.coApplicant.pepContact = completion?.coApplicant.pepContact;
    } else {
      input.coApplicant = null;
    }

    return input;
  };

  function handleFormCompleted() {
    setFormCompleted(true);
  }

  useEffect(() => {
    if (!props.completionResponse) {
      return;
    }

    if (!props.completionResponse?.completion?.errors.length) {
      setCompletionData(props.completionResponse?.completion?.data);

      const completionInputData = getCompletionInput(
        props.completionResponse?.completion?.data
      );

      setCompletionInput(completionInputData);

      setStatus(props.completionResponse?.completion?.data?.status);

      setHasLoansToResolve(
        props.completionResponse?.completion?.data?.requiresLoanRepayment
      );
    }
  }, [props.completionResponse]);

  useEffect(() => {
    if (
      completionData !== undefined &&
      status === loanStatus.acceptedByCustomer &&
      meRef.current === false
    ) {
      setValidating(true);
    }
  }, [completionData, status]);

  useEffect(() => {
    const validateCompletionData = (data, hasLoansToResolve) => {
      if (hasLoansToResolve) {
        validateLoansToRepayStep(data, 0);
        validateCitizenshipStep(data, 1);
        validateOtherTaxResidencesStep(data, 2);
        validatePepStep(data, 3);
        setValidating(false);
      } else {
        validateCitizenshipStep(data, 0);
        validateOtherTaxResidencesStep(data, 1);
        validatePepStep(data, 2);
        setValidating(false);
      }
    };
    if (validating) {
      validateCompletionData(completionData, hasLoansToResolve);
      meRef.current = true;
    }
  }, [
    validateCitizenshipStep,
    validateLoansToRepayStep,
    validateOtherTaxResidencesStep,
    validatePepStep,
    completionData,
    hasLoansToResolve,
    status,
    validating,
  ]);

  if (
    loading ||
    loading_banks ||
    loading_countries ||
    props.applicationLoading ||
    loading_settings ||
    validating
  ) {
    return <Spinner fullscreen />;
  }

  if (errorOnRequest) {
    return <ErrorScreen errorCode={errorOnRequest} />;
  }

  if (
    status === loanStatus.error ||
    status === loanStatus.pendingManualHandling
  ) {
    return <ErrorScreen errorCode='error' />;
  }

  if (maintenanceMode.mode) {
    return <MaintenanceModeScreen message={maintenanceMode.msg} />;
  }

  return (
    <CompletionContext.Provider
      value={{
        interfaceText: interfaceText,
        banks: banks,
        banksWithExtraFields: banksWithExtraFields,

        completionData,
        stepsCompleted,
        handleStepsCompleted,
        stepsStateChange,
        status,
        hasLoansToResolve,
        resetContext,
        errorOnRequest,
        setErrorOnRequest,
        setCompletionData,
        setUpdatedData,
        countries,
        appSettings,
        completionInput,
        validating,
        requestStatus,
        handleFormCompleted,
        setMaintenanceMode,
        setReviewInit,
        reviewInit,

        refetchSettings: {
          refetchInterfaceText: refetchInterfaceText,
          refetchCountries: refetchCountries,
          refetchConfig: refetchConfig,
          refetchBanks: refetchBanks,
        },
      }}
    >
      {props.children}
    </CompletionContext.Provider>
  );
};

export default CompletionContextProvider;
