import React from "react";
import { CoApplicantSection } from "../../Layout/CoApplicantSection";
import { CoApplicantCitizenshipFormControls } from "./CoApplicantCitizenshipFormControls";

export const CoApplicantCitizenship = ({ personalData = null }) => {
  return (
    <CoApplicantSection
      personalData={personalData}
      title='Medsökandes medborgarskap'
    >
      <CoApplicantCitizenshipFormControls />
    </CoApplicantSection>
  );
};
