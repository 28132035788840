import React from "react";
import { useState } from "react";
import { FormControl, Input, FormHelperText, FormLabel } from "@mui/material";
import { HelpTooltip } from "../HelpTooltip/HelpTooltip";

export const LsbInput = props => {
  // state to hide and show placeholder on focus and blur input
  const [hidePlaceholder, setHidePlaceholder] = useState(false);

  const togglePlaceholder = () => {
    hidePlaceholder ? setHidePlaceholder(false) : setHidePlaceholder(true);
  };

  const handleInputChange = event => {
    if (props.onChange && typeof props.onChange === "function") {
      props.onChange(event.target.value);
    }
    return event.target.value;
  };

  const handleOnBlur = event => {
    togglePlaceholder();
    if (props.saveItem) {
      props.saveItem(event.target.value !== null ? true : false);
    }
  };

  return (
    <FormControl error={props.errors ? true : false} component={"div"}>
      {props.label && (
        <FormLabel
          htmlFor={props.inputId}
          color='secondary'
          className={props.helpText ? "MuiFormLabel-withHelp" : ""}
        >
          {props.icon && (
            <img
              src={props.icon}
              alt='img'
              style={{
                verticalAlign: "middle",
                marginRight: "0.5rem",
              }}
            />
          )}

          {props.label}

          {props.helpText && <HelpTooltip text={props.helpText} />}
        </FormLabel>
      )}

      <Input
        id={props.inputId}
        name={props.name}
        placeholder={!hidePlaceholder ? props.placeholder : ""}
        onFocus={togglePlaceholder}
        onBlur={handleOnBlur}
        onChange={handleInputChange}
        color='secondary'
        multiline={props.multiline}
        defaultValue={props.defaultValue}
        type={props.type}
        ref={props.inputRef}
        maxRows={4}
        disabled={props.disabled}
        inputProps={{
          maxLength: props.maxLength,
          inputMode: props.inputMode,
        }}
      />
      {props.errors && (
        <FormHelperText>
          {props.errors.message
            ? props.errors.message
            : "Vänligen fyll i fältet korrekt"}
        </FormHelperText>
      )}
    </FormControl>
  );
};
